import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import LoginRequired from "./utils/LoginRequired";
import { useEffect } from "react";
import { getUser, getUserState } from "./redux/authentication/getUserSlice";
import { LoginScreen, RegisterScreen } from "./screens/authentication";
import { Settings, Team } from "./screens/settings";
import { DashboardScreen } from "./screens/dashboard";
import { Developer } from "./screens/developer/Developer";
import { ComplianceScreen } from "./screens/kyc/ComplianceScreen";
import { getLoginState } from "./redux/authentication/userLoginSlice";
import { CustomersScreen } from "./screens/customers/CustomersScreen";
import { VirtualAccountScreen } from "./screens/virtualAccount/VirtualAccountScreen";
import { WebhookScreen } from "./screens/webhook/WebhookScreen";
import { QueryClient, QueryClientProvider } from "react-query";
import { Transactions } from "./screens/business/Transactions";
import { NewBusiness } from "./screens/business/NewBusiness";
import { SupportScreen } from "./screens/support/SupportScreen";
import { AccounTransactionsScreen } from "./screens/virtualAccount/AccounTransactionsScreen";
import { WithdrawScreen } from "./screens/withdraw/withdraw";
import { ResetPassword } from "./screens/authentication/ResetPassword";
import { VerifyEmail } from "./screens/kyc/verification/EmailVerification";
import { Error404Screen } from "./screens/errorScreen/Error404Screen";
import OneSignal from 'react-onesignal';

const queryClient = new QueryClient();
function App() {
  const { userInfo } = useSelector(getLoginState);
  const { userData }  = useSelector(getUserState)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    OneSignal.init({
      appId: "f13fc720-664a-443e-a437-952c41b704cc"
    });
  }, []);

  useEffect(() => {
    if (userInfo?.access) {
      dispatch(getUser());
     
    }
  }, [dispatch,location,  userInfo, ]);

  useEffect(() => {
  
  if( userInfo?.access && userData  && !userData.email_verify && location.pathname !== "/email-verify"){
        navigate("/email-verify");
      }
  }, [location,userInfo, navigate, userData]);


  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
      
        <Route path='*' element={<Error404Screen />} />
        {/* <Route path='/terms-and-condition' element={<TermsandCondition/>} />
          <Route path='/faqs' element={<Faqs/>} /> */}
        <Route path="/" element={<LoginScreen />} />
        <Route path="signin/" element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/forgot-password" element={<ResetPassword />} />
        
        <Route path="/team" element={<Team />} />

        {/* Login Required Page */}
        <Route
          path="/compliance"
          element={
            <LoginRequired>
              <ComplianceScreen />
            </LoginRequired>
          }
        />

<Route
          path="/email-verify"
          element={
            <LoginRequired>
              <VerifyEmail />
            </LoginRequired>
          }
        />



<Route
          path="/add-new-business"
          element={
            <LoginRequired>
              <NewBusiness />
            </LoginRequired>
          }
        />



        <Route
          path="/customers"
          element={
            <LoginRequired>
              <CustomersScreen />
            </LoginRequired>
          }
        />


<Route
          path="/support"
          element={
            <LoginRequired>
              <SupportScreen />
            </LoginRequired>
          }
        />


<Route
          path="/virtual-account/:account_number"
          element={
            <LoginRequired>
              <AccounTransactionsScreen />
            </LoginRequired>
          }
        />

<Route
          path="/withdraw"
          element={
            <LoginRequired>
              <WithdrawScreen />
            </LoginRequired>
          }
        />




        <Route
          path="/customer-reserved-account"
          element={
            <LoginRequired>
              <VirtualAccountScreen />
            </LoginRequired>
          }
        />

        <Route
          path="/webhook-event"
          element={
            <LoginRequired>
              <WebhookScreen />
            </LoginRequired>
          }
        />

        <Route
          path="/dashboard"
          element={
            <LoginRequired>
              <DashboardScreen />
            </LoginRequired>
          }
        />

        <Route
          path="/developer"
          element={
            <LoginRequired>
              <Developer />
            </LoginRequired>
          }
        />

        <Route
          path="/settings"
          element={
            <LoginRequired>
              <Settings />
            </LoginRequired>
          }
        />
       

        <Route
          path="/transactions"
          element={
            <LoginRequired>
              <Transactions />
            </LoginRequired>
          }
        />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
