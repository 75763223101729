import React from 'react'
import { Link } from 'react-router-dom'

export const Error404Screen = () => {
  return (
    <div className='error-page'>

      <div className='error-center'>

        <img src='/images/404.png' alt='404'></img>

        <h3>Page not Found</h3>
        <Link  to="/" className="error-btn">Go Back</Link>

      </div>


    </div>
  )
}
