import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import { useAuthContext } from "../../../context/authentication/authContext";
import showerror from "../../../components/ErrorToast";
import showsuccess from "../../../components/SuccessToast";
import { useStateLgaData } from "../hooks/useStateLgaDopnDown";
import { bussiness_industry } from "../data/bussiness_industry";
import { useDispatch, useSelector } from "react-redux";
import {
  getRegisterState,
  registerUser,
  resetState,
} from "../../../redux/authentication/userRegisterSlice";
import { useNavigate } from "react-router-dom";

const RegisterFormThree = () => {
  const { formOne, formTwo, formThree, setFormTwo, setFormThree } =
    useAuthContext();

  const [stateFormat, { data: stateLgaData }] = useStateLgaData();
  const { loading, status, message } = useSelector(getRegisterState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (status === "success") {
      dispatch(resetState());
      showsuccess(message)
      navigate("/signin");
    }
  }, [dispatch, message, navigate, status]);
  
  const validationSchema = Yup.object({
    isCAC: Yup.boolean().required(),
    bizname: Yup.string().when("isCAC", {
      is: true,
      then: () => Yup.string().required("Business name is required."),
    }),
    websiteurl:Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter Valid Website url!'
  ).required("Business Website is required."),

    businessdesc: Yup.string().when("isCAC", {
      is: true,
      then: () => Yup.string().required("Business description is required."),
    }),
    streetAddress: Yup.string().required("Street Address is required."),
  });

  const [selectedOptions, setSelectedOptions] = React.useState({
    business_industry: "",
    state: "",
    lga: "",
  });
  const [lga, setLga] = React.useState([]);
  function handleSelect(data, e) {
    setSelectedOptions((prev) => ({
      ...prev,
      [e.name]: data.value,
    }));
    if (e.name === "state") {
      const stateObj = stateLgaData.find((state) => {
        return state.name === data.value;
      });
      const newStateLga = stateObj.lg.map((lga) => {
        return {
          value: lga,
          label: lga,
        };
      });
      setLga(newStateLga);
    }
  }
  const formFields = formThree.fields;
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      bizname: formFields.legal_business_name,
      websiteurl:formFields.websiteurl,
      businessdesc: formFields.business_description,
      isCAC: Boolean(formTwo.account_type !== "PERSONAL"),
      streetAddress: formFields.street_address,
    },
  });

  const onSubmit = (values) => {
    if (formTwo.account_type !== "PERSONAL") {
      if (selectedOptions.business_industry === "") {
        showerror("Kindly select a business Industry");
        return;
      }
    }

    if (selectedOptions.state === "") {
      showerror("Kindly select a state");
      return;
    }
    if (selectedOptions.lga === "") {
      showerror("Kindly select a LGA");
      return;
    }
    setFormThree((prev) => ({
      ...prev,
      fields: {
        legal_business_name: values.bizname,
        websiteurl:values.websiteurl,
        business_description: values.businessdesc,
        business_industry: selectedOptions.business_industry,
        state: selectedOptions.state,
        lga: selectedOptions.lga,
        street_address: values.streetAddress,
      },
    }));
    // MAKE API REQUEST HERE 👇
    const data = {
      username: `0${formOne.fields.phonenumber}`,
      email: formOne.fields.email,
      firstname: formOne.fields.Fname,
      lastname: formOne.fields.Lname,
      password: formOne.fields.password,
      phone: `0${formOne.fields.phonenumber}`,
      account_type: formTwo.account_type,
      name: values.bizname === "" ? null : values.bizname,
      description: values.businessdesc === "" ? null : values.businessdesc,
      industry:
        selectedOptions.business_industry === ""
          ? null
          : selectedOptions.business_industry,
      state: selectedOptions.state,
      lga: selectedOptions.lga,
      address: values.streetAddress,
      websiteurl:values.websiteurl,
    };
  
    dispatch(registerUser(data));

  };

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      padding: "5px 0px 5px 15px",
      borderColor: state.isFocused ? "#f87e27" : "#ccc",
      "&:hover": {
        border: "1px solid #ccc",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f87e27"
        : state.isFocused
        ? "#ffe9da"
        : provided.backgroundColor,
    }),
  };
  const goBack = () => {
    const values = getValues();
    setFormThree((prev) => ({
      active: false,
      fields: {
        legal_business_name: values.bizname,
        business_description: values.businessdesc,
        business_industry: selectedOptions.business_industry,
        state: selectedOptions.state,
        lga: selectedOptions.lga,
        street_address: values.streetAddress,
        websiteurl:values.websiteurl,
      },
    }));

    setFormTwo((prev) => ({
      ...prev,
      active: true,
    }));
  };

  return (
    <div className="form-container">
      <img
        src="/images/logo-white.png"
        alt=""
        className="form-container-logo"
      />
      <section className="form-card account">
        <form className="create-act" onSubmit={handleSubmit(onSubmit)}>
          <h4 className="formtwo__title">
            {formTwo.account_type === "PERSONAL"
              ? "TELL US ABOUT YOURSELF"
              : "TELL US ABOUT YOUR BUSINESS"}
          </h4>

          {formTwo.account_type !== "PERSONAL" && (
            <>
              <div className="act-form">
                <input
                  placeholder="Legal Business Name"
                  type="text"
                  className={
                    errors.bizname
                      ? "form-input form-invalid-input"
                      : "form-input"
                  }
                  {...register("bizname")}
                />
                <ErrorMessage
                  errors={errors}
                  name="bizname"
                  render={({ message }) => (
                    <span className="form-invalid-feedback"> {message}</span>
                  )}
                />
              </div>

              <div className="act-form">
                <input
                  placeholder="Business Website Url"
                  type="url"
                  className={
                    errors.websiteurl
                      ? "form-input form-invalid-input"
                      : "form-input"
                  }
                  {...register("websiteurl")}
                />
                 <span style={{"fontSize":"14px"}}> website domain with https// i.e https://www.domain.com</span>
               
                <ErrorMessage
                  errors={errors}
                  name="websiteurl"
                  render={({ message }) => (
                    <span className="form-invalid-feedback"> {message}</span>
                  )}
                />
              </div>

              <div className="act-form">
                <textarea
                  placeholder="Business Description"
                  rows={5}
                  cols={60}
                  className={
                    errors.bizname
                      ? "form-textarea form-invalid-input"
                      : "form-textarea"
                  }
                  {...register("businessdesc")}
                />
                <ErrorMessage
                  errors={errors}
                  name="businessdesc"
                  render={({ message }) => (
                    <span className="form-invalid-feedback"> {message}</span>
                  )}
                />
              </div>

              <div className="act-form">
                <Select
                  placeholder="Select Business Industry"
                  options={bussiness_industry}
                  value={
                    selectedOptions.business_industry !== "" && {
                      label: selectedOptions.business_industry,
                      value: selectedOptions.business_industry,
                    }
                  }
                  onChange={handleSelect}
                  isSearchable={true}
                  styles={colourStyles}
                  name="business_industry"
                />
              </div>
            </>
          )}

          <div className="state-lga">
            <Select
              placeholder="State"
              options={stateFormat}
              value={
                selectedOptions.state !== "" && {
                  label: selectedOptions.state,
                  value: selectedOptions.state,
                }
              }
              onChange={handleSelect}
              isSearchable={true}
              styles={colourStyles}
              name="state"
            />
            <Select
              placeholder="LGA"
              options={lga}
              value={
                selectedOptions.lga !== "" && {
                  label: selectedOptions.lga,
                  value: selectedOptions.lga,
                }
              }
              onChange={handleSelect}
              isSearchable={true}
              styles={colourStyles}
              name="lga"
            />
          </div>
          <div className="act-form">
            <input
              placeholder="Street Address"
              type="text"
              className={
                errors.bizname ? "form-input form-invalid-input" : "form-input"
              }
              {...register("streetAddress")}
            />
            <ErrorMessage
              errors={errors}
              name="streetAddress"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>

          <div className="button__group">
            <button
              type="button"
              className="btn btn-primary formsubmit__btn"
              onClick={goBack}
            >
              Back
            </button>
            <button type="submit" className="btn btn-secondary formsubmit__btn">
              {loading ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </form>
      </section>
      <p
        style={{
          marginTop: "52px",
          color: "white",
        }}
      >
        © payvessel {new Date().getFullYear()}
      </p>
    </div>
  );
};

export default RegisterFormThree;
