import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";



export const logoutAction = () => {
  localStorage.removeItem("payvessel-userInfo")
  localStorage.removeItem("payvessel-activeBusiness")
  // showerror(`Oops 😠:  successfully logout`);
  window.location.pathname = "/signin";
};


export const login = createAsyncThunk("userlogin/login", async (authdata,thunkAPI) => {
  try {
    const { data } = await axiosInstance.post(`/api/v1/login/`, {
      email: authdata.email,
      password: authdata.password,
      user_agent : authdata.userAgent 
    });
    localStorage.setItem("payvessel-userInfo", JSON.stringify(data));
 
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});

const userLoginSlice = createSlice({
  name: "userlogin",
  initialState: {
    userInfo: localStorage.getItem("payvessel-userInfo")
      ? JSON.parse(localStorage.getItem("payvessel-userInfo"))
      : null,
    loading: false,
  },
 
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
    },
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const getLoginState = (state) => state.userlogin;
export default userLoginSlice.reducer;
