import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/useAxios";

export const getVirtAccTransactions = createAsyncThunk(
  "virtualacctransactions/getvirtacctransactions",
  async (payloaddata, thunkAPI) => {
    try {
      var url = `/api/v1/virtual-account/${payloaddata?.businessid}/${payloaddata?.accountNum}/`;

      
      if (payloaddata?.page) {
        url = `/api/v1/virtual-account/${payloaddata?.businessid}/${payloaddata?.accountNum}/?page=${payloaddata?.page}`;
      }
      const { data } = await axiosInstance.get(url);

      return data;
    } catch (error) {
      let errorMsg = "";

      if (error.response && error.response.data.error) {
        errorMsg = error.response.data.error[0];
      } else if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.response && error.response.data.detail) {
        errorMsg = error.response.data.detail;
      } else {
        errorMsg = error.message;
      }
      // showerror(`Oops 😠,  ${errorMsg}`);
      return thunkAPI.rejectWithValue(errorMsg);
    }
  }
);

const getVirtAccTransactionsSlice = createSlice({
  name: "virtualacctransactions",
  initialState: {
    vtransactions: [],
    vtransactions_loading: false,
  },

  extraReducers: {
    [getVirtAccTransactions.fulfilled]: (state, action) => {
      state.vtransactions_loading = false;
      state.vtransactions = action.payload;
    },
    [getVirtAccTransactions.pending]: (state) => {
      state.vtransactions_loading = true;
    },
    [getVirtAccTransactions.rejected]: (state) => {
      state.vtransactions_loading = false;
    },
  },
});

export const getVirtAccTransactionsState = (state) => state.virtualacctransactions;
export default getVirtAccTransactionsSlice.reducer;
