import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const webhookquery = createAsyncThunk("webhookquerys/webhookquery", async (payloaddata,thunkAPI) => {

  try {
    var url = `/api/v1/webhook-event/${payloaddata?.businessid}/?query=${payloaddata?.query}`
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});


const webhookquerySlice = createSlice({
  name: "webhookquerys",
  initialState: {
    webhookquerylist: null,
    query:"",
    queryloading: false,
  },

  reducers: {
    resetQuery: (state) => {
      state.webhookquerylist = null;
      state.query= "";
      state.queryloading = false;
    },
    setQuery: (state,action) => {
      state.query= action.payload;
     
    },
  },

  extraReducers: {
    [webhookquery.fulfilled]: (state, action) => {
      state.queryloading = false;
      state.webhookquerylist = action.payload;
      
    },
    [webhookquery.pending]: (state) => {
      state.queryloading = true;
    },
    [webhookquery.rejected]: (state) => {
      state.queryloading = false;
    },
  },
});

export const webhookqueryState = (state) => state.webhookquerys;
export default webhookquerySlice.reducer;
export const { resetQuery,setQuery } = webhookquerySlice.actions;


