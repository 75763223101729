import { createSlice } from "@reduxjs/toolkit";

const generalSlice = createSlice({
  name: "general",
  initialState: {
    KYCModal: false,
    perInfo: false,
    bvn: false,
    trans: false,
    transData: null,
    showwebhookmodal:false,
    webhookData: null,
    video: false,
    proof: false,
    cert: false,
    other: false,
    securityWord: "",
  },
  reducers: {
    showKYCModal: (state) => {
      state.KYCModal = true;
    },
    hideKYCModal: (state) => {
      state.KYCModal = false;
    },
    showPerInfoModal: (state) => {
      state.perInfo = true;
    },
    hidePerInfoModal: (state) => {
      state.perInfo = false;
    },
    showBVNModal: (state) => {
      state.bvn = true;
    },
    transModal: (state, { payload }) => {
      state.trans = payload;
    },
    selectedTransData: (state, { payload }) => {
      state.transData = payload;
    },
    webhookModal: (state, { payload }) => {
      state.showwebhookmodal = payload;
    },
    selectedWebhookData: (state, { payload }) => {
      state.webhookData = payload;
    },

    hideBVNModal: (state) => {
      state.bvn = false;
    },
    showVideoModal: (state) => {
      state.video = true;
    },
    hideVideoModal: (state) => {
      state.video = false;
    },
    showProofModal: (state) => {
      state.proof = true;
    },
    hideProofModal: (state) => {
      state.proof = false;
    },
    showCertModal: (state) => {
      state.cert = true;
    },
    hideCertModal: (state) => {
      state.cert = false;
    },
    showOtherModal: (state) => {
      state.other = true;
    },
    hideOtherModal: (state) => {
      state.other = false;
    },
  },
});

export const {
  showKYCModal,
  showBVNModal,
  showCertModal,
  showOtherModal,
  showPerInfoModal,
  showProofModal,
  showVideoModal,
  transModal,
  selectedTransData,
  hideKYCModal,
  hideBVNModal,
  hideCertModal,
  hideOtherModal,
  hidePerInfoModal,
  hideProofModal,
  hideVideoModal,
  webhookModal,
  selectedWebhookData
  
} = generalSlice.actions;
export default generalSlice.reducer;
