import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const queryVirtAccTransaction = createAsyncThunk(
  "queryvirtacctransactions/query",
  async (payloaddata, thunkAPI) => {
    try {
      var url = `/api/v1/virtual-account/${payloaddata?.businessid}/${payloaddata?.accountNum}/?query=${payloaddata?.query}`;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      let errorMsg = "";

      if (error.response && error.response.data.error) {
        errorMsg = error.response.data.error[0];
      } else if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.response && error.response.data.detail) {
        errorMsg = error.response.data.detail;
      } else {
        errorMsg = error.message;
      }
      showerror(`Oops 😠,  ${errorMsg}`);
      return thunkAPI.rejectWithValue(errorMsg);
    }
  }
);

const queryVirtAccTransactionSlice = createSlice({
  name: "queryvirtacctransactions",
  initialState: {
    vquerytransactionlist: null,
    vquery: "",
    vqueryloading: false,
  },

  reducers: {
    resetQuery: (state) => {
      state.vquerytransactionlist = null;
      state.vquery = "";
      state.vqueryloading = false;
    },
    setQuery: (state, action) => {
      state.vquery = action.payload;
    },
  },

  extraReducers: {
    [queryVirtAccTransaction.fulfilled]: (state, action) => {
      state.vqueryloading = false;
      state.vquerytransactionlist = action.payload;
    },
    [queryVirtAccTransaction.pending]: (state) => {
      state.vqueryloading = true;
    },
    [queryVirtAccTransaction.rejected]: (state) => {
      state.vqueryloading = false;
    },
  },
});

export const queryVirtAccTransactionState = (state) => state.queryvirtacctransactions;
export default queryVirtAccTransactionSlice.reducer;
export const { resetQuery, setQuery } = queryVirtAccTransactionSlice.actions;
