import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const querytransaction = createAsyncThunk("querytransactions/query", async (payloaddata,thunkAPI) => {

  try {
    var url = `/api/v1/transactions/${payloaddata?.businessid}/?query=${payloaddata?.query}`
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});


const querytransactionSlice = createSlice({
  name: "querytransactions",
  initialState: {
    querytransactionlist: null,
    query:"",
    queryloading: false,
  },

  reducers: {
    resetQuery: (state) => {
      state.querytransactionlist = null;
      state.query= "";
      state.queryloading = false;
    },
    setQuery: (state,action) => {
      state.query= action.payload;
     
    },
  },

  extraReducers: {
    [querytransaction.fulfilled]: (state, action) => {
      state.queryloading = false;
      state.querytransactionlist = action.payload;
      
    },
    [querytransaction.pending]: (state) => {
      state.queryloading = true;
    },
    [querytransaction.rejected]: (state) => {
      state.queryloading = false;
    },
  },
});

export const querytransactionState = (state) => state.querytransactions;
export default querytransactionSlice.reducer;
export const { resetQuery,setQuery } = querytransactionSlice.actions;


