import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBox } from '../../../components/LoadingBox'
import { getUserState } from '../../../redux/authentication/getUserSlice'
import { APIKeysState, generateAPIKeys, getAPIKeys } from '../../../redux/settings/apikeysSlice'
import Swal from 'sweetalert2'
import showsuccess from '../../../components/SuccessToast'


export const Apikey = () => {
  const { loading,api_key,api_secret }  = useSelector(APIKeysState)
  const { activeBusiness, }  = useSelector(getUserState)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAPIKeys(activeBusiness?.businessid))

  }, [activeBusiness, dispatch])
  

const handleSubmit = () =>{

  Swal.fire({

    title: 'Are you sure?',
    text: "you want to generate new crendential!",
    icon: 'warning',
    showCancelButton:true,
   
    confirmButtonText: 'Yes',

  }).then((result) => {
    
    if (result.isConfirmed) {
      dispatch(generateAPIKeys(activeBusiness?.businessid))
    } 
  })
 

}

const handleCopyClick = async (textToCopy) => {
  try {
    await navigator.clipboard.writeText(textToCopy);
    showsuccess("Copied to clipboard")
   
  } catch (error) {
    console.error('Error copying text: ', error);
  }
};

  return (
    <div className="card profile"  >
     
     {loading && <LoadingBox/>}



    <div className="setting-profile" >
      <h2>API Configuration</h2>
      
    
          
           <div className="edit-profile">
            <label htmlFor="firstname">API key</label>
            <div className="api-flex">
            <input type="text" value={api_key}  readOnly/>
            <span className="api_copy">
          <i className= "fa fa-copy" onClick={()=>handleCopyClick(api_key)}></i>
          </span>

          </div>
          </div>



          <div className="edit-profile">
            <label htmlFor="firstname">Secret Key</label>
            <div className="api-flex">
            <div className="p-relative">
             <input type="text" value={api_secret}  readOnly />

              {/* <span className="form-input-append">
                <i className= "fa fa-eye-slash" ></i>
              </span> */}

            </div>
            <span className="api_copy">
          <i className= "fa fa-copy" onClick={()=>handleCopyClick(api_secret)}></i>
          </span>

          </div>
          </div>

          

          
          <button type='button'  className="btn btn-full btn-secondary" onClick={handleSubmit}>Generate New Credential</button>




    </div>
  </div>
  )
}
