import React from 'react'
import { Base } from '../../components/Base'

export const Team = () => {
return (
<Base>

   <>
   <div>
        <div className="card setting">
          <div className="settings-nav">
            <li><a href="settings.html"> Profile</a></li>
            <li><a href="businessInfo.html"> Business Info</a></li>
            <li><a href="bankAccount.html"> Bank Accounts</a></li>
            <li><a href="team.html" className="setting-active"> Team</a></li>
            <li><a href="preferences.html"> Preferences</a></li>
          </div>
        </div>
        <div className="transaction-table">
          <div className="card">
            <div className="trade-preview my-abs">
              <form action>
                <input type="text" name="q" className="transac-search" placeholder="Search for Team member" />
              </form>
              <div className="filter-plus">
                <button className="year-option filter" id="getFilter2">Manage Roles
                </button>
                <button className="btn btn-prm" style={{fontSize: '14px'}}>Invite New Team
                  Members</button>
              </div>
            </div>
            <div className="card bank-details" id="bank-form2">
              <h3 style={{textAlign: 'center', fontWeight: 400}}>Invite New Team Member</h3>
              <img src="../images/close.png" alt="" className="close-bank-form" id="close-bank-form2" />
              <form action>
                <div className="add-bank">
                  <label htmlFor="mail">Email Address</label>
                  <input type="email" name="mail" id />
                </div>
                <div className="add-bank">
                  <label htmlFor="role">Team Member Role</label>
                  <select name="roles" id>
                    <option value="select" selected disabled>Select Bank</option>
                    <option value="active">Active</option>
                    <option value="in-active">Inactive</option>
                    <option value="in-active">Inactive</option>
                  </select>
                </div>
                <div className="mr-center">
                  <input type="submit" defaultValue="Invite" className="btn btn-prm" />
                </div>
              </form>
            </div>
            <div className="transac-table">
              <table className="table">
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email address</th>
                    <th>Roles</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody id>
                  <tr><td>Macy Janet</td>
                    <td>Macysbakery@em...</td>
                    <td><span className="btn-pink">Business Owner</span></td>
                    <td><span className="btn-success two">Active</span>
                      <span className="btn-danger two mr-lt">Change Role</span>
                      <span className="mr-lt">Remove</span>
                    </td>
                  </tr></tbody><tbody>
                  <tr><td>Damini Ayo</td>
                    <td>Macysbakery@em...</td>
                    <td><span className="btn-blue">Developer</span></td>
                    <td><span className="btn-success two">Active</span>
                      <span className="btn-danger two mr-lt">Change Role</span>
                      <span className="mr-lt">Remove</span>
                    </td>
                  </tr></tbody>
                <tbody>
                  <tr><td>Angela Oyinye</td>
                    <td>Macysbakery@em...</td>
                    <td><span className="btn-lemon">Customer Rep</span></td>
                    <td><span className="btn-success two">Active</span>
                      <span className="btn-danger two mr-lt">Change Role</span>
                      <span className="mr-lt">Remove</span>
                    </td>
                  </tr></tbody>
              </table>
              {/* <div class="card flex table2">
                                    <p>Macy Janet</p>
                                    <p>Macysbakery@em...</p>
                                    <p><span class="btn-pink" >Business Owner</span>
                                        <span class="btn-success two">Active</span>
                                        <span class="btn-danger two">Change Role</span>
                                            <span>Remove</span>
                                    </p>
                                </div> */}
            </div>
          </div>
        </div>
        <div className="page-number">
          <div className="card flex-number">
            <p><i className="fas next active-two fa-angle-left" /></p>
            <p className="active-two">1</p>
            <p><i className="fas next active-two fa-angle-right" /></p>
          </div>
        </div>
      </div>
   </>
   
</Base>
)
}
