import { FaCheck } from "react-icons/fa";

export const StepCircleProgress = ({
  stepStyeWidth,
  stepOne,
  stepTwo,
  stepThree,
}) => {
  return (
    <div className="step-container" style={{ "--step-width": stepStyeWidth }}>
      <div className="step-wrapper">
        <div
          className={
            stepStyeWidth === 0
              ? "step-active"
              : stepStyeWidth >= 1
              ? "step-style-1"
              : "step-style"
          }
          onClick={stepOne}
        >
          {stepStyeWidth >= 0 && <FaCheck color="#fff" size={25} />}
        </div>
        <div
          className={`step-label ${stepStyeWidth === 0 ? "step-label-b" : ""}`}
        >
          Profile
        </div>
      </div>
      <div className="step-wrapper">
        <div
          className={
            stepStyeWidth === 1
              ? "step-active"
              : stepStyeWidth >= 2
              ? "step-style-1"
              : "step-style"
          }
          onClick={stepTwo}
        >
          {stepStyeWidth >= 1 && <FaCheck color="#fff" size={25} />}
        </div>
        <div
          className={`step-label ${stepStyeWidth === 1 ? "step-label-b" : ""}`}
        >
          Contact
        </div>
      </div>
      <div className="step-wrapper">
        <div
          className={
            stepStyeWidth === 2
              ? "step-active"
              : stepStyeWidth >= 2
              ? "step-style-1"
              : "step-style"
          }
          onClick={stepThree}
        >
          {stepStyeWidth >= 2 && <FaCheck color="#fff" size={25} />}
        </div>
        <div
          className={`step-label ${stepStyeWidth === 2 ? "step-label-b" : ""}`}
        >
          Document
        </div>
      </div>
    </div>
  );
};
