import React, { useState } from 'react'
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { downloadPdf } from '../../../actions/download';
import { BackDrop } from '../../../components/BackDrop'
import { updateStatus, withdrawState } from '../../../redux/withdraw/withdrawSlice';

export const SuccessModal = () => {

  const [isDownloading, setIsDownloading] = useState(false)
  const {resp_data} = useSelector(withdrawState);


    const dispatch = useDispatch();

    const saveReceipt = async (id) => {
      
      setIsDownloading(true)
        downloadPdf(id);
      setIsDownloading(false)


  }

  return (

    <BackDrop>
    <div className="modal">
      <div className="header">
        <div className="close-icon" onClick={() => dispatch(updateStatus())}>
          <MdOutlineClose size={20} color="#fff" />
        </div>
      </div>
      <div className="modal-content">
          <img src={resp_data?.data?.status === "successful" ? `../images/success.svg`: resp_data?.data?.status === "failed" ?  `../images/failed.png`: `../images/pending.png`}
            className="success"
            alt='sucess'
           
          />

        <p className="title">{resp_data?.message}</p>
        <p className='text'>
        {resp_data?.data?.description}
        </p>
       
      </div>
      <div className="btn-wrapper">
        <Link to="/" className="btn btn-full  btn-secondary" >
          Back to Home
        </Link>

        <button className="btn  btn-full btn-prm" type="button" onClick={()=>{saveReceipt(resp_data?.transactionref)}}  disabled={isDownloading && true}> 
         
         {isDownloading ? <> <i className="fas fa-circle-notch fa-spin"></i> Downloading... </> :  'Download Receipt'}</button>
      </div>

          </div>

  </BackDrop>



  )
}
