import React, { useEffect } from 'react'
import { useBankData } from '../hooks/useBankDopnDown';
import Select from "react-select";
import { BankAccountState, fetchPayoutAccount, updateBank, updatePayoutAccount, validateAccountNumber } from '../../../redux/settings/bankSlice';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingBox } from '../../../components/LoadingBox';
import showerror from '../../../components/ErrorToast';
import {  getUserState } from '../../../redux/authentication/getUserSlice';
import { getOTP, getOtpState } from '../../../redux/otp/otpSlice';


export const PayoutAccount = () => {
  const [bankFormat] = useBankData();
  const { loading,bankCode,bankName,accountNumber, accountName,otp} = useSelector(BankAccountState);
  const { loading:getOtpLoading} = useSelector(getOtpState);

  const dispatch = useDispatch();
  const { activeBusiness }  = useSelector(getUserState)

  useEffect(() => {
    dispatch(fetchPayoutAccount(activeBusiness?.businessid));
    
  }, [activeBusiness?.businessid, dispatch]);

  useEffect(() => {
    if (accountNumber && bankCode &&  accountNumber.length === 10){ 
      const postdata = {
        bankcode:bankCode,
       
        accountnumber:accountNumber
      }
      dispatch(validateAccountNumber(postdata))
    }else{
      dispatch(updateBank({ name: 'accountName', value: "" }));

    }

  }, [accountNumber, bankCode, dispatch])
  
 
  const handlechange = (e) => {
    const { name, value } = e.target;
    dispatch(updateBank({ name, value }));

  }

  function handleSelect(data) {
   dispatch(updateBank({ name: 'bankCode', value: data.value.BankCode }));
   dispatch(updateBank({ name: 'bankName', value: data.value.BankName }));
    
  }

  
const getOtphandler = ()=>{
    const payloaddata = {
        user_agent:navigator.userAgent,
        service: "Payout Bank Account Change"
    }
      dispatch(getOTP(payloaddata))

}

  const submitHandler = (e) => {
    e.preventDefault()
    if (!accountNumber) {
      showerror("account number is required")
    }
    else if (!accountName) {
      showerror("account name is required")
    }

    else if (!bankCode) {
      showerror("bank code is required")
    }

    else if (!otp) {
      showerror("OTP code is required")
    }

    else if (!bankName) {
      showerror("bank name is required")
    }
    
    else {

      const user_agent = navigator.userAgent;
      const  service =  "Payout Bank Account Change";
      const data = {accountNumber,accountName,bankCode,bankName,otp,user_agent,service }
      const payloaddata = { businessID: activeBusiness?.businessid,data}
      dispatch(updatePayoutAccount(payloaddata))

          }
  }


  const colourStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      padding: "5px 0px 5px 15px",
      borderColor: state.isFocused ? "#f87e27" : "#ccc",
      "&:hover": {
        border: "1px solid #ccc",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f87e27"
        : state.isFocused
        ? "#ffe9da"
        : provided.backgroundColor,
    }),
  };

  return (
    
    <>
    
    <div className="card profile">
    {loading &&  <LoadingBox/> }
      <div className="setting-profile">
        <h2>Add Business Bank Account</h2>

    
    <form  onSubmit={submitHandler}>
              
           

<div className="add-bank">
                  <label htmlFor="bank-name">Bank Name</label>
                
                </div>

                <Select
              placeholder="Select Bank"
              options={bankFormat}
              value=
              {
                bankCode !== "" && {
                  label: bankName,
                  value: {bank:bankName,code:bankCode},
                }
              }
              onChange={handleSelect}
              
              isSearchable={true}
              styles={colourStyles}
              name="state"
            />
                
                {/* </>
                
               
                
              }    */}

                <div className="edit-profile">
                  <label htmlFor="acct-number">Account Number</label>
                  <input type="number" name="accountNumber" maxlength="10" value={accountNumber} onChange={handlechange}  />
                </div>
             
             <div className="edit-profile">
                  <label htmlFor="acct-name">Account Name</label>
                  <input type="text" name="accountName" value={accountName} disabled/>
                </div>

            
           <div className="edit-profile">
             <label htmlFor="acct-name">OTP </label>

            <div class="div-otp-flex">
            <input type="text"  name = "otp" value={otp} onChange={handlechange} />
            <button type="button" className="btn btn-secondary"  onClick={getOtphandler}
            disabled={getOtpLoading ? true : false}
            >
              {getOtpLoading ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : (
                "Get OTP"
              )}

            </button>

            </div> </div>

                <div className="">
                  <button type="submit" disabled ={accountName && otp?.length === 6 ? false : true} className="btn btn-full  btn-secondary">Save</button>
                </div>
              </form>
            </div>
            </div>
      
       
    </>
  )
}
