export const randomWords = [
    'adventure',
    'apple',
    'beach',
    'bicycle',
    'blue',
    'book',
    'cake',
    'camera',
    'cat',
    'chocolate',
    'coffee',
    'cookie',
    'daisy',
    'dog',
    'dolphin',
    'dragon',
    'dream',
    'elephant',
    'fire',
    'flower',
    'forest',
    'friend',
    'fruit',
    'garden',
    'gift',
    'guitar',
    'happiness',
    'heart',
    'home',
    'honey',
    'ice cream',
    'island',
    'jazz',
    'journey',
    'key',
    'kiss',
    'lake',
    'lemon',
    'light',
    'love',
    'magic',
    'moon',
    'mountain',
    'music',
    'nature',
    'ocean',
    'orange',
    'painting',
    'panda',
    'paradise',
    'peace',
    'pear',
    'penguin',
    'piano',
    'pineapple',
    'pizza',
    'popcorn',
    'purple',
    'rain',
    'rainbow',
    'river',
    'rose',
    'sailing',
    'sand',
    'sea',
    'ship',
    'sky',
    'smile',
    'snow',
    'song',
    'star',
    'strawberry',
    'sun',
    'sunflower',
    'sunset',
    'surprise',
    'sweet',
    'tea',
    'thunder',
    'tiger',
    'travel',
    'tree',
    'turtle',
    'umbrella',
    'unicorn',
    'vacation',
    'waterfall',
    'wave',
    'whale',
    'wind',
    'wine',
    'winter',
    'wonder',
    'yellow',
    "acorn",
  "balloon",
  "barbecue",
  "butterfly",
  "cactus",
  "camera",
  "candle",
  "carousel",
  "celery",
  "cherry",
  "chimney",
  "compass",
  "cupcake",
  "dandelion",
  "dinner",
  "disco",
  "drum",
  "duck",
  "earthquake",
  "electricity",
  "fireplace",
  "flamingo",
  "flip-flops",
  "football",
  "fountain",
  "fried chicken",
  "fruit salad",
  "galaxy",
  "garlic",
  "ghost",
  "glacier",
  "grape",
  "guava",
  "hamburger",
  "handshake",
  "hedgehog",
  "helicopter",
  "hibiscus",
  "hockey",
  "honeycomb",
  "hurricane",
  "iceberg",
  "iguana",
  "jacket",
  "jellyfish",
  "kangaroo",
  "kiwi",
  "ladder",
  "lighthouse",
  "lobster",
  "mango",
  "marshmallow",
  "mermaid",
  "microphone",
  "mistletoe",
  "muffin",
  "mustache",
  "night",
  "oasis",
  "octopus",
  "opera",
  "orangutan",
  "pajamas",
  "palm tree",
  "panda",
  "peanut",
  "pearl",
  "pepperoni",
  "picnic",
  "pilot",
  "popsicle",
  "pretzel",
  "pumpkin",
  "quicksand",
  "quilt",
  "raccoon",
  "raincoat",
  "rattlesnake",
  "robot",
  "rocket",
  "roller coaster",
  "sailboat",
  "sardine",
  "scarecrow",
  "seahorse",
  "shark",
  "sherbet",
  "skateboard",
  "slippers",
  "snowman",
  "spaceship",
  "spider",
  "spring",
  "steak",
  "sunrise",
  "sushi",
  "swing",
  "taco",
  "teddy bear",
  "tent",
  "tornado",
  "toucan",
  "train",
  "treasure",
  "tulip",
  "umbrella",
  "unicorn",
  "volcano",
  "watermelon",
  "whistle",
  "windmill",
  "xylophone",
  "yacht",
  "zebra"
  ];