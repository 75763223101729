import React from 'react'
import { Link } from 'react-router-dom'

export const VirtualaccountTable = ({virtualaccount}) => {
  return (
    <div className="transac-table">
    <table className="table">
      <thead>
        <tr>
       
          <th>REFERENCE</th>
          <th>BANK NAME</th>
          <th>ACCOUNT NUMBER</th>
          <th>ACCOUNT NAME</th>
          <th>ACTION</th>
          
         
        </tr>
      </thead>
      <tbody>
     

          {    virtualaccount?.results?.map((account)=> <tr key={account.trackingReference}>
         
             <td>{account.trackingReference}</td>
              <td>{account.bankName}</td>
              <td>{account.accountNumber}</td>
              <td>{account.accountName}</td>
              <td><Link to={`/virtual-account/${account.accountNumber}`} className="transaction_type trans-default"> View  </Link></td>
              
        </tr>)}

        
        
        </tbody>
    </table>
  </div>
  )
}
