import React from 'react'

export const Pagination = ({transactions,goto}) => {
  return (
    <div className="page-number">
           <div className="card flex-number">
               {transactions?.has_previous &&   <p onClick={()=>goto(transactions?.previous_page_number)}><i className="fas next active-two fa-angle-left" /></p>}

               { transactions?.elipse?.map((page)=>
               page != null ? <p className= {transactions?.page === page ?  "active-two" : ""}  onClick={()=>goto(page)}>{page}</p> : <p>...</p>
               )}
             {transactions?.has_next &&   <p onClick={()=>goto(transactions?.next_page_number)}><i className="fas next active-two fa-angle-right" /></p>}
            
           </div>
         </div>
  )
}
