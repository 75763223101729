import React from 'react'
import { Link } from 'react-router-dom'

export const SettingsHeader = ({activeTab,activeTabAction}) => {
  return (
    <div className="card setting">
    <div className="settings-nav">
      <li><Link to="#" className={activeTab === "Profile"? "setting-active":"" } onClick={()=>activeTabAction("Profile")}> Profile</Link></li>
      <li><Link to="#" className={activeTab === "Business Info"? "setting-active":"" } onClick={()=>activeTabAction("Business Info")}> Business Info</Link></li>
      <li><Link to="#" className={activeTab === "Payout Bank Accounts"? "setting-active":"" } onClick={()=>activeTabAction("Payout Bank Accounts")}> Payout Bank Accounts</Link></li> 
      <li><Link to="#" className={activeTab === "API Key"? "setting-active":"" } onClick={()=>activeTabAction("API Key")}>  API Key</Link></li>
      <li><Link to="#" className={activeTab === "WebHook"? "setting-active":"" } onClick={()=>activeTabAction("WebHook")}> WebHook</Link></li>
      {/* <li><Link to="#" className={activeTab === "Preferences"? "setting-active":"" } onClick={()=>activeTabAction("Preferences")}> Preferences</Link></li> */}
    </div>
  </div>
  )
}
