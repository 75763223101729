import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Base } from '../../components/Base'
import { EmptyComponent } from '../../components/EmptyComponent';
import showerror from '../../components/ErrorToast';
import { LoadingBox } from '../../components/LoadingBox';
import { getUserState } from '../../redux/authentication/getUserSlice';
import { fetchwebhookevent, geteventStatistic, sendWebhook, webhookeventState } from '../../redux/webhookEvents/webhookeventSlice';
import { resetFilter, set_Filter, webhookFilter, webhookFilterState } from '../../redux/webhookEvents/webhookfilterSlice';
import { resetQuery, setQuery, webhookquery, webhookqueryState } from '../../redux/webhookEvents/webhookquerySlice';
import { Pagination } from './components/Pagination';
import { TableComponets } from './components/TableComponets';

export const WebhookScreen = () => {

    const dispatch = useDispatch();
    const { activeBusiness }  = useSelector(getUserState)
    const { webhookevents,loading,sending,statistic }  = useSelector(webhookeventState)
    const { webhookquerylist,queryloading,query }  = useSelector(webhookqueryState)
     const { webhookfilterlist,filterloading,filter }  = useSelector(webhookFilterState)
    const { selectedtransaction }  = useSelector(webhookeventState)
    const [isfilter, setFilter] = useState(false)
    const [filtervalue, setFiltervalue] = useState("")
    
    useEffect(() => {
  
      dispatch(fetchwebhookevent({"businessid":activeBusiness?.businessid,"page":null}))
      dispatch(geteventStatistic({"businessid":activeBusiness?.businessid}))
      
    }, [activeBusiness,dispatch]);


    const goto =(page)=>{
        dispatch(fetchwebhookevent({"businessid":activeBusiness?.businessid,"page":page}))
    }

    const pushwebhook =()=>{
      if(selectedtransaction.length < 1){
        showerror("Select at least one transaction to push")

      }
      else{
      dispatch(sendWebhook({"businessid":activeBusiness?.businessid,"transactions":selectedtransaction}))
      

      }
  }

    const search = (event)=>{
      const query = event.target.value
      dispatch(setQuery(query)) 
      
      if (query?.length > 0){
        dispatch(webhookquery({"businessid":activeBusiness?.businessid,"query":query}))
      }
      else{
        dispatch(resetQuery()) 
      }
    }

    const clearfilter = ()=>{
      setFilter(false);
     dispatch(resetFilter());
     
     }

     const getFilter = () => {
      setFilter(true);
      
      let filterbox = document.querySelector(".filter-form");
      filterbox.classList.toggle("toggle-filter");
    };
  

   const  onChangeValue =(e)=> {
     
      setFiltervalue(e.target.value)
      
    }

    const filterWebhook = ()=>{
       dispatch(set_Filter(filtervalue))
      dispatch(webhookFilter({"businessid":activeBusiness?.businessid,"filter":filtervalue}))
      let filterbox = document.querySelector(".filter-form");
      filterbox.classList.remove("toggle-filter");
    
    }
     

  return (
    <Base title={"Webhook Event"} icon={"dashboard_"}>

    <>
    <div>
    <div className="boxes">

    <div className="card dash-card">
    <p>Sent</p>
    <h2>{statistic?.response?.succesful}</h2>
    
  </div>

  <div className="card dash-card">
    <p>In Progress</p>
    <h2>{statistic?.response?.pending}</h2>
    
  </div>

  <div className="card dash-card">
    <p>Failed</p>
    <h2>{statistic?.response?.failed}</h2>
    
  </div>
  </div>


         <div className="transaction-table">
             
           <div className="card">

             {queryloading && <LoadingBox/>}
           {loading && <LoadingBox/>}
           {sending && <LoadingBox/>}
           {filterloading && <LoadingBox/>}

           <div className="trade-preview">
            <form action>
              <div className="p-relative">
            
              <input type="text" value={query} onChange={search} className="transac-search" placeholder="Search" />
              {query?.length > 0 &&  <span className="form-input-append">
                <i className= "fa fa-times" onClick={()=>dispatch(resetQuery()) }></i>
              </span>}
             
              </div>
            </form>

            <div div class="filter-d-flex">

             {query?.length > 0 ? webhookquerylist?.results?.length > 0 ?
              <input type="button" onClick={pushwebhook} value="Push Notification" className="btn btn-prm"  />:<></>:
              <input type="button" onClick={pushwebhook} value="Push Notification" className="btn btn-prm"  />
              }

 <div className="filter-plus">

{isfilter &&  <button className="year-option filter" onClick={clearfilter}>Clear Filter <i className="fa fa-times" />
</button> }


 <button className="year-option filter" onClick={getFilter}>Filter <i className="fa fa-filter" />
 </button>

 <div className="filter-form card" onChange={onChangeValue}>

 <div className="filter-radio">
    
     
     <div className="flex">
     <label>Failed</label>
     <input type="radio" name="filter"  className='filter_radio' value="failed"></input>
     </div>
   </div>

   <div className="filter-radio" >
    
     
    <div className="flex">
    <label>Succesful</label>
    <input type="radio" name="filter"  className='filter_radio' value="sent"></input>
    </div>
    </div>

    <input type="button"  onClick={filterWebhook}  value="Apply Filter" className="btn btn-prm"  />
              

    </div>


</div>
</div>

          </div>
               
               {query?.length > 0 ? webhookquerylist?.results?.length > 0 ?
                <TableComponets  webhookevents={webhookquerylist} />: <EmptyComponent image={`../images/empty.png`} text={'Your query did not match any records.'} />
: filter?.length > 0 ? webhookfilterlist?.results?.length > 0 ?
<TableComponets  webhookevents={webhookfilterlist} />: <EmptyComponent image={`../images/empty.png`} text={'Your filter did not match any records.'} />:

                <TableComponets  webhookevents={webhookevents} />
               
              }
           </div>
         </div>

         {query?.length > 0 ? webhookquerylist?.results?.length > 0 ?
              <Pagination  webhookevents={webhookquerylist}  goto={goto}/>:<></>:filter?.length > 0 ? webhookfilterlist?.results?.length > 0 ?<Pagination  webhookevents={webhookfilterlist}  goto={goto}/>:<></>:
              webhookevents?.results?.length > 0 && <Pagination  webhookevents={webhookevents}  goto={goto}/>
               
              }

       
       </div>
    </>
    
 </Base>
  )
}
