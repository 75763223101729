import {
  getComplianceState,
  resetComplianceState,
  uploadDocument,
} from "../../../redux/kyc/getcomplianceSlice";
import { getUserState } from "../../../redux/authentication/getUserSlice";
import { hideCertModal } from "../../../redux/kyc/generalSlice";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import showerror from "../../../components/ErrorToast";
import { MdOutlineClose } from "react-icons/md";
import { useState, useEffect } from "react";
import { BackDrop } from "../../../components/BackDrop";

export const CertificateModal = () => {
  const [fileUpload, setFileUpload] = useState({
    file: null,
    isUploaded: false,
  });
  const { uploadloading, success } = useSelector(getComplianceState);
  const { activeBusiness } = useSelector(getUserState);
  const { file, isUploaded } = fileUpload;
  const dispatch = useDispatch();

  const handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    setFileUpload({ file, isUploaded: true });
  };

  useEffect(() => {
    if (success === true) {
      dispatch(resetComplianceState())
      dispatch(hideCertModal());
    }
  }, [dispatch, success]);

  const handlesubmit = () => {
    if (!file) {
      showerror("Please upload valid Certificate of Incorporation");
      return;
    } else {
      let formData = new FormData();
      formData.append("certificate_of_incorporation", file);
      formData.append("action", "CERTIFICATE_OF_INCORPORATION");

      let payloaddata = {
        businessID: activeBusiness?.businessid,
        formData,
      };

      dispatch(uploadDocument(payloaddata));
    }
  };

  return (
    <BackDrop>
      <div className="modal">
        <div className="header">
          <div className="close-icon" onClick={() => dispatch(hideCertModal())}>
            <MdOutlineClose size={20} color="#fff" />
          </div>
        </div>
        <div className="desc">
          <HiOutlineExclamationCircle size={20} />
          <p>Upload your Government Issued Business Document</p>
        </div>
        <div className="body-proof">
          <div className="upload">
            <input
              type="file"
              name="driver-license"
              id="driver-upl"
              accept="image/*,.pdf"
              hidden
              onChange={handleFileUpload}
            />
            <label className="upload-label" htmlFor="driver-upl">
              {!isUploaded && (
                <span>
                  <svg
                    width="21"
                    height="25"
                    viewBox="0 0 21 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.3305 0.0151598C13.2778 0.00520151 13.2235 0 13.1681 0C13.1126 0 13.0584 0.00520151 13.0057 0.0151598H5.5008C2.51604 0.0151598 0 2.527 0 5.59072V18.9564C0 22.1898 2.40101 24.7286 5.5008 24.7286H15.0864C18.0332 24.7286 20.4624 22.0622 20.4624 18.9564V7.51646C20.4624 7.27541 20.3725 7.0437 20.2116 6.86983L14.1324 0.302148C13.9627 0.1188 13.7282 0.0151598 13.4832 0.0151598H13.3305ZM12.2681 1.88081L5.50109 1.88181C3.49534 1.88181 1.80029 3.57402 1.80029 5.59013V18.9558C1.80029 21.1659 3.4032 22.8608 5.50109 22.8608H15.0867C17.0159 22.8608 18.6627 21.0532 18.6627 18.9558L18.6624 8.69269L17.6103 8.69641C17.2102 8.69591 16.755 8.69503 16.249 8.69383C14.1246 8.68918 12.3914 6.96288 12.2744 4.78934L12.2681 4.5548V1.88081ZM17.6772 6.8264L16.2529 6.82661C15.0453 6.82396 14.0681 5.8078 14.0681 4.5548V2.92746L17.6772 6.8264ZM8.86865 11.5211V17.4442L8.87687 17.5709C8.93646 18.0266 9.31302 18.3778 9.76865 18.3778C10.2657 18.3778 10.6687 17.9598 10.6687 17.4442V11.5234L12.5366 13.4692L12.6373 13.5599C12.9891 13.8318 13.4892 13.8028 13.8094 13.472C14.1616 13.1082 14.1628 12.5171 13.8121 12.1517L10.4267 8.62506C10.3418 8.5307 10.2383 8.45463 10.1222 8.40314C9.79331 8.25705 9.3976 8.32362 9.12967 8.60283L5.72407 12.1518L5.63715 12.2567C5.37653 12.6228 5.40672 13.1414 5.72697 13.4721L5.82811 13.5623C6.18105 13.8327 6.68097 13.8013 6.99976 13.4691L8.86865 11.5211Z"
                      fill="#FF6A00"
                    ></path>
                  </svg>
                </span>
              )}
              <h5>{isUploaded ? file.name : "Select File"}</h5>
            </label>
          </div>
          {uploadloading ? (
            <button className="btn-upload" disabled={true}>
              {" "}
              <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>{" "}
              Please wait ...
            </button>
          ) : (
            <button
              className="btn-upload"
              onClick={() => handlesubmit()}
              disabled={isUploaded ? false : true}
            >
              Upload
            </button>
          )}
        </div>
      </div>
    </BackDrop>
  );
};
