import { rootUrl } from "../utils/useAxios";


export const downloadPdf = (id) => {
    fetch(`${rootUrl}/get-receipt/${id}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/pdf',
        },
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `Receipt-${id}.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            
            link.parentNode.removeChild(link);
        });
}
