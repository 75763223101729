import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const filtertransaction = createAsyncThunk("filtertransactions/filter", async (payloaddata,thunkAPI) => {

  try {
    var url = `/api/v1/transactions/${payloaddata?.businessid}/?start_date=${payloaddata?.start_date}&end_date=${payloaddata?.end_date}`
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});


const filtertransactionSlice = createSlice({
  name: "filtertransactions",
  initialState: {
    filtertransactionlist: null,
   
    filterloading: false,
  },

  reducers: {
    resetFilter: (state) => {
      state.filtertransactionlist = null;
      
      state.filterloading = false;
    },
    setFilter: (state,action) => {
      state.filter= action.payload;
     
    },
  },

  extraReducers: {
    [filtertransaction.fulfilled]: (state, action) => {
      state.filterloading = false;
      state.filtertransactionlist = action.payload;
      
    },
    [filtertransaction.pending]: (state) => {
      state.filterloading = true;
    },
    [filtertransaction.rejected]: (state) => {
      state.filterloading = false;
    },
  },
});

export const filtertransactionState = (state) => state.filtertransactions;
export default filtertransactionSlice.reducer;
export const { resetFilter,setFilter } = filtertransactionSlice.actions;


