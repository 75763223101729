import React, { useEffect } from 'react'


// add 'loadingbox' to parent class before using this component
export const LoadingBox = () => {

    useEffect(() => {
        const child = document.getElementById('loadingoverlay');
        child.parentElement.classList.add('loadingbox');
    }, [])


    
  return (
    <div className='loadingoverlay' id="loadingoverlay">

    <div className='loadingContent'>

    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>

    </div>


  </div>
  )
}
