import { useQuery } from "react-query";
import axios from "axios";
import { useState } from "react";
import { decryptPayload, rootUrl } from "../../../utils/useAxios";

export const useBankData = () => {
  const [bankFormat, setBankFormat] = useState([]);
  const fetchBankList = async () => {
    const { data } = await axios.get(`${rootUrl}/api/v1/banklist/`);


    var  respdata = JSON.parse(decryptPayload(data.data))


    if (respdata) {
      
     

      const modifiedResponse = respdata.map((state) => {
        return {
          value: state,
          label: state.BankName,
        };
      });
      setBankFormat(modifiedResponse);
    }

    return respdata;
  };
  return [bankFormat, useQuery("banklist", fetchBankList)];
};


