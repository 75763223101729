import { StepCircleProgress } from "./components/StepCircleProgress";
import { getComplianceState } from "../../redux/kyc/getcomplianceSlice";
import { AddressProofModal } from "./components/AddressProofModal";
import { PersonaInfoModal } from "./components/PersonaInfoModal";
import { CertificateModal } from "./components/CertificateModal";
import { VideoModal } from "./components/VideoModal";
import { Document } from "./components/Documents";
import { BVNModal } from "./components/BVNModal";
import { Profile } from "./components/Profile";
import { Contact } from "./components/Contact";
import { Base } from "../../components/Base";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCompliance } from "../../redux/kyc/getcomplianceSlice";
import { getUserState } from "../../redux/authentication/getUserSlice";

export const ComplianceScreen = () => {
  const dispatch = useDispatch();
  const { activeBusiness } = useSelector(getUserState);
  const { success } = useSelector(getComplianceState);

  useEffect(() => {
    dispatch(getCompliance(activeBusiness?.businessid));
  }, [activeBusiness, dispatch, success]);

  const showPerInfo = useSelector((state) => state.general.perInfo);
  const showBVN = useSelector((state) => state.general.bvn);
  const showVideo = useSelector((state) => state.general.video);
  const showProof = useSelector((state) => state.general.proof);
  const showCert = useSelector((state) => state.general.cert);
  const [stepStyeWidth, setStepStyleWidth] = useState(0);

  const nextStep = () => {
    setStepStyleWidth((prev) => prev + 1);
  };

  const stepOne = () => {
    setStepStyleWidth(0);
  };

  const stepTwo = () => {
    setStepStyleWidth(1);
  };

  const stepThree = () => {
    setStepStyleWidth(2);
  };

  return (
    <Base title={"Compliance"} icon={"bubble"}>
      <div className="complaince">
        <StepCircleProgress
          stepStyeWidth={stepStyeWidth}
          stepOne={stepOne}
          stepTwo={stepTwo}
          stepThree={stepThree}
        />
        <div className="content">
          {/* PAGES */}
          {stepStyeWidth === 0 && <Profile onNext={nextStep} />}
          {stepStyeWidth === 1 && <Contact onNext={nextStep} />}
          {stepStyeWidth === 2 && <Document />}
          {/* MODALS */}
          {showPerInfo && <PersonaInfoModal />}
          {showBVN && <BVNModal />}
          {showVideo && <VideoModal />}
          {showProof && <AddressProofModal />}
          {showCert && <CertificateModal />}
         
        </div>
      </div>
    </Base>
  );
};
