import { hidePerInfoModal } from "../../../redux/kyc/generalSlice";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { BackDrop } from "../../../components/BackDrop";
import { useState } from "react";
import {
  getComplianceState,
  resetComplianceState,
  uploadDocument,
} from "../../../redux/kyc/getcomplianceSlice";
import { getUserState } from "../../../redux/authentication/getUserSlice";
import showerror from "../../../components/ErrorToast";
import { useEffect } from "react";

export const PersonaInfoModal = () => {
  const [fileUpload, setFileUpload] = useState({
    file: null,
    isUploaded: false,
  });
  const { file, isUploaded } = fileUpload;
  const [name, setName] = useState("");
  const dispatch = useDispatch();
  const { activeBusiness } = useSelector(getUserState);
  const { uploadloading, success } = useSelector(getComplianceState);

  useEffect(() => {
    if (success === true) {
      dispatch(resetComplianceState())
      dispatch(hidePerInfoModal());
    }
  }, [dispatch, success]);

  const handlesubmit = (file, name) => {
    if (!name) {
      showerror("Document name is required");
      return;
    } else if (!file) {
      showerror("Please upload valid Nigerian Government-issued ID card");
      return;
    } else {
      let formData = new FormData();
      formData.append("personal_document", file);
      formData.append("personal_document_name", name);
      formData.append("action", "PERSONAL_DOCUMENT");

      let payloaddata = {
        businessID: activeBusiness?.businessid,
        name,
        formData,
      };

      dispatch(uploadDocument(payloaddata));
    }
  };

  const handleFileUpload = (evt) => {
    const file = evt.target.files[0];
    setFileUpload((prev) => ({
      file,
      isUploaded: true,
    }));
  };

  return (
    <BackDrop>
      <div className="modal">
        <div className="header">
          <div
            className="close-icon"
            onClick={() => dispatch(hidePerInfoModal())}
          >
            <MdOutlineClose size={20} color="#fff" />
          </div>
        </div>
        <div className="desc">
          <HiOutlineExclamationCircle size={20} />
          <p>Upload your unexpired Nigerian Government-issued ID card</p>
        </div>
        <div className="body disable-scrollbars">
          <div className="driver-license">
            <div>
              <span>
                <svg
                  width="37"
                  height="37"
                  viewBox="0 0 37 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      d="M32.7603 6.9375H4.23968C1.90092 6.9375 0 8.83842 0 11.1772V30.4478C0 32.7866 1.90092 34.6875 4.23968 34.6875H32.7603C35.0991 34.6875 37 32.7866 37 30.4478V11.1772C37 8.83842 35.0991 6.9375 32.7603 6.9375Z"
                      fill="#CCCCCC"
                      fillOpacity="0.25"
                    ></path>
                    <path
                      d="M15.4162 16.9598C15.4162 19.0883 13.6908 20.8139 11.5621 20.8139C9.43335 20.8139 7.70801 19.0883 7.70801 16.9598C7.70801 14.8311 9.43335 13.1055 11.5621 13.1055C13.6908 13.1055 15.4162 14.8311 15.4162 16.9598Z"
                      fill="#CCCCCC"
                    ></path>
                    <path
                      d="M14.2603 22.3555H8.86468C6.52592 22.3555 4.625 24.2564 4.625 26.5951V27.3661C4.625 28.0043 5.143 28.5223 5.78125 28.5223H17.3438C17.982 28.5223 18.5 28.0043 18.5 27.3661V26.5951C18.5 24.2564 16.5991 22.3555 14.2603 22.3555Z"
                      fill="#CCCCCC"
                    ></path>
                    <path
                      d="M31.2183 16.1875H22.7393C22.101 16.1875 21.583 15.6695 21.583 15.0312C21.583 14.393 22.101 13.875 22.7393 13.875H31.2183C31.8566 13.875 32.3746 14.393 32.3746 15.0312C32.3746 15.6695 31.8566 16.1875 31.2183 16.1875Z"
                      fill="#CCCCCC"
                    ></path>
                    <path
                      d="M31.2183 22.3555H22.7393C22.101 22.3555 21.583 21.8375 21.583 21.1992C21.583 20.561 22.101 20.043 22.7393 20.043H31.2183C31.8566 20.043 32.3746 20.561 32.3746 21.1992C32.3746 21.8375 31.8566 22.3555 31.2183 22.3555Z"
                      fill="#CCCCCC"
                    ></path>
                    <path
                      d="M31.2183 28.5195H22.7393C22.101 28.5195 21.583 28.0015 21.583 27.3633C21.583 26.725 22.101 26.207 22.7393 26.207H31.2183C31.8566 26.207 32.3746 26.725 32.3746 27.3633C32.3746 28.0015 31.8566 28.5195 31.2183 28.5195Z"
                      fill="#CCCCCC"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="37" height="37" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <h5>Nigerian Government-issued ID card</h5>
            </div>

            <div className="">
              <label htmlFor="bvn">Choose Your Document Type</label>
              <select
                className="form-input form-select mt-4"
                value={name}
                onChange={(e) => setName(e.target.value)}
              >
                <option value="">-----Select Your Document Type-----</option>
                <option value="Driver's License">Driver's License</option>
                <option value="Voter's Card">Voter's Card</option>
                <option value="NIN Slip">NIN Slip</option>
                <option value="International Passport">
                  International Passport
                </option>
                <option value="National ID Card">National ID Card</option>
              </select>
            </div>

            <div className="upload mt-12">
              <input
                type="file"
                name="driver-license"
                id="driver-upl"
                accept="image/*,.pdf"
                hidden
                onChange={handleFileUpload}
              />
              <label className="upload-label" htmlFor="driver-upl">
                {!isUploaded && (
                  <span>
                    <svg
                      width="21"
                      height="25"
                      viewBox="0 0 21 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.3305 0.0151598C13.2778 0.00520151 13.2235 0 13.1681 0C13.1126 0 13.0584 0.00520151 13.0057 0.0151598H5.5008C2.51604 0.0151598 0 2.527 0 5.59072V18.9564C0 22.1898 2.40101 24.7286 5.5008 24.7286H15.0864C18.0332 24.7286 20.4624 22.0622 20.4624 18.9564V7.51646C20.4624 7.27541 20.3725 7.0437 20.2116 6.86983L14.1324 0.302148C13.9627 0.1188 13.7282 0.0151598 13.4832 0.0151598H13.3305ZM12.2681 1.88081L5.50109 1.88181C3.49534 1.88181 1.80029 3.57402 1.80029 5.59013V18.9558C1.80029 21.1659 3.4032 22.8608 5.50109 22.8608H15.0867C17.0159 22.8608 18.6627 21.0532 18.6627 18.9558L18.6624 8.69269L17.6103 8.69641C17.2102 8.69591 16.755 8.69503 16.249 8.69383C14.1246 8.68918 12.3914 6.96288 12.2744 4.78934L12.2681 4.5548V1.88081ZM17.6772 6.8264L16.2529 6.82661C15.0453 6.82396 14.0681 5.8078 14.0681 4.5548V2.92746L17.6772 6.8264ZM8.86865 11.5211V17.4442L8.87687 17.5709C8.93646 18.0266 9.31302 18.3778 9.76865 18.3778C10.2657 18.3778 10.6687 17.9598 10.6687 17.4442V11.5234L12.5366 13.4692L12.6373 13.5599C12.9891 13.8318 13.4892 13.8028 13.8094 13.472C14.1616 13.1082 14.1628 12.5171 13.8121 12.1517L10.4267 8.62506C10.3418 8.5307 10.2383 8.45463 10.1222 8.40314C9.79331 8.25705 9.3976 8.32362 9.12967 8.60283L5.72407 12.1518L5.63715 12.2567C5.37653 12.6228 5.40672 13.1414 5.72697 13.4721L5.82811 13.5623C6.18105 13.8327 6.68097 13.8013 6.99976 13.4691L8.86865 11.5211Z"
                        fill="#FF6A00"
                      ></path>
                    </svg>
                  </span>
                )}
                <h5>{isUploaded ? file.name : "Select File"}</h5>
              </label>
              <h5>Upload the Front of your Identity Card</h5>
              {uploadloading ? (
                <button className="btn-upload" disabled={true}>
                  {" "}
                  <i
                    className="fa fa-spinner fa-spin"
                    aria-hidden="true"
                  ></i>{" "}
                  Please wait ...
                </button>
              ) : (
                <button
                  className="btn-upload"
                  disabled={isUploaded ? false : true}
                  onClick={() => handlesubmit(file, name)}
                >
                  Upload
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </BackDrop>
  );
};
