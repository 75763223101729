import { selectedWebhookData, webhookModal } from "../redux/kyc/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../utils/Dateformat";
import { MdOutlineClose } from "react-icons/md";
import { BackDrop } from "./BackDrop";

export const WebhookModal = () => {
  const data = useSelector((state) => state.general.webhookData);
  const dispatch = useDispatch();

  const closeTransModal = () => {
    dispatch(webhookModal(false));
    dispatch(selectedWebhookData(null));
  };

  return (
    <BackDrop>
      <div className="modal">
        <div className="header">
          <div className="close-icon" onClick={() => closeTransModal()}>
            <MdOutlineClose size={20} color="#fff" />
          </div>
        </div>
        <div className="body-trans">
          {data && (
            <>

              <div className="trans-data">
                <div>Date:</div>
                <div>{formatDate(data.created_date)}</div>
                </div>

                <div className="trans-data">
                <div>Reference:</div>
                <div>{data.reference}</div>
                </div>

                <div className="trans-data">
                <div>Attempt:</div>
                <div>{data.attempts}</div>
                </div>

                <div className="trans-data">
                <div>Webhook Url:</div>
                <div>{data.url}</div>
                </div>

                <div className="trans-data">
                <div>Request Time:</div>
                <div>{data.request_time}</div>
                </div>

                <div className="trans-data code-section">
                <div>Request Payload:</div>
                <div>{JSON.stringify(data.getbody)}</div>
                </div>

                <div className="trans-data">
                <div>Status:</div>
                <div className="trans-status"><span className={data.status === "sent" ? "btn-success " : data.status === "failed" ? "btn-danger" :"btn-warn"}>{data.status}</span></div>
                </div> 

                <div className="trans-data code-section" >
                <div>Response Body:</div>
                <div>{data.response_message}</div>
                </div>
               
                <div className="trans-data">
                <div>Response Time:</div>
                <div>{data.request_time}</div>
              
                </div>
                <div className="trans-data">
                <div>Response Code:</div>
                <div>{data.response_code}</div>
                </div>

                

            </>
          )}
        </div>
        <div className="btn-trans-close">
          <button className="btn btn-prm" onClick={() => closeTransModal()}>
            Close
          </button>
        </div>
      </div>
    </BackDrop>
  );
};
