import { selectedTransData, transModal } from "../redux/kyc/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatMoney } from "../utils/FormatMoney";
import { formatDate } from "../utils/Dateformat";
import { MdOutlineClose } from "react-icons/md";
import { BackDrop } from "./BackDrop";

export const TransactionModal = () => {
  const data = useSelector((state) => state.general.transData);
  const dispatch = useDispatch();

  const closeTransModal = () => {
    dispatch(transModal(false));
    dispatch(selectedTransData(null));
  };

  return (
    <BackDrop>
      <div className="modal">
        <div className="header">
          <div className="close-icon" onClick={() => closeTransModal()}>
            <MdOutlineClose size={20} color="#fff" />
          </div>
        </div>
        <div className="body-trans">
          {data && (
            <>
              <div className="trans-data">
                <div>Date:</div>
                <div>{formatDate(data.created_date)}</div>
                </div>

                <div className="trans-data">
                <div>Reference:</div>
                <div>{data.transactionref}</div>
                </div>

                <div className="trans-data">
                <div>Description:</div>
                <div>{data.description}</div>
               
                </div>

                <div className="trans-data">
                <div>Destination:</div>
                <div>{data.destination}</div>
                </div>

                <div className="trans-data">
                <div>Amount:</div>
                <div>₦{formatMoney(data.amount)}</div>
                
                </div>

                <div className="trans-data">
                <div>Settlement Amount:</div>
                <div>₦{formatMoney(data.settlement_amount)}</div>
                
                </div>


                <div className="trans-data">
                <div>Fee:</div>
                <div>₦{formatMoney(data.fee)}</div>
                
                </div>


                <div className="trans-data">
                <div>Status:</div>
                <div className="trans-status">{data.status}</div>
                </div>

                <div className="trans-data">
                <div>Type:</div>
                <div
                  className={
                    data.transaction_type === "DEBIT"
                      ? "transaction_type trans-debit"
                      : "transaction_type trans-credit"
                  }
                >
                  {data.transaction_type}
                </div>
                </div>

           
            </>
          )}
        </div>
        <div className="btn-trans-close">
          <button className="btn btn-prm" onClick={() => closeTransModal()}>
            Close
          </button>
        </div>
      </div>
    </BackDrop>
  );
};
