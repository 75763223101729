import { useEffect, useState } from "react";
import { Base } from "../../../components/Base";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getUserState, resetSuccess, updateOtp, verifyEmail } from "../../../redux/authentication/getUserSlice";
import { getOTP, getOtpState } from "../../../redux/otp/otpSlice";
import { LoadingBox } from "../../../components/LoadingBox";
import showerror from "../../../components/ErrorToast";
import { useNavigate } from "react-router-dom";

export const VerifyEmail = () => {

  const [showOTP, setShowOTP] = useState(false);
  const { userData,activeBusiness,otp,loading,success }  = useSelector(getUserState)
  const { loading:getOtpLoading} = useSelector(getOtpState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

 

  useEffect(() => {
    if(success){
      dispatch(resetSuccess())
      dispatch(getUser());
         
        }
    }, [dispatch, success]);
  

    useEffect(() => {
  
      if( userData  && userData.email_verify ){
        navigate("/");
          }
        
      }, [ navigate, userData]);
    


  const getOtphandler = ()=>{
    const payloaddata = {
        user_agent:navigator.userAgent,
        service: "Email Verification"
    }
      dispatch(getOTP(payloaddata))

      setShowOTP(true)

}

const submitHandler = (e) => {
  e.preventDefault()
  
   if (!otp) {
    showerror("OTP code is required")
    return
  }

  else {
    const user_agent = navigator.userAgent;
    const  service =  "Email Verification";
    const data = {otp,user_agent,service }
    const payloaddata = { businessID: activeBusiness?.businessid,data}
    dispatch(verifyEmail(payloaddata))}
}




const handlechange = (e) => {
  const { name, value } = e.target;
  dispatch(updateOtp({ name, value }));
}

  return (

    <Base title={""} icon={"dashboard_"}>
 {loading &&  <LoadingBox/> }
    <div className="feat-card">
      
    <h2 className="email-verify">Verify Email Address</h2>

        <>
          {showOTP ? (
             <form  onSubmit={submitHandler}>
            
              <p>
                A one-time password has been sent to your Registered Email Address,
                 kindly input it in the field below to verify your Email.
              </p>

              <div className="edit-profile">
             <label htmlFor="acct-name">OTP </label>

            <div class="div-otp-flex">
            <input type="text"  name = "otp" value={otp} onChange={handlechange} />
            <button type="button" className="btn btn-secondary"  
            disabled={getOtpLoading ? true : false} onClick={getOtphandler}
            >
              {getOtpLoading ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : (
                "Resend OTP"
              )}

            </button>

            </div> </div>
          
             
              <div className="trade-preview">
                <div className="btn-feat-container">
                
                </div>
                <button className="btn btn-full btn-secondary" type="submit">
                  Verify
                </button>
              </div>
      
            </form>
          ) : (
            <div className="">
              
              <div className="act-form">
            <label className="form-input-label ">Email Address</label>
            <input
              type="text"
              readOnly
              name="email"
              value={userData?.email}
              
              className="form-input"
              
            />
           
          </div>
              <button
                className="btn btn-full btn-secondary"
                type="submit"
                onClick={getOtphandler}
              >
                {getOtpLoading ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : 'Proceed'}
              </button>
            </div>
          )}
        </>
     
    </div>
</Base>
  );
};
