import { showBVNModal, showCertModal, showPerInfoModal, showProofModal, showVideoModal } from "../../../redux/kyc/generalSlice";
import { getComplianceState } from "../../../redux/kyc/getcomplianceSlice";
import { useDispatch, useSelector } from "react-redux";
import { CompliceStatus } from "./CompliceStatus";

export const Document = () => {
  const dispatch = useDispatch();
  const { business_compliance_data: busComData } = useSelector(getComplianceState);
  const personalDocStatus = busComData?.personal_document_verify_status;
  const BVNStatus = busComData?.bvn_verify_status;
  const videoStatus = busComData?.video_confirmation_verify_status;
  const addressStatus = busComData?.proof_of_address_verify_status;
  const certStatus = busComData?.certificate_of_incorporation_verify_status;
  const business_name = busComData?.business_name;

  console.log(busComData)

  return (
    <div className="profile">
      <div className="header">

     <div className="card unlock kycflex" style={{backgroundColor: 'blue', margin: '25px 0px',color:"white"}}>
     <div>  <h2>Are you experiencing compliance issues?</h2>
          <p>Get in touch with our compliance team at <a href="mailto:compliance@payvessel.com" >compliance@payvessel.com</a> to report them.</p>
          </div> 
        </div>


        <h3>Document</h3>
        <p>Please Upload All Documents</p>
      </div>
      <div>
        <div className="docs">
          <h5>Personal Information Document</h5>
          <span
            className={`bs-plus ${personalDocStatus}`}
            onClick={
              personalDocStatus === "rejected" || personalDocStatus === null || personalDocStatus === ""
                ? () => dispatch(showPerInfoModal())
                : () => { }
            }
          >
            <CompliceStatus status={personalDocStatus} />
          </span>
        </div>
        <div className="docs">
          <h5>BVN (Compulsory)</h5>
          <span
            className={`bs-plus ${BVNStatus}`}
            onClick={
              BVNStatus === "rejected" || BVNStatus === null || BVNStatus === ""
                ? () => dispatch(showBVNModal())
                : () => {}
            }
          >
            <CompliceStatus status={BVNStatus} />
          </span>
        </div>
        <div className="docs">
          <h5>Video Confirmation</h5>
          <span
            className={`bs-plus ${videoStatus}`}
            onClick={
              videoStatus === "rejected" || videoStatus === null || videoStatus === ""
                ? () => dispatch(showVideoModal())
                : () => {}
            }
          >
            <CompliceStatus status={videoStatus} />
          </span>
        </div>
        <div className="docs">
          <h5>Proof of Address</h5>
          <span
            className={`bs-plus ${addressStatus}`}
            onClick={
              addressStatus === "rejected" || addressStatus === null || addressStatus === ""
                ? () => dispatch(showProofModal())
                : () => {}
            }
          >
            <CompliceStatus status={addressStatus} />
          </span>
        </div>


        {
          business_name !==  "NON_REGISTERED_BUSINESS" && <div className="docs">
          <h5>Certificate of Incorporation </h5>
          <span
            className={`bs-plus ${certStatus}`}
            onClick={
              certStatus === "rejected" || certStatus === null || certStatus === ""
                ? () => dispatch(showCertModal())
                : () => {}
            }
          >
            <CompliceStatus status={certStatus} />
          </span>
        </div>
        }
      </div>
    </div>
  );
};
