import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import { axiosForm_DataInstance } from "../../utils/useAxios";

export const updatelogo = createAsyncThunk(
  "bussiness/updatelogo",
  async (payloaddata, thunkAPI) => {
    try {
     
      const { data } = await axiosForm_DataInstance.post(
        `/api/v1/update-business-logo/${payloaddata.businessID}/`,
        payloaddata.formData
      );

      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.toString();
      showerror(`Oops! ${message}`);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const updateBusinessLogoSlice = createSlice({
  name: "updatebussiness",
  initialState: {
    loading: false,
    message: "",
    data: {},
    error: null,
    status: "idle",
  },
  reducers: {
    resetState: (state) => {
      state.error = null;
      state.status = "idle";
    },
  },
  extraReducers: {
    [updatelogo.pending]: (state) => {
      state.loading = true;
      state.status = "loading";
    },
    [updatelogo.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
      state.status = "success";
    },
    [updatelogo.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.status = "failed";
    },
  },
});

export const getUpdateBusinessState = (state) => state.updatebussiness;
export const { resetState } = updateBusinessLogoSlice.actions;
export default updateBusinessLogoSlice.reducer;
