import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const webhookFilter = createAsyncThunk("webhookFilters/webhookFilter", async (payloaddata,thunkAPI) => {

  try {
    var url = `/api/v1/webhook-event/${payloaddata?.businessid}/?filter=${payloaddata?.filter}`
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});


const webhookFilterSlice = createSlice({
  name: "webhookFilters",
  initialState: {
    webhookfilterlist: null,
    filter:"",
    filterloading: false,
  },

  reducers: {
    resetFilter: (state) => {
      state.webhookfilterlist = null;
      state.filter= "";
      state.filterloading = false;
    },
    set_Filter: (state,action) => {
      state.filter= action.payload;
     
    },
  },

  extraReducers: {
    [webhookFilter.fulfilled]: (state, action) => {
      state.filterloading = false;
      state.webhookfilterlist = action.payload;
      
    },
    [webhookFilter.pending]: (state) => {
      state.filterloading = true;
    },
    [webhookFilter.rejected]: (state) => {
      state.filterloading = false;
    },
  },
});

export const webhookFilterState = (state) => state.webhookFilters;
export default webhookFilterSlice.reducer;
export const { resetFilter,set_Filter } = webhookFilterSlice.actions;


