import React from "react";
import { NavBar } from "./NavBar";
import { Sidebar } from "./Sidebar";

export const Base = ({ children, title, icon }) => {
  return (
  
    
    <div className="features">
      <div className = "Update">

<marquee className="UpdateMessage">In compliance with CBN regulations, we wish to notify you that Payvessel does not process payments for cryptocurrency trading platforms.
</marquee>


</div>
      <div className="container nav-feature">
      <div className="sidebar">
        <Sidebar />
        </div>
        {/* Dashboard */}
        <div className="main">
        <div className="feature-board">
          <NavBar title={title} icon={icon} />

          <section className="showcase">{children}</section>
        </div>
      </div>
    </div>
    </div>
  );
};


export const Baseless = ({ children, title, icon }) => {
  return (
  
    <div className="features">
      <div className="container nav-feature">
      <div className="sidebar">
        <Sidebar />
        </div>
        {/* Dashboard */}
        <div className="main">
        <div className="feature-board">
          <NavBar title={title} icon={icon} />

          <section>{children}</section>
        </div>
      </div>
    </div>
    </div>
  );
};
