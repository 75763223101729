import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const fetchvirtualaccount = createAsyncThunk("virtualaccount/fetchvirtualaccount", async (payloaddata,thunkAPI) => {
  try {
     
    var url = `/api/v1/reservedAccount/${payloaddata?.businessid}/`

    if (payloaddata?.page){
        url = `/api/v1/reservedAccount/${payloaddata?.businessid}/?page=${payloaddata?.page}`
    }
  
    const { data } = await axiosInstance.get(url);
      
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});


const virtualAccountSlice = createSlice({
  name: "virtualaccount",
  initialState: {
    virtualaccount: null,
    loading: false,
  },

  extraReducers: {
    [fetchvirtualaccount.fulfilled]: (state, action) => {
      state.loading = false;
      state.virtualaccount = action.payload;
      
    },
    [fetchvirtualaccount.pending]: (state) => {
      state.loading = true;
    },
    [fetchvirtualaccount.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const virtualaccountState = (state) => state.virtualaccount;
export default virtualAccountSlice.reducer;
