import React from 'react'
import { Base } from '../../components/Base'

export const Developer = () => {
  return (
   <Base>

   <>
   <form action className="dev-form">
        <p style={{textAlign: 'center', fontWeight: 500}}>API Configuration</p>
        <label htmlFor>Secret Key</label>
        <div className="dev-form-input-two">
          <input type="password" name="password" id defaultValue="••••••••••••••••••••••••••••••••••••" readOnly /><i className="fa-sharp fa-regular fa-eye" />
          <i className="fa-regular fa-eye-slash" />
        </div>
        <label htmlFor>Public Key</label>
        <div className="dev-form-input-two">
          <input type="text" name="pub-key" id defaultValue="pk_12345678abcdefghijklmnopqrst1234" readOnly /><i className="fa-sharp fa-regular fa-copy" />
        </div>
        <div className="dev-form-input">
          <label htmlFor>Redirect URL</label>
          <input type="url" name="url-redirect" id />
        </div>
        <div className="dev-form-input">
          <label htmlFor>Webhook URL</label>
          <input type="url" name="hook-url" id />
        </div>
        <input type="submit" defaultValue="Save Changes" className="btn btn-secondary" />
    </form>
   </>
   
   
   </Base>
  )
}
