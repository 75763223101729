export const accountTypeOptions = [
  {
    option: "REGISTERED_BUSINESS",
    name: "Your business is registered with CAC.",
    offers: [
      {
        id: 1,
        title: "Generate static virtual account to receive money from your customers",
      },
      {
        id: 2,
        title: "An account name that is same as your business name",
      },
      {
        id: 3,
        title: "Daily settlement to your bank account",
      },
      {
        id: 4,
        title: "Tools to track your revenue, expenses and customer loyalty",
      },
      {
        id: 5,
        title:
          "Tools for your staff to verify payments even when you are not present",
      },

      
     
    ],
  },
  {
    option: "NON_REGISTERED_BUSINESS",
    name: "You are not CAC registered but you need a dedicated account for your business",
    offers: [
      {
        id: 1,
        title:
          "Paperless setup of a dedicated personal account for your business operations",
      },
      {
        id: 2,
        title: "Daily settlement to your bank account",
      },
      {
        id: 3,
        title: "Tools to track your revenue, expenses and customer loyalty",
      },
      {
        id: 4,
        title:
          "Tools for your staff to verify payments even when you are not present",
      },
     
    ],
  },
  
];
