import { useQuery } from "react-query";
import axios from "axios";
import { useState } from "react";
import { decryptPayload, rootUrl } from "../../../utils/useAxios";

export const useStateLgaData = () => {
  const [stateFormat, setStateFormat] = useState([]);
  // Get all States and there LGA
  const fetchStateLgaList = async () => {
    const { data } = await axios.get(`${rootUrl}/api/v1/states/`);
    var  respdata = JSON.parse(decryptPayload(data.data))
    if (respdata) {
      const modifiedResponse = respdata.map((state) => {
        return {
          value: state.name,
          label: state.name,
        };
      });
      setStateFormat(modifiedResponse);
    }

    return respdata;
  };
  return [stateFormat, useQuery("statesLga", fetchStateLgaList)];
};
