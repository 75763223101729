import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const getBusiness = createAsyncThunk("user/getbusiness", async (businessID,thunkAPI) => {
  try {
     
    const { data } = await axiosInstance.get(`/api/v1/business/${businessID}/`);
    
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});

const getbusinessSlice = createSlice({
  name: "business",
  initialState: {
    businessdata: null,
    loading: false,
    
  },
 
  extraReducers: {
    [getBusiness.fulfilled]: (state, action) => {
      state.loading = false;
      state.businessdata = action.payload;
      
    },
    [getBusiness.pending]: (state) => {
      state.loading = true;
    },
    [getBusiness.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const getBusinessState = (state) => state.business;
export default getbusinessSlice.reducer;
