import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Base } from '../../components/Base'
import { LoadingBox } from '../../components/LoadingBox';
import { getUserState } from '../../redux/authentication/getUserSlice';
import { CustomersState, fetchCustomers } from '../../redux/customers/customersSlice';
import { querycustomers, querycustomersState, resetQuery, setQuery } from '../../redux/customers/querycustomersSlice';
import { Pagination } from './components/Paginator';
import { UserTable } from './components/UserTable';

export const CustomersScreen = () => {

    const dispatch = useDispatch();
    const { activeBusiness }  = useSelector(getUserState)
    const { customers,loading }  = useSelector(CustomersState)
    const { querycustomerslist,queryloading,query }  = useSelector(querycustomersState)
    
   
    useEffect(() => {
  
      dispatch(fetchCustomers({"businessid":activeBusiness?.businessid,"page":null}))
      
    }, [activeBusiness,dispatch]);



    const goto =(page)=>{
      dispatch(fetchCustomers({"businessid":activeBusiness?.businessid,"page":page}))
  }

  const search = (event)=>{
    const query = event.target.value
    dispatch(setQuery(query)) 
    
    if (query?.length > 0){
      dispatch(querycustomers({"businessid":activeBusiness?.businessid,"query":query}))
    }
    else{
      dispatch(resetQuery()) 
    }
  }


  return (
    <Base title={"Customers"} icon={"dashboard_"}>

    <>
    <div>
         <div className="transaction-table">
             
           <div className="card">
           {loading && <LoadingBox/>}
           {queryloading && <LoadingBox/>}


           <form action>
              <div className="p-relative">
            
              <input type="text" value={query} onChange={search} className="transac-search" placeholder="Search" />
              {query?.length > 0 &&  <span className="form-input-append">
                <i className= "fa fa-times" onClick={()=>dispatch(resetQuery()) }></i>
              </span>}
             
              </div>
            </form>

            {query?.length > 0 ? querycustomerslist?.results?.length > 0 ?
                <UserTable  customers={querycustomerslist} />: <div class="emtyTransactions">

                <img src={`../images/empty.png`} className="empty" alt="empty " />

                <p>Your query did not match any records.</p>

                </div>:
                <UserTable  customers={customers} />
               
              }
             
           </div>
         </div>
         {query?.length > 0 ? querycustomerslist?.results?.length > 0 ?
              <Pagination  customers={querycustomerslist}  goto={goto}/>:<></>:
              customers?.results?.length > 0 &&  <Pagination  customers={customers}  goto={goto}/>
               
              }
       </div>
    </>
    
 </Base>
  )
}
