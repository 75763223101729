import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { WebhookModal } from '../../../components/WebhookModal';
import { selectedWebhookData, webhookModal } from '../../../redux/kyc/generalSlice';
import { updateSelectedTransaction, webhookeventState } from '../../../redux/webhookEvents/webhookeventSlice';
import { formatDate } from '../../../utils/Dateformat';

export const TableComponets = ({webhookevents}) => {
  const showModal = useSelector((state) => state.general.showwebhookmodal);
  const { selectedtransaction }  = useSelector(webhookeventState)
  const data = useSelector((state) => state.general.webhookData);


  const dispatch = useDispatch();
  const handleCheckboxChange = (e,reference) => {

    if(e.target.checked){

      const updatedCheckboxes =  [...selectedtransaction,reference]
      dispatch(updateSelectedTransaction(updatedCheckboxes))

    }
    else{

      const updatedCheckboxes =  selectedtransaction.filter((x) => x !== reference)
      dispatch(updateSelectedTransaction(updatedCheckboxes))

    }
   
  };

  const handleCheckAllChange = (e) => {
    if(e.target.checked){
    const updatedCheckboxes = webhookevents?.results?.map((event) => event.reference);
    dispatch(updateSelectedTransaction(updatedCheckboxes))
    }
    else{
      dispatch(updateSelectedTransaction([]))

    }
  };

  const openTransModal = (data) => {
    dispatch(webhookModal(true));
    dispatch(selectedWebhookData(data));
  };





  return (

    <>
    {showModal && <WebhookModal />}
    <div className="transac-table">
               <table className="table">
                 <thead>
                   <tr>  
                   <th> <input type="checkbox"
          // checked={isCheckedAll}
          onChange={handleCheckAllChange}></input></th> 
                     <th>REFERENCE  </th>
                     <th>REMARKS</th>
                     <th>AMOUNT</th>
                     <th>ACCOUNT NUMBER</th>
                    
                     <th>STATUS</th>
                     <th>ATTEMPTS</th>
                     <th>REQUEST TIME</th>
                     <th>RESPONSE TIME</th>
                     <th>Actions</th>
                    
                   </tr>
                 </thead>
                 <tbody>
                     {webhookevents?.results?.map((event)=> <tr key={event.reference}>
                     <td> <input  type="checkbox"
              checked={selectedtransaction.find((checkbox) => checkbox === event.reference) != null ? true : false}
              onChange={(e) => handleCheckboxChange(e,event.reference)}></input></td>
                        <td>{event.reference}</td>
                        
                         <td>{event?.data?.order?.description}</td>
                         <td>₦{event?.data?.order?.amount}</td>
                         <td>{event?.data?.virtualAccount?.virtualAccountNumber}</td>
                        
                    
                         <td > <span className={event.status === "sent" ? "btn-success " : event.status === "failed" ? "btn-danger" :"btn-warn"}>{event.status}</span></td>
                    
                         <td>{event.attempts}</td>
                         <td>{formatDate(event.request_time)}</td>
                         <td>{formatDate(event.response_time)}</td>
                         <td><Link to="#"  onClick={() => openTransModal(event)} className="transaction_type trans-default"> View  </Link></td>
              

                         
                         
                   </tr>)}
                   </tbody>
               </table>
             </div>
             </>
  )
}
