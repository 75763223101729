import axios from 'axios'
import jwt_decode from "jwt-decode";
import dayjs from 'dayjs'
import CryptoJS from "crypto-js";

export const rootUrl = "https://api.payvessel.com" ;
export const sandboxURL = "https://sandbox.payvessel.com" ;


function getkeyString(str) {
  return str.split("").reverse().join("").replace("==eee", "");
}


const logoutAction = () => {
  localStorage.removeItem("payvessel-userInfo")
  localStorage.removeItem("payvessel-activeBusiness")
  window.location.pathname = "/signin";
};


export const encryptPayload = (data)=>{

    var key = "=E0SiBnLzAHPzYzZeee==";
    key = CryptoJS.enc.Utf8.parse(getkeyString(key));
    var iv = CryptoJS.enc.Utf8.parse('BBBBBBBBBBBBBBBB')
    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv, mode: CryptoJS.mode.CBC});
    encrypted = encrypted.toString();
    const encrypted_data = {data:encrypted}
    return encrypted_data
}

export const decryptPayload = (Base64CBC)=>{
    var key = "=E0SiBnLzAHPzYzZeee==";
    key = CryptoJS.enc.Utf8.parse(getkeyString(key));
    var iv = CryptoJS.enc.Utf8.parse('BBBBBBBBBBBBBBBB')
    var decrypted =  CryptoJS.AES.decrypt(Base64CBC, key, { iv: iv, mode: CryptoJS.mode.CBC});
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    return decrypted
}



const getAccessToken = () =>  localStorage.getItem('payvessel-userInfo') ? JSON.parse(localStorage.getItem('payvessel-userInfo')) : null

const token = getAccessToken();

const axiosInstance = axios.create({
        baseURL: rootUrl,
        headers:  { Accept: 'application/json',  Authorization:`Bearer ${token?.access }`}
    });

const axiosInstance2 = axios.create({
      baseURL: sandboxURL,
      headers:  { Accept: 'application/json',  Authorization:`Bearer ${token?.access }`}
  });



const axiosFormDataInstance = axios.create({
        baseURL: rootUrl,
        headers:  { 
            Accept: 'application/json',  
            Authorization:`Bearer ${token?.access }`,
            "Content-Type":"multipart/form-data"
    }


    });





axiosInstance.interceptors.request.use(async req => {
        const token = getAccessToken();

        if(req?.data)  req.data = encryptPayload(req.data)

        if(!token?.access)  return req

        const user = jwt_decode(token?.access)
        
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    
        if(!isExpired) { 
            req.headers.Authorization = `Bearer ${token?.access}`
            return req
        }
        const response = await axios.post(`${rootUrl}/api/v1/token/refresh/`, encryptPayload({
            refresh: token?.refresh
          }));

        

        const respdata = JSON.parse(decryptPayload(response.data.data))

        localStorage.setItem('payvessel-userInfo', JSON.stringify(respdata))
        
        req.headers.Authorization = `Bearer ${respdata.access}`
        // req.data = encrypted_data
       


        return req
    })



axiosInstance.interceptors.response.use(
        response => {
         
          if (response?.data) {
            
            if (response?.data?.data){
                response.data = JSON.parse(decryptPayload(response.data.data))
            }

          }
          return response;
        },
        error => {
        
          if (error.message.includes("Invalid token specified")){
            
            logoutAction()
            return Promise.reject(error);
          }

          

          if (error?.response?.status === 401){
            logoutAction()
            return Promise.reject(error);
          }


          if (error?.response) {

            if (error.response?.data?.data){
                error.response.data = JSON.parse(decryptPayload(error.response.data.data))
            }

            
          }
          return Promise.reject(error);
        }
      );


axiosFormDataInstance.interceptors.request.use(async req => {
    const token = getAccessToken();
    if(!token?.access)  return req

    const user = jwt_decode(token?.access)
    
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if(!isExpired) { 
        req.headers.Authorization = `Bearer ${token?.access}`
        return req
    }
    const response = await axios.post(`${rootUrl}/api/v1/token/refresh/`, encryptPayload({
      refresh: token?.refresh
    }));

  

  const respdata = JSON.parse(decryptPayload(response.data.data))

  localStorage.setItem('payvessel-userInfo', JSON.stringify(respdata))
  
  req.headers.Authorization = `Bearer ${respdata.access}`
    return req
})



axiosFormDataInstance.interceptors.response.use(
  response => {

    
   
    if (response?.data) {
      
      if (response?.data?.data){
          response.data = JSON.parse(decryptPayload(response.data.data))
      }

    }
    return response;
  },
  error => {
    // Handle error cases

    if (error.message.includes("Invalid token specified")){
      logoutAction()
      return Promise.reject(error);
    }

    if (error?.response?.status === 401){
      logoutAction()
      return Promise.reject(error);
    }


    if (error?.response) {

      if (error.response?.data?.data){
          error.response.data = JSON.parse(decryptPayload(error.response.data.data))
      }
      return Promise.reject(error);
    }

    return Promise.reject(error);
    
  }
);






axiosInstance2.interceptors.request.use(async req => {
  const token = getAccessToken();

  if(req?.data)  req.data = encryptPayload(req.data)

  if(!token?.access)  return req

  const user = jwt_decode(token?.access)
  
  const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;


  if(!isExpired) { 
      req.headers.Authorization = `Bearer ${token?.access}`
      return req
  }
  const response = await axios.post(`${rootUrl}/api/v1/token/refresh/`, encryptPayload({
      refresh: token?.refresh
    }));

  

  const respdata = JSON.parse(decryptPayload(response.data.data))

  localStorage.setItem('payvessel-userInfo', JSON.stringify(respdata))
  
  req.headers.Authorization = `Bearer ${respdata.access}`
  // req.data = encrypted_data
 


  return req
})



axiosInstance2.interceptors.response.use(
  response => {
   
    if (response?.data) {
      
      if (response?.data?.data){
          response.data = JSON.parse(decryptPayload(response.data.data))
      }

    }
    return response;
  },
  error => {
  
    if (error.message.includes("Invalid token specified")){
      
      logoutAction()
      return Promise.reject(error);
    }

    

    if (error?.response?.status === 401){
      logoutAction()
      return Promise.reject(error);
    }


    if (error?.response) {

      if (error.response?.data?.data){
          error.response.data = JSON.parse(decryptPayload(error.response.data.data))
      }

      
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
export const  axiosInstanceNEW = axiosInstance2;
export const axiosForm_DataInstance =  axiosFormDataInstance;
export const baseUrl =  rootUrl;
