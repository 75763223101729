import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import showsuccess from "../../components/SuccessToast";
import axiosInstance from "../../utils/useAxios";

export const validateAccountNumber = createAsyncThunk("bank_account/validate", async (postdata,thunkAPI) => {
  try {
    const { data } = await axiosInstance.get(`/api/v1/validate-account-number/?bankcode=${postdata.bankcode}&accountnumber=${postdata.accountnumber}`);
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);

    return thunkAPI.rejectWithValue(errorMsg);
  }
});


export const fetchPayoutAccount = createAsyncThunk("bank_account/fetch", async (businessID,thunkAPI) => {
  try {
    const { data } = await axiosInstance.get(`/api/v1/business-payout-account/${businessID}/`);
    
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);

    return thunkAPI.rejectWithValue(errorMsg);
  }
});


export const updatePayoutAccount = createAsyncThunk("bank_account/update", async (payloaddata,thunkAPI) => {
  try {
     
    const { data } = await axiosInstance.post(`/api/v1/business-payout-account/${payloaddata.businessID}/`,payloaddata.data);
    showsuccess(`${data.message}`);
   
    return data;

  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});



const bankSlice = createSlice({
  name: "bank_account",
  initialState: {
    accountName: "",
    bankCode: "",
    accountNumber:"",
    bankName:"",
    otp:"",
    loading: false,
    success:false,
    hasAccount:false
  },

  reducers: {
    resetState: (state) => {
      state.loading = false;
      state.success = false;
    },
    updateBank: (state,action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
  extraReducers: {
    [validateAccountNumber.fulfilled]: (state, action) => {
        state.loading = false;
        state.accountName = action.payload.accountName;
        state.bankCode = action.payload.bankCode;
        state.accountNumber = action.payload.accountNumber;
      },
      [validateAccountNumber.pending]: (state) => {
        state.loading = true;
      },
      [validateAccountNumber.rejected]: (state) => {
        state.loading = false;
        state.accountName = "";
      },


      [fetchPayoutAccount.fulfilled]: (state, action) => {
        state.loading = false;
        state.accountName = action.payload.accountName;
        state.bankCode = action.payload.bankcode;
        state.bankName = action.payload.bankname;
        state.accountNumber = action.payload.accountNumber;
        state.hasAccount = true
        
      },
      [fetchPayoutAccount.pending]: (state) => {
        state.loading = true;
      },
      [fetchPayoutAccount.rejected]: (state) => {
        state.loading = false;
        state.accountName = "";
      },

      [updatePayoutAccount.fulfilled]: (state, action) => {
       
        state.loading = false;
        state.accountName = action.payload.data.accountName;
        state.bankCode = action.payload.data.bankcode;
        state.bankName = action.payload.data.bankname;
        state.accountNumber = action.payload.data.accountNumber;
        state.success = true;
        state.hasAccount = true
        state.otp = ""
       
      },
      [updatePayoutAccount.pending]: (state) => {
        state.loading = true;
      },
      [updatePayoutAccount.rejected]: (state) => {
        state.loading = false;
        state.otp = ""
        
      },
  },
});

export const BankAccountState = (state) => state.bank_account;
export default bankSlice.reducer;
export const { updateBank,resetState } = bankSlice.actions;