/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getLoginState } from "../redux/authentication/userLoginSlice";


const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];


function LoginRequired({ children }) {

   
    let timer;
    const handleLogoutTimer = () => {
        
        timer = setTimeout(() => {
          // clears any pending timer.
          resetTimer();
          // Listener clean up. Removes the existing event listener from the window
          Object.values(events).forEach((item) => {
            window.removeEventListener(item, resetTimer);
          });
          // logs out user
          logoutAction();
        }, 500000); // 10000ms = 10secs. You can change the time.
      };


      const resetTimer = () => {
        if (timer) clearTimeout(timer);
      };

      useEffect(() => {
        Object.values(events).forEach((item) => {
          window.addEventListener(item, () => {
            resetTimer();
            handleLogoutTimer();
          });
        });
      }, []);
      
      // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
      const logoutAction = () => {
        localStorage.removeItem("payvessel-userInfo")
        localStorage.removeItem("payvessel-activeBusiness")
        window.location.pathname = "/signin";
      };

    const { userInfo }  = useSelector(getLoginState)
    const location = useLocation();

    
    return userInfo?.access
        ?  children
        : <Navigate to="/signin"
            replace
            state={{ path: location.pathname }} />;
}
export default LoginRequired



