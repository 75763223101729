import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import showerror from "../../components/ErrorToast";
import { updateEmail, passwordResetState, resetPasswordOtp, resetPasswordsubmit, resetState } from "../../redux/authentication/passwordResetSlice";
import * as Yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";


export const ResetPassword = () => {
    const { loading,success,step,email} = useSelector(passwordResetState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPass, setShowPass] = React.useState(false);
  const [showConfirmPass, setShowConfirmPass] = React.useState(false);


    const validationSchema = Yup.object({
     
      
      otp: Yup.number()
        .typeError("Invalid OTP")
        .required("OTP is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Your password should contain uppercase, special character & number"
        ),
      confirmpassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Password does not match"
      ),
    });



    const {
      handleSubmit,
      register,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
     
    });

    useEffect(() => {
     if(success){
       dispatch(resetState())
      navigate("/");

     }
    }, [dispatch, navigate, success])
    

    const submitHandler = (data) => {

      if (!data.otp) {
        showerror("OTP is required");
        return;
      }

      else if (!data.password) {
        showerror("New password  is required");
        return;
      }

      else if (!data.confirmpassword) {
        showerror("Comfirm New password  is required");
        return;
      }

      else if (data.password !== data.confirmpassword) {
        showerror("Password  not match");
        return;
      }



        const payloaddata = {
            user_agent:navigator.userAgent,
            service: "Password Reset",
            email:email,
            otp:data.otp,
            password1:data.password,
            password2:data.confirmpassword

        }
      dispatch(resetPasswordsubmit(payloaddata))
      };

      const getOtphandler = ()=>{
        const payloaddata = {
            user_agent:navigator.userAgent,
            service: "Password Reset",
            email:email
        }
          dispatch(resetPasswordOtp(payloaddata))
    
     
    
    }


    const handlechange = (e) => {
      const { name, value } = e.target;
      dispatch(updateEmail({ name, value }));
  
    }

  return (
    <div className="form-container">
    <img
      src="/images/logo-white.png"
      alt=""
      className="form-container-logo"
    />

    <section className="form-card account">
    <form className="create-act" onSubmit={handleSubmit(submitHandler)}>
        { step === 1 ?
<>
<h5>Forgot Your Account</h5>
<p></p>
<div className="act-form">
  <label className="form-input-label ">Email Address</label>
  <input
    type="email"
    name="email"
    value={email}
    className= "form-input"
    onChange={handlechange}
    
  />
 
</div> 
<button
          type="button"
          onClick={()=>getOtphandler()}
          className="btn btn-primary"
          disabled={loading ? true : false}
        >
          {loading ? (
            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
          ) : (
            "Proceed"
          )}
        </button>
</>:<><h5>Set New Password</h5>
<p></p>

<div className="act-form phoneinput__container">

<label className="form-input-label ">New Password</label>
            <div className="innerPasswordInputcontainer">
              {!showPass ? (
                <AiOutlineEyeInvisible
                  size={22}
                  onClick={() => setShowPass(!showPass)}
                  className={`password__eye ${
                    errors.password && "password__eye-error"
                  }`}
                />
              ) : (
                <AiOutlineEye
                  size={22}
                  onClick={() => setShowPass(!showPass)}
                  className={`password__eye ${
                    errors.password && "password__eye-error"
                  }`}
                />
              )}

              <input
                placeholder="Password"
                type={showPass ? "text" : "password"}
                className={
                  errors.password
                    ? "form-input form-invalid-input"
                    : "form-input"
                }
                {...register("password")}
              />
            </div>
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>
          <div className="act-form phoneinput__container">
          <label className="form-input-label ">Comfirm New Password</label>
            <div className="innerPasswordInputcontainer">
              {!showConfirmPass ? (
                <AiOutlineEyeInvisible
                  size={22}
                  onClick={() => setShowConfirmPass(!showConfirmPass)}
                  className={`password__eye ${
                    errors.confirmpassword && "password__eye-error"
                  }`}
                />
              ) : (
                <AiOutlineEye
                  size={22}
                  onClick={() => setShowConfirmPass(!showConfirmPass)}
                  className={`password__eye ${
                    errors.confirmpassword && "password__eye-error"
                  }`}
                />
              )}


              <input
                placeholder="Confirm Password"
                type={showConfirmPass ? "text" : "password"}
                className={
                  errors.confirmpassword
                    ? "form-input form-invalid-input"
                    : "form-input"
                }
                {...register("confirmpassword")}
              />
            </div>
            <ErrorMessage
              errors={errors}
              name="confirmpassword"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>

          <div className="act-form phoneinput__container">
           

<label className="form-input-label ">OTP Code</label>
              <input
                placeholder="OTP Code"
                type="number"
                
                className={
                  errors.otp
                    ? "form-input form-invalid-input"
                    : "form-input"
                }
                {...register("otp")}
              />
           
            <ErrorMessage
              errors={errors}
              name="otp"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>


          <span className="prm-color">
            <Link to="#" onClick={getOtphandler}>Resend OTP</Link>
            
          </span>  
<button
          type="submit"
          className="btn btn-primary"
          disabled={loading ? true : false}
        >
          {loading ? (
            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
          ) : (
            "Change Password"
          )}
        </button>

</> 
        }
        
        <p>
         Go back to{" "}
          <span className="prm-color">
            <Link to="/">Login Page</Link>
            
          </span>
        </p>
      </form>
    </section>
    <p
      style={{
        marginTop: "auto",
        color: "white",
      }}
    >
      © payvessel {new Date().getFullYear()}
    </p>
  </div>
  )
}
