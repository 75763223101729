import React from 'react'
import { Link } from 'react-router-dom'
import { Baseless } from '../../components/Base'

export const SupportScreen = () => {

  
  // const numbers = [];

  // const selectRandomSupportNumber = () => {
  //   const randomIndex = Math.floor(Math.random() * numbers.length);
  //   const selectedString = numbers[randomIndex];
  //   window.location.href = `https://wa.me/${selectedString}`;
  // };


return (
<Baseless title={""} icon={"dashboard_"}>

   <>
  

     <div className="support-bg-container">
     
     
     <h2>Get In Touch</h2>
     <p>We are here to help you get started with Payvessel.</p>
<p>If you have any questions, feel free to start a live chat conversation or contact us.</p>  

     </div>


     <div className="support-option">

     <div className="support-card-div">

       <div className="support-single">

        

         <div className="content">
         <div className="icon">
           <i className="fa fa-add"></i>
         </div>
           <h2> JOIN PAYVESSEL FAMILY</h2>
           <p>Get latest information on payvessel whatsapp channel</p>
           
           </div>


           <div className="button">

             <Link to="https://whatsapp.com/channel/0029Va9FcRzElagxeiWsWR3c" className="button-link">Join Now</Link>
           
           </div>

       </div>

       <div className="support-single">



<div className="content">
<div className="icon">
  <i className="far fa-comment"></i>
</div>
  <h2> GET IN TOUCH</h2>
  <p>please reach out to our support team</p>

  </div>


  <div className="button">
    <Link to="mailto:support@payvessel.com"  className="button-link">Send Email</Link>
  
  </div>

</div>

{/* <div className="support-single">



<div className="content">
  
<div className="icon">
  <i className="fa fa-envelope"></i>
</div>
  <h2> SUGGEST AN IMPROVEMENT</h2>
  <p>If you spot something that can be better, let us know</p>
  <p>support@payvessel.com</p>
  
  </div>

  <div className="button">
    <Link to="mailto:support@payvessel.com" className="button-link">Send Mail</Link>
  
  </div>

</div> */}

</div>

     </div>
      
      
      
      
        {/* <div className="get-user">
          <h3>Welcome, Macy 😎</h3>
          <p>We are here to help you get started with payvessel<br/>start a live chat conversation if you have any questions or contact us.</p>
        
        </div>
        <div className="card unlock flex" style={{backgroundColor: 'pink', margin: '25px 0px'}}>
          <p>Complete your identification process to unlock your full  potentials</p>
          <button className="btn btn-prm" style={{backgroundColor: 'red'}}>Verify Account</button>
        </div>
        <div className="card unlock flex" style={{backgroundColor: 'rgb(226, 143, 226)', margin: '25px 0px'}}>
          <p>Hey Macy! Finish Setting up your Account</p>
          <button className="btn btn-prm" style={{backgroundColor: 'purple'}}>Setup Account</button>
        </div>
        <div className="card virtual-act">
          
          <h2>Virtual Account</h2>
          <p>Open a virtual account for your business.</p>
        </div> */}
     
   </>
   
</Baseless>
)
}
