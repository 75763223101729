export function formatDate(date) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const d = new Date(date);
    let month = months[d.getMonth()];
    let hh = d.getHours();
    let m = d.getMinutes();
    let s = d.getSeconds();
    let day = d.getDate();
    let year = d.getUTCFullYear();
    var dd = "AM";
    var h = hh;
    if (h >= 12) {
        h = hh - 12;
        dd = "PM";
    }
    let da = `${month}.  ${day}, ${year}, ${h}:${m}:${s} ${dd}`

    return da


}



export function formatDateNew(dateTime) {
    let day = dateTime.getDate();
    let month = dateTime.getMonth() + 1; // Month indexes start from 0
    let year = dateTime.getFullYear();
    let hours = dateTime.getHours();
    let minutes = dateTime.getMinutes();
    let seconds = dateTime.getSeconds();
  
    // Add leading zeros if necessary
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
  
    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
  }


  export function formatDateAndTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);
  
    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Month indexes start from 0
    const year = dateTime.getFullYear();
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const seconds = dateTime.getSeconds();
  
    // Add leading zeros if necessary
    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedYear = year;
    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;
  
    return formattedYear + "-" + formattedMonth + "-" + formattedDay + " " + formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
  }