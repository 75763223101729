export const bussiness_industry = [
    { value: "Accountants", label: "Accountants" },
    { value: "Advertising agencies", label: "Advertising agencies" },
    {
      value: "Advertising/Public relations",
      label: "Advertising/Public relations",
    },
    {
      value: "Aerospace defense contractors",
      label: "Aerospace defense contractors",
    },
    { value: "Agency banking", label: "Agency banking" },
    { value: "Agribusiness", label: "Agribusiness" },
    {
      value: "Agricultural services & products",
      label: "Agricultural services & products",
    },
    { value: "Agriculture", label: "Agriculture" },
    { value: "Air transport", label: "Air transport" },
    { value: "Air transport unions", label: "Air transport unions" },
    { value: "Airlines", label: "Airlines" },
    { value: "Alcoholic Beverages", label: "Alcoholic Beverages" },
    {
      value: "Alternative Energy Production & Services",
      label: "Alternative Energy Production & Services",
    },
    { value: "Apparel retailers", label: "Apparel retailers" },
    { value: "Architectural Services", label: "Architectural Services" },
    { value: "Attorneys/Law Firms", label: "Attorneys/Law Firms" },
    { value: "Auto Dealers", label: "Auto Dealers" },
    { value: "Auto Dealers Japanese", label: "Auto Dealers Japanese" },
    { value: "Auto Manufacturers", label: "Auto Manufacturers" },
    {
      value: "Automobile/Auto Parts Retailers",
      label: "Automobile/Auto Parts Retailers",
    },
    { value: "Automotive", label: "Automotive" },
    { value: "Banking", label: "Banking" },
    { value: "Bars & Restaurants", label: "Bars & Restaurants" },
    { value: "Beer wine & Liquor", label: "Beer wine & Liquor" },
    {
      value: "Beverages--Brewers/Distillers",
      label: "Beverages--Brewers/Distillers",
    },
    { value: "Beverages--Non-Alcoholic", label: "Beverages--Non-Alcoholic" },
    {
      value: "Books Magazines & Newspapers",
      label: "Books Magazines & Newspapers",
    },
    { value: "Broadcasters Radio/TV", label: "Broadcasters Radio/TV" },
    { value: "Brokers and Services", label: "Brokers and Services" },
    {
      value: "Builders/General Contractors",
      label: "Builders/General Contractors",
    },
    { value: "Builders/Residential", label: "Builders/Residential" },
    { value: "Building constructions", label: "Building constructions" },
    { value: "Building Materials", label: "Building Materials" },
    {
      value: "Building materials & Equipment",
      label: "Building materials & Equipment",
    },
    {
      value: "Building Structure/Completion",
      label: "Building Structure/Completion",
    },
    { value: "Building trade unions", label: "Building trade unions" },
    { value: "Business associations", label: "Business associations" },
    { value: "Business services", label: "Business services" },
    {
      value: "Cable & satellite TV production & distribution",
      label: "Cable & satellite TV production & distribution",
    },
    { value: "Candidates committees", label: "Candidates committees" },
    {
      value: "Candidates committee Democratic",
      label: "Candidates committee Democratic",
    },
    {
      value: "Candidates committees republican",
      label: "Candidates committees republican",
    },
    { value: "Car dealers", label: "Car dealers" },
    { value: "Car dealers imports", label: "Car dealers imports" },
    { value: "Car manufacturers", label: "Car manufacturers" },
    { value: "Cash point business", label: "Cash point business" },
    { value: "Casinos/Gambling", label: "Casinos/Gambling" },
    { value: "Cattle Ranchers/Livestock", label: "Cattle Ranchers/Livestock" },
    {
      value: "Chemical & Related Manufacturing",
      label: "Chemical & Related Manufacturing",
    },
    { value: "Chemicals", label: "Chemicals" },
    { value: "Chiropractors", label: "Chiropractors" },
    {
      value: "Civil Servants/Public Officials",
      label: "Civil Servants/Public Officials",
    },
    { value: "Coal Extraction", label: "Coal Extraction" },
    { value: "Coal Mining", label: "Coal Mining" },
    {
      value: "Coal and Coal Products Distributors",
      label: "Coal and Coal Products Distributors",
    },
    {
      value: "Colleges Universities & Schools",
      label: "Colleges Universities & Schools",
    },
    {
      value: "Commercial TV & Radio Stations",
      label: "Commercial TV & Radio Stations",
    },
    {
      value: "Communications/Electronics",
      label: "Communications/Electronics",
    },
    { value: "Computer Based Systems", label: "Computer Based Systems" },
    { value: "Computer Software", label: "Computer Software" },
    { value: "Computers and Peripherals", label: "Computers and Peripherals" },
    { value: "Conservative/Republican", label: "Conservative/Republican" },
    { value: "Construction", label: "Construction" },
    { value: "Construction Services", label: "Construction Services" },
    { value: "Construction Unions", label: "Airlines" },
    { value: "Consumer Electronics", label: "Consumer Electronics" },
    { value: "Cosmetics", label: "Cosmetics" },
    { value: "Courier/Freight/Delivery", label: "Courier/Freight/Delivery" },
    { value: "Credit Unions", label: "Credit Unions" },
    { value: "Crop Production", label: "Crop Production" },
    {
      value: "Crop Production & Basic Processing",
      label: "Crop Production & Basic Processing",
    },
    {
      value: "Crude Oil and Natural Gas Extraction",
      label: "Crude Oil and Natural Gas Extraction",
    },
    { value: "Cruise Lines", label: "Cruise Lines" },
    { value: "Cruise Ships & Lines", label: "Cruise Ships & Lines" },
    { value: "Dairy", label: "Dairy" },
    { value: "Defense", label: "Defense" },
    { value: "Defense Aerospace", label: "Defense Aerospace" },
    { value: "Defense Electronics", label: "Defense Electronics" },
    {
      value: "Defense/Foreign Policy Advocates",
      label: "Defense/Foreign Policy Advocates",
    },
    {
      value: "Democratic Candidate Committees",
      label: "Democratic Candidate Committees",
    },
    { value: "Democratic Leadership", label: "Democratic Leadership" },
    { value: "Democratic/Liberal", label: "Democratic/Liberal" },
    { value: "Dentists", label: "Dentists" },
    {
      value: "Diversified Communication Services",
      label: "Diversified Communication Services",
    },
    {
      value: "Doctors & Other Health Professionals",
      label: "Doctors & Other Health Professionals",
    },
    { value: "Drug Manufacturers", label: "Drug Manufacturers" },
    { value: "Education", label: "Education" },
    { value: "Education/Training", label: "Education/Training" },
    {
      value: "Electric Power Distribution",
      label: "Electric Power Distribution",
    },
    { value: "Electric Power Generation", label: "Electric Power Generation" },
    {
      value: "Electric Power Transmission",
      label: "Electric Power Transmission",
    },
    { value: "Electric Utilities", label: "Electric Utilities" },
    {
      value: "Electronic Office Equipment",
      label: "Electronic Office Equipment",
    },
    {
      value: "Electronic and Electrical Products",
      label: "Electronic and Electrical Products",
    },
    {
      value: "Electronics Defense Contractors",
      label: "Electronics Defense Contractors",
    },
    {
      value: "Electronics Manufacturing & Equipment",
      label: "Electronics Manufacturing & Equipment",
    },
    {
      value: "Electronics/Appliances Retailers",
      label: "Electronics/Appliances Retailers",
    },
    { value: "Employment Solutions", label: "Employment Solutions" },
    {
      value: "Energy & Natural Resources",
      label: "Energy & Natural Resources",
    },
    {
      value: ">Energy Equipment and Services",
      label: ">Energy Equipment and Services",
    },
    { value: "Entertainment Industry", label: "Entertainment Industry" },
    { value: "Environment", label: "Environment" },
    { value: "Farm Bureaus", label: "Farm Bureaus" },
    { value: "Farming", label: "Airlines" },
    { value: "Fashion/Clothing", label: "Fashion/Clothing" },
    { value: "Field Services", label: "Field Services" },
    {
      value: "Finance / Credit Companies",
      label: "Finance / Credit Companies",
    },
    {
      value: "Finance Insurance & Real Estate",
      label: "Finance Insurance & Real Estate",
    },
    { value: "Fishing/Hunting/Trapping", label: "Fishing/Hunting/Trapping" },
    { value: "Food & Beverage", label: "Food & Beverage" },
    { value: "Food Processing & Sales", label: "Food Processing & Sales" },
    { value: "Food Products", label: "Food Products" },
    {
      value: "Food Products Manufacturing",
      label: "Food Products Manufacturing",
    },
    {
      value: "Food Products--Diversified",
      label: "Food Products--Diversified",
    },
    { value: "Food Stores", label: "Food Stores" },
    {
      value: "Food/Drug Retailers and Wholesalers",
      label: "Food/Drug Retailers and Wholesalers",
    },
    { value: "For-profit Education", label: "For-profit Education" },
    { value: "For-profit Prisons", label: "For-profit Prisons" },
    { value: "Foreign & Defense Policy", label: "Foreign & Defense Policy" },
    {
      value: "Forestry & Forest Products",
      label: "Forestry & Forest Products",
    },
    { value: "Foundations Philanthropists & Non-Profits", label: "Foundations Philanthropists & Non-Profits" },
    { value: "Funeral Services", label: "Funeral Services" },
    { value: "Gambling & Casinos", label: "Gambling & Casinos" },
    { value: "Gambling Indian Casinos", label: "Gambling Indian Casinos" },
    { value: "Garbage Collection/Waste Management", label: "Garbage Collection/Waste Management" },
    { value: "Gas & Oil", label: "Gas & Oil" },
    { value: "General Contractors", label: "General Contractors" },
    { value: "Government Employee Unions", label: "Government Employee Unions" },
    { value: "Government Employees", label: "Government Employees" },
    { value: "Gun Control", label: "Gun Control" },
    { value: "Gun Rights", label: "Gun Rights" },
    { value: "HMOs & Health Care Services", label: "HMOs & Health Care Services" },
    { value: "Health", label: "Health" },
    { value: "Health Professionals", label: "Health Professionals" },
    { value: "Healthcare Providers", label: "Healthcare Providers" },
    { value: "Hedge Funds", label: "Hedge Funds" },
    { value: "Home Builders", label: "Home Builders" },
    { value: "Hospitality", label: "Hospitality" },
    { value: "Hospitals & Nursing Homes", label: "Hospitals & Nursing Homes" },
    { value: "Hotels Motels & Tourism", label: "Hotels Motels & Tourism" },
    { value: "Hotels/Lodging", label: "Hotels/Lodging" },
    { value: "Household Durables", label: "Household Durables" },
    { value: "Human Rights", label: "Human Rights" },
    { value: "Human Resources & Recruitment", label: "Human Resources & Recruitment" },
    { value: "IT Services", label: "IT Services" },
    { value: "Ideological/Single-Issue", label: "Ideological/Single-Issue" },
    { value: "Industrial Unions", label: "Industrial Unions" },
    { value: "Insurance", label: "Insurance" },
    { value: "Insurance Carriers", label: "Insurance Carriers" },
    { value: "Integrated Oil and Gas Services", label: "Integrated Oil and Gas Services" },
    { value: "Internet", label: "Internet" },
    { value: "Internet Service Providers", label: "Internet Service Providers" },
    { value: "Labor", label: "Labor" },
    { value: "Lawyers & Lobbyists", label: "Lawyers & Lobbyists" },
    { value: "Lawyers / Law Firms", label: "Lawyers / Law Firms" },
    { value: "Leadership PACs", label: "Leadership PACs" },
    { value: "Liquor Wine & Beer", label: "Liquor Wine & Beer" },
    { value: "Livestock", label: "Livestock" },
    { value: "Livestock/Animal Specialties", label: "Livestock/Animal Specialties" },
    { value: "Lobbyists", label: "Lobbyists" },
    { value: "Lodging / Tourism", label: "Lodging / Tourism" },
    { value: "Logistics", label: "Logistics" },
    { value: "Logging Timber & Paper Mills", label: "Logging Timber & Paper Mills" },
    { value: ">Manufacturing Misc", label: ">Manufacturing Misc" },
    { value: "Marine Transport", label: "Marine Transport" },
    { value: "Meat Processing & Products", label: "Meat Processing & Products" },
    { value: "Media/Entertainment", label: "Media/Entertainment" },
    { value: "Medical Supplies", label: "Medical Supplies" },
    { value: "Metals", label: "Metals" },
    { value: "Mining", label: "Mining" },
    { value: "Miscellaneous Business", label: "Airlines" },
    { value: ">Miscellaneous Defense", label: ">Miscellaneous Defense" },
    { value: "Miscellaneous Finance", label: "Miscellaneous Finance" },
    { value: "Miscellaneous Manufacturing & Distributing", label: "Miscellaneous Manufacturing & Distributing" },
    { value: "Miscellaneous Services", label: "Mining Services" },

    { value: "Miscellaneous Unions", label: "Miscellaneous Unions" },
    { value: "Mortgage Carriers", label: "Mortgage Carriers" },
    { value: "Motion Picture Production & Distribution", label: "Motion Picture Production & Distribution" },
    { value: "Music Production", label: "Music Production" },
    { value: "Natural Gas Pipelines", label: "Natural Gas Pipelines" },
    { value: "Newspaper Magazine & Book Publishing", label: "Newspaper Magazine & Book Publishing" },
    { value: "Non-Building/Heavy Construction", label: "Non-Building/Heavy Construction" },
    { value: "Non-Depository Credit Institutions", label: "Non-Depository Credit Institutions" },
    { value: "Non-Metallic Mineral Mining", label: "Non-Metallic Mineral Mining" },
    { value: "Non-profits Foundations & Philanthropists", label: "Non-profits Foundations & Philanthropists" },
    { value: "Nurses", label: "Nurses" },
    { value: "Nursing Homes/Hospitals", label: "Nursing Homes/Hospitals" },
    { value: "Nutritional & Dietary Supplements", label: "Nutritional & Dietary Supplements" },
    { value: "Oil & Gas", label: "Oil & Gas" },
    { value: "Other Financial Institutions", label: "Other Financial Institutions" },
    { value: "Other ICT Products and Services", label: "Other ICT Products and Services" },
    { value: "Other Services", label: "Other Services" },
    { value: "Packaging/Containers", label: "Packaging/Containers" },
    { value: "Paper/Forest Products", label: "Paper/Forest Products" },
    { value: "PEDPAN", label: "PEDPAN" },
    { value: "Personal/Household Products", label: "Personal/Household Products" },
    { value: "Petroleum Bulk Stations and Terminals", label: "Petroleum Bulk Stations and Terminals" },
    { value: "Petroleum Refining", label: "Petroleum Refining" },
    { value: "Petroleum and Petroleum Products Distributors", label: "Petroleum and Petroleum Products Distributors" },
    { value: "Pharmaceutical Manufacturing", label: "Pharmaceutical Manufacturing" },
    { value: "Pharmaceuticals", label: "Pharmaceuticals" },
    { value: "Pharmaceuticals / Health Products", label: "Pharmaceuticals / Health Products" },
    { value: "Physicians & Other Health Professionals", label: "Physicians & Other Health Professionals" },
    { value: "Postal Unions", label: "Postal Unions" },
    { value: "Poultry & Eggs", label: "Poultry & Eggs" },
    { value: "Power Utilities", label: "Power Utilities" },
    { value: "Precious Metals", label: "Precious Metals" },
    { value: "Precious Stones", label: "Precious Stones" },
    { value: "Printing & Publishing", label: "Printing & Publishing" },
    { value: "Private Equity & Investment Firms", label: "Private Equity & Investment Firms" },
    { value: "Pro-Israel", label: "Pro-Israel" },
    { value: "Processing Systems", label: "Processing Systems" },
    { value: "Professional Sports Sports Arenas & Related Equipment & Services", label: "Professional Sports Sports Arenas & Related Equipment & Services" },
    { value: "Progressive/Democratic", label: "Progressive/Democratic" },
    { value: "Property Management", label: "Property Management" },
    { value: "Public Employees", label: "Public Employees" },
    { value: "Public Sector Unions", label: "Public Sector Unions" },
    { value: "Publishing & Printing", label: "Publishing & Printing" },
    { value: "Radio/TV Stations", label: "Radio/TV Stations" },
    { value: "Rail Transportation", label: "Rail Transportation" },
    { value: "Railroads", label: "Railroads" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Real Estate Development", label: "Real Estate Development" },
    { value: "Real Estate Investment Trusts(REITs)", label: "Real Estate Investment Trusts(REITs)" },
    { value: "Record Companies/Singers", label: "Record Companies/Singers" },
    { value: "Recorded Music & Music Production", label: "Recorded Music & Music Production" },
    { value: "Recreation / Live Entertainment", label: "Recreation / Live Entertainment" },
    { value: "Religious Organizations/Clergy", label: "Religious Organizations/Clergy" },
    { value: "Repair/Maintenance", label: "Repair/Maintenance" },
    { value: "Republican Candidate Committees", label: "Republican Candidate Committees" },
    { value: "Republican Leadership PACs", label: "Republican Leadership PACs" },
    { value: "Republican/Conservative", label: "Republican/Conservative" },
    { value: "Residential Construction", label: "Residential Construction" },
    { value: "Restaurants & Drinking Establishments", label: "Restaurants & Drinking Establishments" },
    { value: ">Retail Sales", label: ">Retail Sales" },
    { value: "Retired", label: "Retired" },
    { value: "Road Transportation", label: "Road Transportation" },
    { value: "Savings & Loans", label: "Savings & Loans" },
    { value: "Schools/Education", label: "Schools/Education" },
    { value: "Scientific and Technical Instruments", label: "Scientific and Technical Instruments" },
    { value: "Sea Transport", label: "Sea Transport" },
    { value: "Securities & Investment", label: "Securities & Investment" },
    { value: "Semiconductors", label: "Semiconductors" },
    { value: "Site Preparation Services", label: "Site Preparation Services" },
    { value: "Special Trade Contractors", label: "Special Trade Contractors" },
    { value: "Specialty Retailers", label: "Specialty Retailers" },
    { value: "Sports Professional", label: "Sports Professional" },
    { value: "Steel Production", label: "Steel Production" },
    { value: "Stock Brokers/Investment Industry", label: "Stock Brokers/Investment Industry" },
    { value: "Storage/Warehousing", label: "Storage/Warehousing" },
    { value: "Student Loan Companies", label: "Student Loan Companies" },
    { value: "Sugar Cane & Sugar Beets", label: "Sugar Cane & Sugar Beets" },
    { value: "TV / Movies / Music", label: "TV / Movies / Music" },
    { value: "TV Production", label: "TV Production" },
    { value: "Teachers Unions", label: "Teachers Unions" },
    { value: "Teachers/Education", label: "Teachers/Education" },
    { value: "Telecom Services & Equipment", label: "Telecom Services & Equipment" },
    { value: "Telecommunications Carriers", label: "Telecommunications Carriers" },
    { value: "Telecommunications Equipment", label: "Telecommunications Equipment" },
    { value: "Telecommunications Services", label: "Telecommunications Services" },
    { value: "Telephone Utilities", label: "Telephone Utilities" },
    { value: ">Textiles", label: ">Textiles" },
    { value: "Textiles/Apparel", label: "Textiles/Apparel" },
    { value: "Timber Logging & Paper Mills", label: "Timber Logging & Paper Mills" },
    { value: "Tobacco", label: "Tobacco" },
    { value: "Tobacco Products", label: "Tobacco Products" },
    { value: "Tools and Machinery", label: "Tools and Machinery" },
    { value: "Toys and Games", label: "Toys and Games" },
    { value: "Transport-Related Services", label: "Transport-Related Services" },
    { value: "Transportation", label: "Transportation" },
    { value: "Transportation Unions", label: "Transportation Unions" },
    { value: "Trash Collection/Waste Management", label: "Trash Collection/Waste Management" },
    { value: "Travel and Tourism", label: "Travel and Tourism" },
    { value: "Trucking", label: "Trucking" },
    { value: "Unions", label: "Unions" },
    { value: "Unions Airline", label: "Unions Airline" },
    { value: "Unions Building Trades", label: "Unions Building Trades" },
    { value: "Unions Industrial", label: "Unions Industrial" },
    { value: "Unions Misc", label: "Unions Misc" },
    { value: "Unions Public Sector", label: "Unions Public Sector" },
    { value: "Unions Teacher", label: "Unions Teacher" },
    { value: "Unions Transportation", label: "Unions Transportation" },
    { value: "Universities Colleges & Schools", label: "Universities Colleges & Schools" },
    { value: "Vegetables & Fruits", label: "Vegetables & Fruits" },
    { value: "Venture Capital", label: "Venture Capital" },
    { value: "Waste Management", label: "Waste Management" },
    { value: "Water Transportation", label: "Water Transportation" },
    { value: "Water Treatment and Distribution", label: "Water Treatment and Distribution" },
    { value: "Wine Beer & Liquor", label: "Wine Beer & Liquor" },
    { value: "Womens Issues", label: "Womens Issues" },
  ];


  export const bussiness_type= [
    { value: "REGISTERED_BUSINESS", label: "REGISTERED BUSINESS" },
    { value: "NON_REGISTERED_BUSINESS", label: "NON REGISTERED BUSINESS" },
  ]