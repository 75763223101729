import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import showsuccess from "../../components/SuccessToast";
import axiosInstance, { axiosForm_DataInstance } from "../../utils/useAxios";

export const getCompliance = createAsyncThunk(
  "user/getcompliance",
  async (businessID) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/v1/compliance/${businessID}/`
      );
      return data;
    } catch (error) {
      let errorMsg = "";

      if (error.response && error.response.data.error) {
        errorMsg = error.response.data.error[0];
      } else if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.response && error.response.data.detail) {
        errorMsg = error.response.data.detail;
      } else {
        errorMsg = error.message;
      }
      showerror(`Oops 😠,  ${errorMsg}`);
      return errorMsg;
    }
  }
);

export const uploadDocument = createAsyncThunk(
  "user/compliance_upload",
  async (payloaddata, thunkAPI) => {
    try {
     
      const { data } = await axiosForm_DataInstance.post(
        `/api/v1/compliance/${payloaddata.businessID}/`,
        payloaddata.formData
      );
      showsuccess(data?.message);
      return data;
    } catch (error) {
      let errorMsg = "";

      if (error.response && error.response.data.error) {
        errorMsg = error.response.data.error[0];
      } else if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.response && error.response.data.detail) {
        errorMsg = error.response.data.detail;
      } else {
        errorMsg = error.message;
      }
      showerror(`Oops 😠,  ${errorMsg}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const uploadData = createAsyncThunk(
  "user/compliance_data",
  async (payloaddata, thunkAPI) => {
    try {
     
      const { data } = await axiosInstance.post(
        `/api/v1/compliance/${payloaddata.businessID}/`,
        payloaddata.data
      );
      showsuccess(data?.message);
      return data;
    } catch (error) {
      let errorMsg = "";

      if (error.response && error.response.data.error) {
        errorMsg = error.response.data.error[0];
      } else if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.response && error.response.data.detail) {
        errorMsg = error.response.data.detail;
      } else {
        errorMsg = error.message;
      }
      showerror(`Oops 😠,  ${errorMsg}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const getcomplianceSlice = createSlice({
  name: "compliance",
  initialState: {
    business_compliance_data: null,
    loading: false,
    success:false
  },

  reducers: {
    resetComplianceState: (state) => {
      state.success = false;
      state.uploadloading = false;
      state.loading = false;
    },

  },

  extraReducers: {
    [getCompliance.fulfilled]: (state, action) => {
      state.loading = false;
      state.business_compliance_data = action.payload;
    },
    [getCompliance.pending]: (state) => {
      state.loading = true;
      state.business_compliance_data = null;
    },
    [getCompliance.rejected]: (state) => {
      state.loading = false;
      state.business_compliance_data = null;
    },
    [uploadDocument.fulfilled]: (state, action) => {
 
      state.uploadloading = false;
      state.success = true;
      state.business_compliance_data = action.payload.data;
    },
    [uploadDocument.pending]: (state) => {
      state.uploadloading = true;
      state.success = false;
    },
    [uploadDocument.rejected]: (state) => {
      state.uploadloading = false;
      state.success = false;
    },

    [uploadData.fulfilled]: (state, action) => {
      state.uploadloading = false;
      state.success = true;
      state.business_compliance_data = action.payload.data;
    },
    [uploadData.pending]: (state) => {
      state.uploadloading = true;
      state.success = false;
    },
    [uploadData.rejected]: (state) => {
      state.uploadloading = false;
      state.success = false;
    },


    
  },
});

export const {resetComplianceState} = getcomplianceSlice.actions;
export const getComplianceState = (state) => state.compliance;
export default getcomplianceSlice.reducer;
