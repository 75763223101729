import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import showsuccess from "../../components/SuccessToast";
import axiosInstance from "../../utils/useAxios";

export const getAPIKeys = createAsyncThunk("apikeys/getapikey", async (businessID,thunkAPI) => {
  try {
     
    const { data } = await axiosInstance.get(`/api/v1/getapikey/${businessID}/`);
    
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});



export const generateAPIKeys = createAsyncThunk("apikeys/generateapikey", async (businessID,thunkAPI) => {
    try {
       
      const { data } = await axiosInstance.post(`/api/v1/getapikey/${businessID}/`,{});
      showsuccess(`${data.message}`);
      return data;
    } catch (error) {
      let errorMsg = "";
  
      if (error.response && error.response.data.error) {
        errorMsg = error.response.data.error[0];
      } else if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.response && error.response.data.detail) {
        errorMsg = error.response.data.detail;
      } else {
        errorMsg = error.message;
      }
      showerror(`Oops 😠,  ${errorMsg}`);
      return thunkAPI.rejectWithValue(errorMsg);
    }
  });



const ApikeysSlice = createSlice({
  name: "apikeys",
  initialState: {
    api_key: "",
    api_secret:"",
    loading: false,
  },

 
  extraReducers: {
    [getAPIKeys.fulfilled]: (state, action) => {
      state.loading = false;
      state.api_key = action.payload.api_key;
      state.api_secret = action.payload.api_secret;
      
    },
    [getAPIKeys.pending]: (state) => {
      state.loading = true;
    },
    [getAPIKeys.rejected]: (state) => {
      state.loading = false;
    },

    [generateAPIKeys.fulfilled]: (state, action) => {
        state.loading = false;
        state.api_key = action.payload.api_key;
        state.api_secret = action.payload.api_secret;
      },
      [generateAPIKeys.pending]: (state) => {
        state.loading = true;
      },
      [generateAPIKeys.rejected]: (state) => {
        state.loading = false;
      },
  },
});

export const APIKeysState = (state) => state.apikeys;
export default ApikeysSlice.reducer;
