import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const addnewbusiness = createAsyncThunk(
  "newbussiness/addnew",
  async (postdata, thunkAPI) => {
    try {
      const { data } = await axiosInstance.post(
        `/api/v1/add-new-business/`,
        postdata
      );
      return data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.toString();
      showerror(`Oops! ${message}`);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const newBusinessSlice = createSlice({
  name: "newbussiness",
  initialState: {
    loading: false,
    message: "",
    data: {},
    error: null,
    status: "idle",
  },
  reducers: {
    resetState: (state) => {
      state.error = null;
      state.status = "idle";
    },
  },
  extraReducers: {
    [addnewbusiness.pending]: (state) => {
      state.loading = true;
      state.status = "loading";
    },
    [addnewbusiness.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
      state.status = "success";
    },
    [addnewbusiness.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.status = "failed";
    },
  },
});

export const getNewBusinessState = (state) => state.newbussiness;
export const { resetState } = newBusinessSlice.actions;
export default newBusinessSlice.reducer;
