import { ErrorMessage } from '@hookform/error-message';
import React from 'react'
import Select from "react-select";
import { Base } from '../../components/Base'
import { bussiness_industry, bussiness_type } from '../authentication/data/bussiness_industry';
import * as Yup from "yup";
import { useStateLgaData } from '../authentication/hooks/useStateLgaDopnDown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import showerror from '../../components/ErrorToast';
import { addnewbusiness, getNewBusinessState, resetState } from '../../redux/business/newBusinessSlice';
import showsuccess from '../../components/SuccessToast';
import { getUser, updateState } from '../../redux/authentication/getUserSlice';


export const NewBusiness = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
const [stateFormat, { data: stateLgaData }] = useStateLgaData();
  const { loading,data, status, message } = useSelector(getNewBusinessState);
  
  const changeBusiness = (data) => {
    dispatch(updateState(data));
    localStorage.setItem("payvessel-activeBusiness", JSON.stringify(data));
  };


  React.useEffect(() => {
    if (status === "success") {
      dispatch(resetState());
      changeBusiness(data);
      showsuccess(message)
      dispatch(getUser());
      navigate("/dashboard");
    }
    
  }, [dispatch, message, navigate, status]);

  const validationSchema = Yup.object({
    bizname:  Yup.string().required("Business name is required."),
    businessdesc: Yup.string().required("Business description is required."),
    streetAddress: Yup.string().required("Street Address is required."),
    websiteurl:Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter Valid Website url!'
  ).required("Business Website is required."),
  });

  const [selectedOptions, setSelectedOptions] = React.useState({
    business_industry: "",
    business_type: "",
    state: "",
    lga: "",
    
  });


  const [lga, setLga] = React.useState([]);
  
  
  function handleSelect(data, e) {
    setSelectedOptions((prev) => ({
      ...prev,
      [e.name]: data.value,
    }));

    if (e.name === "state") {
      const stateObj = stateLgaData.find((state) => {
        return state.name === data.value;
      });
      const newStateLga = stateObj.lg.map((lga) => {
        return {
          value: lga,
          label: lga,
        };
      });
      setLga(newStateLga);
    }
  }



  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      bizname: "",
      businessdesc: "",
      streetAddress: "",
      websiteurl:""
    },
  });


  
  const onSubmit = (values) => {

     if (selectedOptions.business_industry === "") {
        showerror("Kindly select a business Industry");
        return;
      }
  
      if (selectedOptions.business_type === "") {
        showerror("Kindly select a business Type");
        return;
      }

      if (selectedOptions.state === "") {
        showerror("Kindly select a state");
        return;
      }
      if (selectedOptions.lga === "") {
        showerror("Kindly select a LGA");
        return;
      }

      const data = {
        account_type: selectedOptions.business_type,
        name:  values.bizname,
        description: values.businessdesc,
        industry: selectedOptions.business_industry,
        state: selectedOptions.state,
        lga: selectedOptions.lga,
        address: values.streetAddress,
        websiteurl:values.websiteurl,
      };

      dispatch(addnewbusiness(data))
    
   
    
};  

  const colourStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      padding: "5px 0px 5px 15px",
      borderColor: state.isFocused ? "#f87e27" : "#ccc",
      "&:hover": {
        border: "1px solid #ccc",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#f87e27"
        : state.isFocused
        ? "#ffe9da"
        : provided.backgroundColor,
    }),
  };
  


  return (
    <Base title={"New Business"} icon={"dashboard_"}>

<div className="new-Business">
      <section className="form-card account">
        <form className="create-act" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="newbussiness__title">
          Add New Business
          </h2>

          <div className="act-form">
                <Select
                  placeholder="Select Business Type"
                  options={bussiness_type}
                  value={
                    selectedOptions.business_type !== "" && {
                      label: selectedOptions.business_type,
                      value: selectedOptions.business_type,
                    }
                  }
                  onChange={handleSelect}
                  isSearchable={false}
                  styles={colourStyles}
                  name="business_type"
                />
              </div>

       
              <div className="act-form">
                <input
                  placeholder="Legal Business Name"
                  type="text"
                  className={
                    errors.bizname
                      ? "form-input form-invalid-input"
                      : "form-input"
                  }
                  {...register("bizname")}
                />
                <ErrorMessage
                  errors={errors}
                  name="bizname"
                  render={({ message }) => (
                    <span className="form-invalid-feedback"> {message}</span>
                  )}
                />
              </div>

              <div className="act-form">
                <input
                  placeholder="Business Website Url"
                  type="url"
                  className={
                    errors.websiteurl
                      ? "form-input form-invalid-input"
                      : "form-input"
                  }
                  {...register("websiteurl")}
                />
                <span style={{"fontSize":"14px"}}> website domain with https// i.e https://www.domain.com</span>
               
                <ErrorMessage
                  errors={errors}
                  name="websiteurl"
                  render={({ message }) => (
                    <span className="form-invalid-feedback"> {message}</span>
                  )}
                />
              </div>

              

              <div className="act-form">
                <textarea
                  placeholder="Business Description"
                  rows={5}
                  cols={60}
                  className={
                    errors.bizname
                      ? "form-textarea form-invalid-input"
                      : "form-textarea"
                  }
                  {...register("businessdesc")}
                />
                <ErrorMessage
                  errors={errors}
                  name="businessdesc"
                  render={({ message }) => (
                    <span className="form-invalid-feedback"> {message}</span>
                  )}
                />
              </div>

              <div className="act-form">
                <Select
                  placeholder="Select Business Industry"
                  options={bussiness_industry}
                  value={
                    selectedOptions.business_industry !== "" && {
                      label: selectedOptions.business_industry,
                      value: selectedOptions.business_industry,
                    }
                  }
                  onChange={handleSelect}
                  isSearchable={true}
                  styles={colourStyles}
                  name="business_industry"
                />
              </div>
            
          

          <div className="state-lga">
            <Select
              placeholder="State"
              options={stateFormat}
              value={
                selectedOptions.state !== "" && {
                  label: selectedOptions.state,
                  value: selectedOptions.state,
                }
              }
              onChange={handleSelect}
              isSearchable={true}
              styles={colourStyles}
              name="state"
            />
            <Select
              placeholder="LGA"
              options={lga}
              value={
                selectedOptions.lga !== "" && {
                  label: selectedOptions.lga,
                  value: selectedOptions.lga,
                }
              }
              onChange={handleSelect}
              isSearchable={true}
              styles={colourStyles}
              name="lga"
            />
          </div>


          <div className="act-form">
            <input
              placeholder="Street Address"
              type="text"
              className={
                errors.bizname ? "form-input form-invalid-input" : "form-input"
              }
              {...register("streetAddress")}
            />
            <ErrorMessage
              errors={errors}
              name="streetAddress"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>


          <button
            type="submit"
            className="btn btn-secondary"
            disabled={loading ? true : false}
          >
            {loading ? (
              <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
            ) : (
              "Submit"
            )}
          </button>

         
        </form>
      </section>
      
</div>

    </Base>

    
  )
}
