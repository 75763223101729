import { selectedTransData, transModal } from "../../redux/kyc/generalSlice";
import { TransactionModal } from "../../components/TransactionModal";
import { useDispatch, useSelector } from "react-redux";
import { formatMoney } from "../../utils/FormatMoney";
import { formatDate } from "../../utils/Dateformat";
import React, { Fragment } from "react";

export const TransactionTable = ({ transactions, text }) => {
  const showModal = useSelector((state) => state.general.trans);
  const dispatch = useDispatch();

  console.log(transactions);

  const openTransModal = (data) => {
    dispatch(transModal(true));
    dispatch(selectedTransData(data));
  };

  return (
    <>



      {showModal && <TransactionModal />}
      <div className="transac-table">
        <table className="table">
          <thead>
            <tr>
              <th>AMOUNT</th>
              <th>MEMO</th>
              <th>REF</th>
              <th>STATUS</th>
              <th>DATE &amp; TIME</th>
              <th>TYPE</th>
            </tr>
          </thead>
          <tbody>
            {transactions?.response?.length > 0 ? transactions?.response?.map((transaction) => (
                    <tr
                      key={transaction.transactionref}
                      onClick={() => openTransModal(transaction)}
                      className="trans"
                    >
                      <td>₦{formatMoney(transaction.amount)}</td>
                      <td>{transaction.description}</td>
                      <td>{transaction.transactionref}</td>
                      <td>
                        {" "}
                        <span
                          className={
                            transaction.status === "successful"
                              ? "btn-success "
                              : transaction.status === "failed"
                              ? "btn-danger"
                              : "btn-warn"
                          }
                        >
                          {transaction.status}
                        </span>
                      </td>
                      <td>{formatDate(transaction.created_date)}</td>
                      <td>
                        {" "}
                        <span
                          className={
                            transaction.transaction_type === "DEBIT"
                              ? "transaction_type trans-debit"
                              : "transaction_type trans-credit"
                          }
                        >
                          {" "}
                          {transaction.transaction_type}{" "}
                        </span>
                      </td>
                    </tr>
                  
              )
            ) : (
              <>
                <tr>
                  <td colSpan="6">
                    <div className="emtyTransactions">
                      <img
                        src={`../images/empty.png`}
                        className="empty"
                        alt="empty "
                      />
                      <p>{text}</p>
                    </div>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
