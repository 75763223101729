import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword, getUserState } from '../../../redux/authentication/getUserSlice'
import showerror from '../../../components/ErrorToast'
import * as Yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';


export const Profile = () => {
  const { userData,pswdLoading:loading } = useSelector(getUserState)

  const dispatch = useDispatch();
  const [showPass, setShowPass] = React.useState(false);
  const [shownewPass, setShownewPass] = React.useState(false);
  const [showConfirmPass, setShowConfirmPass] = React.useState(false);


    const validationSchema = Yup.object({
    
      password: Yup.string()
        .required("password is required"),
      newpassword: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Your password should contain uppercase, special character & number"
        ),
      comfirmnewpassword: Yup.string().oneOf(
        [Yup.ref("newpassword"), null],
        "Password does not match"
      ),
    });

    const { reset,handleSubmit,register,formState: { errors },} = useForm({resolver: yupResolver(validationSchema),});



  const handlePasswordChange = (data) => {

    if (!data.password) {showerror("Current Password is required")}
    else if (!data.newpassword) {showerror("New Password is required")}
    else if (data.newpassword !== data.comfirmnewpassword) {showerror("New Password are not match")}
    else {var post_data = {password:data.password,newpassword:data.newpassword,comfirmnewpassword:data.comfirmnewpassword}
            dispatch(changePassword(post_data))

            reset({});
           }
  }

  return (
    <div className="card profile">
      <div className="setting-profile">
        <h2>Merchant Information</h2>

        <div className="form-flex">

          <div className="edit-profile">
            <label htmlFor="firstname">First Name</label>
            <input type="text" name="fname" value={userData?.firstname} readOnly />
          </div>

          <div className="edit-profile">
            <label htmlFor="lastname">Last Name</label>
            <input type="text" name="fname" value={userData?.lastname} readOnly />
          </div>
        </div>

        <div className="edit-profile">
          <label htmlFor="email">E-mail Address</label>
          <input type="email" name="email" value={userData?.email} readOnly />
        </div>


        <div className="edit-profile">
          <label htmlFor="number" >Phone Number</label>


          <input type="number" name="phone-number" value={userData?.phone} readOnly />

        </div>





        <div class="mg-top">
          <h2>Change Password</h2>
         
          <form className="create-act" onSubmit={handleSubmit(handlePasswordChange)}>


            <div className="act-form phoneinput__container">

<label className="form-input-label ">Current Password</label>
            <div className="innerPasswordInputcontainer">
              {!showPass ? (
                <AiOutlineEyeInvisible
                  size={22}
                  onClick={() => setShowPass(!showPass)}
                  className={`password__eye ${
                    errors.password && "password__eye-error"
                  }`}
                />
              ) : (
                <AiOutlineEye
                  size={22}
                  onClick={() => setShowPass(!showPass)}
                  className={`password__eye ${
                    errors.password && "password__eye-error"
                  }`}
                />
              )}

              <input
                placeholder="Current Password"
                type={showPass ? "text" : "password"}
                className={
                  errors.password
                    ? "form-input form-invalid-input"
                    : "form-input"
                }
                {...register("password")}
              />
            </div>
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>



            <div className="act-form phoneinput__container">

<label className="form-input-label ">New Password</label>
            <div className="innerPasswordInputcontainer">
              {!shownewPass ? (
                <AiOutlineEyeInvisible
                  size={22}
                  onClick={() => setShownewPass(!shownewPass)}
                  className={`password__eye ${
                    errors.newpassword && "password__eye-error"
                  }`}
                />
              ) : (
                <AiOutlineEye
                  size={22}
                  onClick={() => setShownewPass(!shownewPass)}
                  className={`password__eye ${
                    errors.newpassword && "password__eye-error"
                  }`}
                />
              )}

              <input
                placeholder="Password"
                type={shownewPass ? "text" : "password"}
                className={
                  errors.newpassword
                    ? "form-input form-invalid-input"
                    : "form-input"
                }
                {...register("newpassword")}
              />
            </div>
            <ErrorMessage
              errors={errors}
              name="newpassword"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>
          <div className="act-form phoneinput__container">
          <label className="form-input-label ">Comfirm New Password</label>
            <div className="innerPasswordInputcontainer">
              {!showConfirmPass ? (
                <AiOutlineEyeInvisible
                  size={22}
                  onClick={() => setShowConfirmPass(!showConfirmPass)}
                  className={`password__eye ${
                    errors.comfirmnewpassword && "password__eye-error"
                  }`}
                />
              ) : (
                <AiOutlineEye
                  size={22}
                  onClick={() => setShowConfirmPass(!showConfirmPass)}
                  className={`password__eye ${
                    errors.comfirmnewpassword && "password__eye-error"
                  }`}
                />
              )}


              <input
                placeholder="Confirm Password"
                type={showConfirmPass ? "text" : "password"}
                className={
                  errors.comfirmnewpassword
                    ? "form-input form-invalid-input"
                    : "form-input"
                }
                {...register("comfirmnewpassword")}
              />
            </div>
            <ErrorMessage
              errors={errors}
              name="comfirmnewpassword"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>
          

<button
            type="submit"
            className="btn btn-full btn-secondary"
            disabled={loading ? true : false}
          >
            {loading ? (
              <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
            ) : (
              "Save"
            )}
          </button>










           


          </form>

        </div>


      </div>
    </div>
  )
}
