import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import showsuccess from "../../components/SuccessToast";
import axiosInstance from "../../utils/useAxios";

export const getOTP = createAsyncThunk("otp/getotp", async (post_data,thunkAPI) => {
  try {
    const { data } = await axiosInstance.post(`/api/v1/get-otp/`,post_data);
    showsuccess(`${data.message}`);
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});




const otpSlice = createSlice({
  name: "otp",
  initialState: {
   
    loading: false,
  },

 
  extraReducers: {
    [getOTP.fulfilled]: (state, action) => {
      state.loading = false;
     
      
    },
    [getOTP.pending]: (state) => {
      state.loading = true;
    },
    [getOTP.rejected]: (state) => {
      state.loading = false;
    },

}
});

export const getOtpState = (state) => state.otp;
export default otpSlice.reducer;
