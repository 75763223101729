import React, { useEffect,  } from "react";
import { ShimmerTable,ShimmerThumbnail } from "react-shimmer-effects";
import {

  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";

import { Link } from "react-router-dom";
import { Base } from "../../components/Base";
import { useDispatch, useSelector } from "react-redux";
import { getUserState } from "../../redux/authentication/getUserSlice";
import { getBusiness, getBusinessState } from "../../redux/dashboard/getBusinessSlice";
import { formatMoney } from "../../utils/FormatMoney";
import { getTransactions, getTransactionsState } from "../../redux/transactions/getTransactionsSlice";
import { formatDate } from "../../utils/Dateformat";
import { getTransactionsChart, getTransactionsChartState } from "../../redux/transactions/getTransactionsChartSlice";
import { selectedTransData, transModal } from "../../redux/kyc/generalSlice";
import { TransactionModal } from "../../components/TransactionModal";

export const DashboardScreen = () => {
  const { activeBusiness }  = useSelector(getUserState)
  const { businessdata }  = useSelector(getBusinessState)
  const { transactions,transactions_loading }  = useSelector(getTransactionsState)
  const { transactions_chart,transactions_chart_loading }  = useSelector(getTransactionsChartState)
  const showModal = useSelector((state) => state.general.trans);


  const openTransModal = (data) => {
    dispatch(transModal(true));
    dispatch(selectedTransData(data));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if(activeBusiness?.businessid){
      dispatch(getBusiness(activeBusiness.businessid));
      dispatch(getTransactions({"businessid":activeBusiness?.businessid,"page":null}));
      dispatch(getTransactionsChart(activeBusiness.businessid));
   
    }
    
  }, [activeBusiness, dispatch]);
 


  
  return (
    
    <Base title={"Dashboard"} icon={"dashboard_"}>
      <>


      

      { !businessdata?.verify &&    <div className="card unlock kycflex" style={{backgroundColor: 'pink', margin: '25px 0px'}}>
     <div>  <h2>Complete your Business Onboard Process! </h2>
 
          <p>Fill out the KYC section to start receiving payment directly to your bank account. </p>
          </div> 
          <Link to="/compliance" className="btn btn-prm" style={{backgroundColor: 'black'}}>Complete KYC</Link>
        </div>}
   

        <div className="get-user">
          <h3>Hello, {activeBusiness?.name} 😎</h3>
          <p>Here's what's going on in your account today</p>
        </div>

        <div className="boxes">
  <div className="card dash-card">
    <p>Account Balance</p>
    <h2>₦{formatMoney(businessdata?.wallet_balance)}</h2>
    <p className="box-p">
      You can withdraw or transfer this funds to your payout bank account.{" "}
    </p>
    <span className="prm-color">
      <Link to="/withdraw" class="link">
        Withdraw Balance <i className="fa fa-arrow-up-right-from-square" />
      </Link>
    </span>
  </div>

 
  {/* <div className="card dash-card">
    <p>Total Revenue</p>
    <h2>₦{formatMoney(businessdata?.total_received)}</h2>
    <p className="box-p">
      This is the total amount income you have generated on this account.{" "}
    </p>
    <span className="prm-color">
      <Link to="/transactions">
        Check Revenue history
        <i className="fa fa-arrow-up-right-from-square" />
      </Link>
    </span>
  </div> */}
  <div className="card dash-card">
    <p>Daily Income</p>
    <h2>₦{formatMoney(businessdata?.daily_revenue)}</h2>
    <p className="box-p">This is the total amount income you have generated on this account today. </p>
    <span className="prm-color">
    <Link to="/transactions">
        See all history <i className="fa fa-arrow-up-right-from-square" />
      </Link>
    </span>
  </div>

  <div className="card dash-card">
    <p>Total Transaction</p>
    <h2>{businessdata?.total_transaction}</h2>
    <p className="box-p">All the credit transaction you have done. </p>
    <span className="prm-color">
    <Link to="/transactions">
        See all history <i className="fa fa-arrow-up-right-from-square" />
      </Link>
    </span>
  </div>

  <div className="card dash-card">
    <p>Total Withdraw</p>
    <h2>₦{formatMoney(businessdata?.total_withdraw)}</h2>
    <p className="box-p">All succesfull withdraw you have done </p>
    <span className="prm-color">
    <Link to="/transactions">
        See all history <i className="fa fa-arrow-up-right-from-square" />
      </Link>
    </span>
  </div>

  {/* <div className="card dash-card">
    <p>Customers</p>
    <h2>{businessdata?.customers}</h2>
    <p className="box-p"> This is the total number of customers that you have issued virtual account for.</p>
    <span className="prm-color">
    <Link to="/customers">
        See all customers <i className="fa fa-arrow-up-right-from-square" />
        </Link>
    </span>
  </div> */}
</div>


        {/* <div className="statistic-cards">

<div className="card-money" >
<p>Total Account Balance</p>
           

  <div class="wallet-balance">
  <h2>₦{formatMoney(businessdata?.wallet_balance)}</h2>

  <i class="fa fa-eye-slash"></i>

  </div>
  
 
</div>

<div className="card-money">
<p>Total Revenue</p>

  <div class="wallet-balance">
  <h2>₦{formatMoney(businessdata?.total_received)}</h2>
  </div>
 

</div>

<div className="card-money">
<p>Daily Revenue</p>

  <div class="wallet-balance">
  <h2>₦{formatMoney(businessdata?.daily_revenue)}</h2>
  </div>
 

</div>

<div className="card-money">
<p>Customers</p>

  <div class="wallet-balance">
  <h2>{businessdata?.customers}</h2>
  </div>
 

</div>


</div> */}


        {/* <div className="boxes">
          <div className="card">
            <p>Total Account Balance</p>
            <h2>₦{formatMoney(businessdata?.wallet_balance)}</h2>
            <p className="box-p">
              You can withdraw or transfer this funds to any bank account.{" "}
            </p>
            <span className="prm-color">
              <Link to="withdraw">
                Withdraw Balance{" "}
                <i className="fa fa-arrow-up-right-from-square" />
              </Link>
            </span>
          </div>
          <div className="card">
            <p>Total Revenue</p>
            <h2>₦{formatMoney(businessdata?.total_received)}</h2>
            <p className="box-p">
              This is the total amount income you have generated on this
              account.{" "}
            </p>
            <span className="prm-color">
              <Link to="revenue">
                Check Revenue history
                <i className="fa fa-arrow-up-right-from-square" />
              </Link>
            </span>
          </div>
        
          <div className="card">
            <p>Daily Revenue</p>
            <h2>₦{formatMoney(businessdata?.daily_revenue)}</h2>
            <p className="box-p">
              Your next settlement is on {userData?.nextSettlementDate}.{" "}
            </p>
            <span className="prm-color">
              <Link to="withdraw">
                This is your daily revenue
                <i className="fa fa-arrow-up-right-from-square" />
              </Link>
            </span>
          </div>

          <div className="card">
            <p>Customers</p>
            <h2>{businessdata?.customers}</h2>
            <p className="box-p">
            This is the total number of customers that you have issued virtual account for.
            </p>
            <span className="prm-color">
              <Link to="withdraw">
              see all your customers{" "}
                <i className="fa fa-arrow-up-right-from-square" />
              </Link>
            </span>
          </div>
        </div> */}
        <div className="trade-view">
          <div className="card">
            <div className="trade-preview">
              <h2 style={{ fontSize: "1.2em" }}>Overview</h2>
             
            </div>

            {/* Implement Area Graph Chart */}
            {transactions_chart_loading ? (
            <>
            
             <ShimmerThumbnail height={400} rounded />
            
            </>
            ) : (
              <ResponsiveContainer width="100%" height={400}>

            <BarChart width={600} height={300} data={transactions_chart?.response}>
                <XAxis dataKey="month"  />
                <YAxis />
                <Tooltip />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <Bar dataKey="total" fill="#ff6b00" barSize={30} />
              </BarChart>

              </ResponsiveContainer>

            )}
          </div>
        </div>


        {showModal && <TransactionModal />}
        <div className="transaction-table">
          <div className="card">
            <div className="trade-preview">
              <h2 style={{ fontSize: "1.2em" }}>Latest Transaction </h2>
            
            
             <Link to="/transactions" style={{color:"black"}} > View More</Link>
            </div>
            {transactions_loading ?<>  <ShimmerTable row={5} col={5} /></>:
            <div className="transac-table">
              <table className="table">
                <thead>
                  <tr>
                  <th>AMOUNT</th>
                    <th>MEMO</th>
                    <th>REF</th>
                    <th>STATUS</th>
                    <th>DATE &amp; TIME</th>
                    <th>TYPE</th>
                    
                  </tr>
                </thead>
                <tbody>


                  
                  {transactions?.response?.length > 0 ?
                   transactions?.response?.map((transaction) => (
                        <tr key={transaction.transactionref} onClick={() => openTransModal(transaction)}>
                        <td>₦{formatMoney(transaction.amount)}</td>
                        <td>{transaction.description}</td>
                        <td>{transaction.transactionref}</td>
                        <td > <span className={transaction.status === "successful" ? "btn-success " : transaction.status === "failed" ? "btn-danger" :"btn-warn"}>{transaction.status}</span></td>
                        <td>{formatDate(transaction.created_date)}</td>
                        <td> <span className={transaction.transaction_type === "DEBIT" ? "transaction_type trans-debit":"transaction_type trans-credit"}> {transaction.transaction_type} </span></td>
                        
                      </tr>

                       )
                      
                      
                      ):<>

                      <tr>
                        <td colSpan="6">

                      <div class="emtyTransactions">

                      <img src={`../images/empty.png`} className="empty" alt="empty " />

                      <p>You do not have any transactions to show.</p>


                      </div>
                      </td>

                      </tr>
                      
                      </>}



                </tbody>
              </table>
            </div>
            }
          </div>
        </div>


      </>
    </Base>
  );
};
