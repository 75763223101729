import React from 'react'

export const Pagination = ({virtualaccount,goto}) => {
  return (
    <div className="page-number">
           <div className="card flex-number">
               {virtualaccount?.previous &&   <p onClick={()=>goto(virtualaccount?.previous_page_number)}><i className="fas next active-two fa-angle-left" /></p>}

               { virtualaccount?.elipse.map((page)=>
               page != null ? <p className= {virtualaccount?.page === page ?  "active-two" : ""}  onClick={()=>goto(page)}>{page}</p> : <p>...</p>
               )}
             {virtualaccount?.next &&   <p onClick={()=>goto(virtualaccount?.next_page_number)}><i className="fas next active-two fa-angle-right" /></p>}
            
           </div>
         </div>
  )
}
