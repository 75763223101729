import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";


export const withdraw = createAsyncThunk("withdraw/withdraw", async (payload,thunkAPI) => {
    try {
       
      // const { data } = await axiosInstanceNEW.post(`/api/v1/withdraw/${payload.businessID}/`,payload.data);
      const { data } = await axiosInstance.post(`/api/v1/withdraw/${payload.businessID}/`,payload.data);
      
      return data;
    } catch (error) {
      let errorMsg = "";
  
      if (error.response && error.response.data.error) {
        errorMsg = error.response.data.error[0];
      } else if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.response && error.response.data.detail) {
        errorMsg = error.response.data.detail;
      } else {
        errorMsg = error.message;
      }
      showerror(`Oops 😠,  ${errorMsg}`);
      return thunkAPI.rejectWithValue(errorMsg);
    }
  });



const withdrawSlice = createSlice({
  name: "withdraw",
  initialState: {
    amount: "",
    otp: "",
    success:false,
    loading: false,
    resp_data:null
  },

  reducers: {

  updateAmount: (state,action) => {
    const { name, value } = action.payload;
    state[name] = value;
  },

  updateStatus: (state,action) => {
     state.success = false;
   
  },
},

  extraReducers: {
    [withdraw.fulfilled]: (state, action) => {
      state.loading = false;
      state.amount = action.payload.amount;
      state.success = true;
      state.resp_data =  action.payload
    },
    [withdraw.pending]: (state) => {
      state.loading = true;
    },
    [withdraw.rejected]: (state) => {
      state.loading = false;
    },

    
  },
});
export const withdrawState = (state) => state.withdraw;
export const { updateAmount,updateStatus } = withdrawSlice.actions;
export default withdrawSlice.reducer;
