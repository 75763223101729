import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Base } from '../../components/Base'
import { getUserState } from '../../redux/authentication/getUserSlice'
import { getTransactions, getTransactionsState } from '../../redux/transactions/getTransactionsSlice'
import { ShimmerTable } from "react-shimmer-effects";
import { TransactionTable } from './TransactionTable'
import { Pagination } from './Pagination'
import { querytransaction, querytransactionState, resetQuery, setQuery } from '../../redux/transactions/querytransactionSlice'
import { formatDateAndTime, formatDateNew } from '../../utils/Dateformat'
import { filtertransaction, filtertransactionState, resetFilter } from '../../redux/transactions/filtertransactionSlice'


export const Transactions = () => {

  const { activeBusiness }  = useSelector(getUserState)
  const { transactions, transactions_loading }  = useSelector(getTransactionsState)
  const { query, querytransactionlist,queryloading }  = useSelector(querytransactionState)
  const { filtertransactionlist,filterloading }  = useSelector(filtertransactionState)
  const [start_date, setStart_date] = useState('')
  const [end_date, setEnd_date] = useState('')
  const [isfilter, setFilter] = useState(false)
  
  const getFilter = () => {
    let filterbox = document.querySelector(".filter-form");
    filterbox.classList.toggle("toggle-filter");
  };


  const dispatch = useDispatch();
  
  useEffect(() => {
    if(activeBusiness?.businessid){
     
      dispatch(getTransactions({"businessid":activeBusiness?.businessid,"page":null}));
   
    }
    
  }, [activeBusiness, dispatch]);
 

  const goto =(page)=>{

    
    dispatch(getTransactions({"businessid":activeBusiness?.businessid,"page":page}))
}

const search = (event)=>{
  const query = event.target.value
  dispatch(setQuery(query)) 
  
  if (query?.length > 0){
    dispatch(querytransaction({"businessid":activeBusiness?.businessid,"query":query}))
  }
  else{
    dispatch(resetQuery()) 
  }
}


const filter = (event)=>{

  setFilter(true);
  let start_date = '';
  let end_date = '';
  const today = new Date();

  
  switch(event){
    case 'Yesterday':
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          start_date = yesterday;
          end_date = today;
          break;

    case 'Last 7 days':
          const last7days = new Date();
          last7days.setDate(last7days.getDate() - 7);
          start_date = last7days;
          end_date = today;
          break;

    case 'Last 15 days':
          const last15days = new Date();
          last15days.setDate(last15days.getDate() - 15);
          start_date = last15days;
          end_date = today;
          break;

    case 'Last month':
          const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
          const startDate = new Date(firstDayOfMonth);
          startDate.setDate(startDate.getDate() - 1);
          const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
          start_date = endDate;
          end_date = startDate;
          break;

    default:

      start_date = '';
      end_date = '';
      

      break;
  }

  start_date = formatDateNew(start_date)
  end_date = formatDateNew(end_date)
  dispatch(filtertransaction({"businessid":activeBusiness?.businessid,"start_date":start_date,"end_date":end_date}))
  let filterbox = document.querySelector(".filter-form");
  filterbox.classList.remove("toggle-filter");
}


const customfilter = ()=>{

  var _start_date = formatDateAndTime(start_date)
  var _end_date = formatDateAndTime(end_date)

  dispatch(filtertransaction({"businessid":activeBusiness?.businessid,"start_date":_start_date,"end_date":_end_date}))
  let filterbox = document.querySelector(".filter-form");
  filterbox.classList.remove("toggle-filter");
}

const clearfilter = ()=>{
 setFilter(false);
 dispatch(resetFilter()) 

 let filterbox = document.querySelector(".filter-form");
  filterbox.classList.remove("toggle-filter");

}



return (
<Base title={"Transactions"} icon={"dashboard_"}>

   <>

            
   <div className="transaction-table">
        <div className="card">
          <div className="trade-preview">
          <form action>
              <div className="p-relative">
            
              <input type="text" value={query} onChange={search} className="transac-search" placeholder="Search" />
              {query?.length > 0 &&  <span className="form-input-append">
                <i className= "fa fa-times" onClick={()=>dispatch(resetQuery()) }></i>
              </span>}
             
              </div>
            </form>
           
           { query?.length < 1 &&  <> <div className="filter-plus">

             {isfilter &&  <button className="year-option filter" onClick={clearfilter}>Clear Filter <i className="fa fa-times" />
             </button> }
          

              <button className="year-option filter" onClick={getFilter}>Filter <i className="fa fa-filter" />
              </button>
          
              <div className="filter-form card">

              <div className="filter-radio">
                 
                  
                  <div className="flex">
                  <label>Yesterday</label>
                  <input type="radio" name="filter" onChange={()=>filter('Yesterday')} className='filter_radio' value="yesterday"></input>
                  </div>
                </div>

                <div className="filter-radio">
                 
                  
                 <div className="flex">
                 <label>Last 7 days</label>
                 <input type="radio" name="filter" onChange={()=>filter('Last 7 days')} className='filter_radio' value="yesterday"></input>
                 </div>
               </div>

               <div className="filter-radio">
                 
                  
                 <div className="flex">
                 <label>Last 15 days</label>
                 <input type="radio" name="filter" onChange={()=>filter('Last 15 days')} className='filter_radio' value="yesterday"></input>
                 </div>
               </div>

               <div className="filter-radio">
                 
                  
                 <div className="flex">
                 <label>Last month</label>
                 <input type="radio" name="filter"  onChange={()=>filter('Last month')} className='filter_radio' value="yesterday"></input>
                 </div>
               </div>

               <div className="filter-radio">
                 
                  
                 <div className="flex">
                 <label>Export to Excel</label>
                 <input type="radio" name="filter" className='filter_radio' value="yesterday"></input>
                 </div>
               </div>

               <div class="filter-divider"></div>

                <div className="filter-set">
                  <label>Custom data range</label>
                 
                  <div className="flex">
                    <input className='input' type="datetime-local" name="start-date" value={start_date} onChange={(e)=>setStart_date(e.target.value)} placeholder="Start Date" />
                    <input className='input' type="datetime-local" name="end-date" value={end_date} onChange={(e)=>setEnd_date(e.target.value)} placeholder="End Date" />
                  </div>
                </div>
               
                
               
                  <input type="button"  onClick={customfilter} value="Apply Filter" className="btn btn-prm"  />
              
              </div>
            </div>
            </> 
}
          </div>
         
         

             {query?.length > 0 ?  queryloading ?<>  <ShimmerTable row={5} col={5} /></>:   
                <TransactionTable  transactions={querytransactionlist} text='Your query did not match any records.'/>:  isfilter ?  filterloading ? <>  <ShimmerTable row={5} col={5} /></> :  <TransactionTable transactions={filtertransactionlist} text='Your Filter did not match any records.'/>:
               transactions_loading ?<>  <ShimmerTable row={5} col={5} /></>:
               <TransactionTable transactions={transactions} text='You do not have any transactions to show.'/>
               
              }



        </div>

       { query?.length > 0 ? <Pagination transactions={querytransactionlist}  goto={goto}/>: isfilter ? <Pagination transactions={filtertransactionlist} goto={goto}/> : <Pagination transactions={transactions} goto={goto}/>

            }
      </div>
   </>
   
</Base>
)
}
