import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import showsuccess from "../../components/SuccessToast";
import axiosInstance from "../../utils/useAxios";

export const getWebhook = createAsyncThunk("webhook/getwebhook", async (businessID,thunkAPI) => {
  try {
    const { data } = await axiosInstance.get(`/api/v1/get-webhook-url/${businessID}/`);
    
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});

export const updateWebhook = createAsyncThunk("webhook/updatewebhook", async (payload,thunkAPI) => {
    try {
       
      const { data } = await axiosInstance.post(`/api/v1/get-webhook-url/${payload.businessID}/`,payload.data);
      showsuccess(`${data.message}`);
      return data;
    } catch (error) {
      let errorMsg = "";
  
      if (error.response && error.response.data.error) {
        errorMsg = error.response.data.error[0];
      } else if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.response && error.response.data.detail) {
        errorMsg = error.response.data.detail;
      } else {
        errorMsg = error.message;
      }
      showerror(`Oops 😠,  ${errorMsg}`);
      return thunkAPI.rejectWithValue(errorMsg);
    }
  });



const webhookSlice = createSlice({
  name: "webhook",
  initialState: {
    webhook_url: "",
    loading: false,
  },

  reducers: {

  updateWebhookurl: (state,action) => {
    const { name, value } = action.payload;
    state[name] = value;
  },
},

 
  extraReducers: {
    [getWebhook.fulfilled]: (state, action) => {
      state.loading = false;
      state.webhook_url = action.payload.webhook_url;
     
      
    },
    [getWebhook.pending]: (state) => {
      state.loading = true;
    },
    [getWebhook.rejected]: (state) => {
      state.loading = false;
    },

    [updateWebhook.fulfilled]: (state, action) => {
        state.loading = false;
        state.webhook_url = action.payload.webhook_url;
       
      },
      [updateWebhook.pending]: (state) => {
        state.loading = true;
      },
      [updateWebhook.rejected]: (state) => {
        state.loading = false;
      },
  },
});
export const WebhookState = (state) => state.webhook;
export const { updateWebhookurl } = webhookSlice.actions;
export default webhookSlice.reducer;
