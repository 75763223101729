import React from 'react'

export const Pagination = ({customers,goto}) => {
  return (
    <div className="page-number">
           <div className="card flex-number">
               {customers?.previous &&   <p onClick={()=>goto(customers?.previous_page_number)}><i className="fas next active-two fa-angle-left" /></p>}

               { customers?.elipse.map((page)=>
               page != null ? <p className= {customers?.page === page ?  "active-two" : ""}  onClick={()=>goto(page)}>{page}</p> : <p>...</p>
               )}
             {customers?.next &&   <p onClick={()=>goto(customers?.next_page_number)}><i className="fas next active-two fa-angle-right" /></p>}
            
           </div>
         </div>
  )
}
