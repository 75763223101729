import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ShimmerTitle } from "react-shimmer-effects";
import {
  getUserState,
  updateState,
} from "../redux/authentication/getUserSlice";
import { logoutAction } from "../redux/authentication/userLoginSlice";

export const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
 

  const logoutHandler = (data) => {
    dispatch(logoutAction());
  };

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const { userData,loading, activeBusiness } = useSelector(getUserState);

  const businessTogle = () => {
    let businessmenu = document.querySelector(".businessmenu");
    businessmenu.classList.toggle("active");
  };

  const changeBusiness = (data) => {
    dispatch(updateState(data));
    localStorage.setItem("payvessel-activeBusiness", JSON.stringify(data));
  };

  const closeNav = () => {
    let mainNav = document.querySelector(".sidebar");
    mainNav.classList.remove("sidebar-toggle-active");
  };

  return (
    <div className="navbar">

      <div class="brand-flex">

     
      <Link class="brand" to={"/"}>
        <img src="/images/logo-white.png" alt="" className="bar-logo" />
      </Link>

      <i className="close-icon fas fa-times" onClick={closeNav} />

      </div>

     

      <div className="businessName">
        <div className="activebusiness">
          <div>
            {loading ?<><ShimmerTitle line={1} gap={10} variant="primary"  />  </>:<>  <span> {activeBusiness?.name}</span>
            <span>{activeBusiness?.virtual_account?.length > 0 && activeBusiness?.virtual_account[0]['accountNumber']} | {activeBusiness?.virtual_account?.length > 0 && activeBusiness?.virtual_account[0]['bankName']}</span>{" "}
       </>}
              </div>

          <i className="fas fa-angle-down" onClick={businessTogle} />
        </div>

        <div className="businessmenu ">
          <div class="divider"></div>

          <div className="businesslist">
            {userData?.business.map(
              (x) =>
                x.name !== activeBusiness?.name && (
                  <>
                    <span onClick={() => changeBusiness(x)}>{x.name}</span>
                    <div class="divider"></div>
                  </>
                )
            )}

            <span> <Link to="/add-new-business">Add New Business</Link></span>
            <div class="divider"></div>

           
          </div>
        </div>
      </div>

      <nav className="nav-list">
        <ul className="nav-list-first">
          <li className={splitLocation[1] === "dashboard" ? "active" : ""}>
            <Link to="/dashboard">
              <span className="flex-icon">
                <img
                  src={
                    "../images/dashboard.png"
                  }
                  className="nav-icon"
                  alt=""
                />

                <span class="side-text">Dashboard</span>
                
              </span>
            </Link>
          </li>

          <li className={splitLocation[1] === "compliance" ? "active" : ""}>
            <Link to="/compliance">
              <span className="flex-icon">
              <img
                  src={
                    "../images/icons/compliance.png"
                  }
                  className="nav-icon"
                  alt=""
                />
                
                <span class="side-text">Compliance</span>
              </span>
            </Link>
          </li>

          <li className={splitLocation[1] === "transactions" ? "active" : ""}>
            <Link to="/transactions">
              <span className="flex-icon">
                <img
                  src={
                     "../images/icons/transactions.png"
                  }
                  className="nav-icon"
                  alt=""
                />

<span class="side-text">Transactions</span>
                
              </span>
            </Link>
          </li>


          <li className={splitLocation[1] === "withdraw" ? "active" : ""}>
            <Link to="/withdraw">
              <span className="flex-icon">

              <img
                  src={
                     "../images/icons/bank.png"
                  }
                  className="nav-icon"
                  alt=""
                />
               <span class="side-text">Withdraw</span>
                
              </span>
            </Link>
          </li>

          <li className={splitLocation[1] === "customer-reserved-account" ? "active" : ""}>
            <Link to="/customer-reserved-account">
              <span className="flex-icon">
              <img
                  src={
                     "../images/icons/card.png"
                  }
                  className="nav-icon"
                  alt=""
                />

                <span class="side-text"> Virtual Accounts</span>
              </span>
            </Link>
          </li>

          <li className={splitLocation[1] === "customers" ? "active" : ""}>
            <Link to="/customers">
              <span className="flex-icon">
              <img
                  src={
                     "../images/icons/customers.png"
                  }
                  className="nav-icon"
                  alt=""
                />
                <span class="side-text"> Customers</span>
              </span>
            </Link>
          </li>

          <li className={splitLocation[1] === "webhook-event" ? "active" : ""}>
            <Link to="/webhook-event">
              <span className="flex-icon">
              <img
                  src={
                     "../images/icons/webhook.png"
                  }
                  className="nav-icon"
                  alt=""
                />
                <span class="side-text"> Webhook Events</span>
              </span>
            </Link>
          </li>

       

          <li className={splitLocation[1] === "settings" ? "active" : ""}>
            <Link to="/settings">
              <span className="flex-icon">
                <img
                  src={
                     "../images/icons/settings.png"
                  }
                  className="nav-icon"
                  alt=""
                />
                
                <span class="side-text"> Settings</span>
              </span>
            </Link>
          </li>

          <li className={splitLocation[1] === "support" ? "active" : ""}>
            <Link to="/support">
              <span className="flex-icon">
              <img
                  src={
                     "../images/icons/support.png"
                  }
                  className="nav-icon"
                  alt=""
                />
                
                <span class="side-text"> Support</span>
              </span>
            </Link>
          </li>
        </ul>
        <ul className="nav-list-second">
          <li className={splitLocation[1] === "developer" ? "active" : ""}>
            <Link to="https://payvessel.gitbook.io/payvessel/" target="_blank">
              <span className="flex-icon">
                <img src="../images/code.png" className="nav-icon" alt="" />{" "}
                
                <span class="side-text"> Documentation</span>
              </span>
            </Link>
          </li>

          <li className={splitLocation[1] === "logout" ? "active" : ""}>
            <Link to="#" onClick={logoutHandler}>
              <span className="flex-icon">
                <img src="../images/logout.png" className="nav-icon" alt="" />{" "}
                
                <span class="side-text"> Log out</span>
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};
