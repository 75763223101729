import React, { useState } from 'react'
import { Base } from '../../components/Base'
import { Apikey } from './components/Apikey'
import { BusinessInfo } from './components/BusinessInfo'
import { PayoutAccount } from './components/PayoutAccount'
import { Profile } from './components/Profile'
import { Preferences } from '../preference/Preferences'

import { SettingsHeader} from './components/SettingsHeader'
import { Webhook } from './components/Webhook'

export const Settings = () => {
 
 const [activeTab,setActiveTab] = useState("Profile")

 const setactive = (menu)=>{
  setActiveTab(menu)
 }

return (
<Base title={"Settings"} icon={"dashboard_"}>

   <>
   <div>
       <SettingsHeader activeTab={activeTab} activeTabAction={setactive} />
      
      {activeTab === "Profile" && <Profile/>} 
      {activeTab === "Business Info" && <BusinessInfo/>} 
      {activeTab === "Payout Bank Accounts" &&  <PayoutAccount/>} 
      {activeTab === "API Key" && <Apikey/>} 
      {activeTab === "WebHook" &&  <Webhook/>} 
      {activeTab === "Preferences" && <Preferences />}
       
       
      </div>
   </>
   
</Base>
)
}
