import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import showsuccess from "../../components/SuccessToast";
import axiosInstance from "../../utils/useAxios";

export const getUser = createAsyncThunk("user/getuser", async (thunkAPI) => {
  try {
    const { data } = await axiosInstance.get(`/api/v1/user/`);

   const  active = localStorage.getItem("payvessel-activeBusiness")? JSON.parse(localStorage.getItem("payvessel-activeBusiness"))
    : data.business[0]

    localStorage.setItem("payvessel-activeBusiness", JSON.stringify(active));

    return {activeBusiness:active, ...data};
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});



export const verifyEmail = createAsyncThunk("user/verifyemail", async (payload,thunkAPI) => {
  try {
     
    const { data } = await axiosInstance.post(`/api/v1/verify-email/${payload.businessID}/`,payload.data);
    showsuccess(data.message);

    const  active = localStorage.getItem("payvessel-activeBusiness")? JSON.parse(localStorage.getItem("payvessel-activeBusiness"))
    : data.data.business[0]

    localStorage.setItem("payvessel-activeBusiness", JSON.stringify(active));

    return {activeBusiness:active, ...data.data};

  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});


export const changePassword = createAsyncThunk("user/updatePassword", async (post_data,thunkAPI) => {
  try {
    const { data } = await axiosInstance.post(`/api/v1/password_change/`, post_data);
    
    showsuccess(`${data.message}`)

  } catch (error) {
   
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
})


const getuserSlice = createSlice({
  name: "user",
  initialState: {
    userData: null,
    otp:"",
    success:false,
    loading: false,
    activeBusiness: localStorage.getItem("payvessel-activeBusiness")
    ? JSON.parse(localStorage.getItem("payvessel-activeBusiness"))
    : null, 
    
    pswdLoading: false,
    

  },
  reducers: {
    updateState: (state,action) => {
      state.activeBusiness = action.payload;
    },
    updateOtp: (state,action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },

    resetSuccess: (state,action) => {
      
      state.success = false;
    },
  },
  extraReducers: {
    [getUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.activeBusiness = action.payload.activeBusiness
      
    },
    [getUser.pending]: (state) => {
      state.loading = true;
    },
    [getUser.rejected]: (state) => {
      state.loading = false;
    },

    [verifyEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // state.userData = action.payload;
      state.success = true;
    
    },
    [verifyEmail.pending]: (state) => {
      state.loading = true;
    },
    [verifyEmail.rejected]: (state) => {
      state.loading = false;
    },
    [changePassword.pending]: (state) => {
      state.pswdLoading = true;
    },
    [changePassword.fulfilled]: (state, {payload}) => {
      
      state.pswdLoading = false;
    },
    [changePassword.rejected]: (state, {payload}) => {
      state.pswdLoading = false;
     
    }
  },
});

export const getUserState = (state) => state.user;
export const { updateState,updateOtp,resetSuccess } = getuserSlice.actions;
export default getuserSlice.reducer;
