import React, { useState } from 'react'
import { Base } from '../../components/Base'

export const Preferences = () => {
  const [ selectedOption, setSelectedOption ] = useState('');
  const [ receiptOption, setReceiptOption ] = useState('')

  const handleSelectedOption = (option) => {
    setSelectedOption(option)
  }

  const handleReceiptOption = (option) => {
    setReceiptOption(option)
  }


return (


   <>
   <div>

        <div className="card profile">
          <h2 style={{textAlign: 'center', fontSize: '28px', fontWeight: 400, margin: '10px 0px 35px'}}>Payment Methods</h2>
          <p>Selects payment options you want your customers to pay with</p>
          <div className="card pay-option">
            <div className="sm-card">
              <h3 style={{fontWeight: 500}}>Cards</h3>
              <p style={{opacity: '0.8', marginTop: '10px'}}>Visa, Mastercard, Verve, Discover.</p>
            </div>

          <div onClick={() => handleSelectedOption('Cards')} style={{cursor: 'pointer'}}>
            { selectedOption === 'Cards' ? 
            <img src="../images/toggleOn.png" alt="" className="nav-ico" /> :
            <img src="../images/toggleOff.png" alt="" className="nav-ico" /> }
          </div> 

          </div>
          <div className="card pay-option">
            <div className="sm-card">
              <h3 style={{fontWeight: 500}}>Banks</h3>
              <p style={{opacity: '0.8', marginTop: '10px'}}>First Bank, Wema Bank, GTB, Kuda MFB, Zenith Bank etc.</p>
            </div>
           
          <div onClick={() => handleSelectedOption('Banks')} style={{cursor: 'pointer'}}>
            { selectedOption === 'Banks' ? 
            <img src="../images/toggleOn.png" alt="" className="nav-ico" /> :
            <img src="../images/toggleOff.png" alt="" className="nav-ico" /> }
          </div> 


          </div>
          <div className="card pay-option">
            <div className="sm-card">
              <h3 style={{fontWeight: 500}}>Banks Transfer</h3>
              <p style={{opacity: '0.8', marginTop: '10px'}}>Enables you to recieve payments via Bank transfers.</p>
            </div>
            
          <div onClick={() => handleSelectedOption('Banks Transfer')} style={{cursor: 'pointer'}}>
            { selectedOption === 'Banks Transfer' ? 
            <img src="../images/toggleOn.png" alt="" className="nav-ico" /> :
            <img src="../images/toggleOff.png" alt="" className="nav-ico" /> }
          </div> 


          </div>
          <div className="card pay-option">
            <div className="sm-card">
              <h3 style={{fontWeight: 500}}>USSD</h3>
              <p style={{opacity: '0.8', marginTop: '10px'}}>First Bank, Wema Bank, GTB, Zenith Bank e.t.c</p>
            </div>
            
          <div onClick={() => handleSelectedOption('USSD')} style={{cursor: 'pointer'}}>
            { selectedOption === 'USSD' ? 
            <img src="../images/toggleOn.png" alt="" className="nav-ico" /> :
            <img src="../images/toggleOff.png" alt="" className="nav-ico" /> }
          </div> 


          </div>
          <h2 style={{textAlign: 'center', fontSize: '28px', fontWeight: 400, margin: '50px 0px 35px'}}>Transaction Receipts</h2>
          <div className="card pay-option">
            <div className="sm-card">
              <h3 style={{fontWeight: 400}}>Send to me</h3>
            </div>

            <div onClick={() => handleReceiptOption('Send to me')} style={{cursor: 'pointer'}}>
              {receiptOption === 'Send to me' ? 
              <img src="../images/toggleOn.png" alt="" className="nav-ico" /> :
            <img src="../images/toggleOff.png" alt="" className="nav-ico" />}
            </div>

          </div>
          <div className="card pay-option">
            <div className="sm-card">
              <h3 style={{fontWeight: 400}}>Send to customers</h3>
            </div>
          
            <div onClick={() => handleReceiptOption('Send to customers')} style={{cursor: 'pointer'}}>
              {receiptOption === 'Send to customers' ? 
              <img src="../images/toggleOn.png" alt="" className="nav-ico" /> :
            <img src="../images/toggleOff.png" alt="" className="nav-ico" />}
            </div>

          </div>
        </div>
      </div>
   </>

)
}
