import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const fetchCustomers = createAsyncThunk("customers/fetchcustomers", async (payloaddata,thunkAPI) => {
  try {

    var url = `/api/v1/customers/${payloaddata?.businessid}/`

    if (payloaddata?.page){
        url = `/api/v1/customers/${payloaddata?.businessid}/?page=${payloaddata?.page}`
    }
  
    const { data } = await axiosInstance.get(url);
    
    
    
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});


const customersSlice = createSlice({
  name: "customers",
  initialState: {
    customers: null,
    loading: false,
  },

  extraReducers: {
    [fetchCustomers.fulfilled]: (state, action) => {
      state.loading = false;
      state.customers = action.payload;
      
    },
    [fetchCustomers.pending]: (state) => {
      state.loading = true;
    },
    [fetchCustomers.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const CustomersState = (state) => state.customers;
export default customersSlice.reducer;
