import React from 'react'

export const UserTable = ({customers}) => {
  return (
    <div className="transac-table">
               <table className="table">
                 <thead>
                   <tr>
                     
                     <th>EMAIL</th>
                     <th>PHONE</th>
                     <th>NAME</th>
                    
                    
                   </tr>
                 </thead>
                 <tbody>

                     {customers?.results?.map((customer)=> <tr key={customer.phone}>
                        <td>{customer.email}</td>
                         <td>{customer.phone}</td>
                         <td>{customer.name}</td>
                      
                         
                   </tr>)}

                   
                   
                   </tbody>
               </table>
             </div>
  )
}
