import React from 'react'
import { BsCheck2Circle, BsClockHistory, BsPlus, BsXCircle } from 'react-icons/bs'

export const CompliceStatus = ({status}) => {

  return (
    <>    {status === "rejected" ?
            <BsXCircle color="#fff" size={20} /> : 
            status === "reviewing" ?
            <BsClockHistory color="#fff" size={20} /> :
            status === "approved" ?
            <BsCheck2Circle color="#fff" size={20} /> :
            <BsPlus color="#ff6A00" size={20} />
          }
    </>
  )
}
