import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import showerror from '../../../components/ErrorToast';
import { LoadingBox } from '../../../components/LoadingBox';
import showsuccess from '../../../components/SuccessToast';
import { getUser, getUserState, updateState } from '../../../redux/authentication/getUserSlice'
import { getUpdateBusinessState, resetState, updatelogo } from '../../../redux/business/updateBusinessLogoSlice';

export const BusinessInfo = () => {
  const { loading,data, status, message } = useSelector(getUpdateBusinessState);
  const dispatch = useDispatch();
  const [fileUpload, setFileUpload] = useState({ file: null, isUploaded: false });
  const [imageSrc, setImageSrc] = useState("");
  const { file, isUploaded } = fileUpload;
    const { activeBusiness }  = useSelector(getUserState)

    const changeBusiness = (data) => {
      dispatch(updateState(data));
      localStorage.setItem("payvessel-activeBusiness", JSON.stringify(data));
    };

  useEffect(() => {
      if (status === "success") {
        
        dispatch(getUser());
        showsuccess(message)
        changeBusiness(data);
        dispatch(resetState());
        
      }
      
    }, [data, dispatch, message, status]);

    
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
     const reader = new FileReader();
     reader.onload = () => {
       const src = reader.result;
       setImageSrc(src);
     };
     reader.readAsDataURL(file);
      
     setFileUpload({ file, isUploaded: true });

     if (!file) {
      showerror("Please upload valid Logo");
      return;
    } else {
      let formData = new FormData();
      formData.append("business_logo", file);
     
      let payloaddata = {
        businessID: activeBusiness?.businessid,
        formData,
      };

      dispatch(updatelogo(payloaddata));
    }
     }

  
  
  return (
    <div className="card profile">
      {loading &&  <LoadingBox/> }
    <div className="setting-profile">
      <div className="info">
        <h2>{activeBusiness?.name}</h2>
        <div className="flex">
       
   <input type="file" name="driver-license" id="driver-upl" accept="image/*"  hidden onChange={handleFileUpload}/>
            
<img src={activeBusiness?.business_logo ? activeBusiness?.business_logo : isUploaded ?  imageSrc :
            "../images/empty-logo.png"} className="empty-logo" alt="" />

          

          <div className="upload">
            <h3>Update Your Business Logo</h3>
            
            <div className="mr-center">
               
                <label  htmlFor="driver-upl" className="btn btn-prm" >
                
                Update Logo
               
                </label>

             
             
            </div>
          </div>
        </div>
      </div>

        <div className="form-flex">

            <div className="edit-profile">
              <label htmlFor="Business Name">Business Name</label>
              <input type="text" name="Business-Name" value={activeBusiness?.name} readOnly />
            </div>

            <div className="edit-profile">
              <label htmlFor="Business Phone Number">Business Phone Number</label>
              <input type="number" name="number" value={activeBusiness?.phone} readOnly />
            </div>
        </div>

        <div className="form-flex">
            <div className="edit-profile">
              <label htmlFor="email">Support Email Address</label>
              <input type="email" name="email" value={activeBusiness?.email} readOnly/>
            </div>
            <div className="edit-profile">
              <label htmlFor="address">Support Phone Number</label>
              <input type="number" name="phone number" value={activeBusiness?.phone} readOnly/>
            </div>

            </div>

            <div className="form-flex">
         
            <div className="edit-profile">
              <label htmlFor="email">Business Industry</label>
              <input type="text" value={activeBusiness?.industry} readOnly />
            </div>
            <div className="edit-profile">
              <label htmlFor="address">Business Address</label>
              <input type="text" value={activeBusiness?.address} readOnly/>
            </div>

            </div>

            <div className="edit-profile">
              <label htmlFor="websiteurl">Business Website</label>
              <input type="url" value={activeBusiness?.websiteurl} readOnly/>
            </div>

            

            <div className="edit-profile">
              <label htmlFor="address">Business ID</label>
              <input type="text" value={activeBusiness?.businessid} readOnly/>
            </div>

            <div className="edit-profile">
              <label htmlFor="address">Business Description</label>
              <input type="text" value={activeBusiness?.description} readOnly/>
            </div>

            
     
        </div>
        {/* <div className="mr-center">
          <input type="submit" value="Save Changes" className="btn btn-secondary btn-lg " />
        </div> */}


  </div>
  )
}
