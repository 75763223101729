import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import showsuccess from "../../components/SuccessToast";
import axiosInstance from "../../utils/useAxios";

export const fetchwebhookevent = createAsyncThunk("webhookevents/fetchwebhookevent", async (payloaddata,thunkAPI) => {
  try {

    var url = `/api/v1/webhook-event/${payloaddata?.businessid}/`

    if (payloaddata?.page){
        url = `/api/v1/webhook-event/${payloaddata?.businessid}/?page=${payloaddata?.page}`
    }
  
    const { data } = await axiosInstance.get(url);
    
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});


export const geteventStatistic = createAsyncThunk("webhookevents/geteventStatistic", async (payloaddata,thunkAPI) => {
  try {

    var url = `/api/v1/webhook-event-statistic/${payloaddata?.businessid}/`

    const { data } = await axiosInstance.get(url);
    
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});



export const sendWebhook = createAsyncThunk("webhookevents/sendwebhook", async (post_data,thunkAPI) => {
  try {
    const { data } = await axiosInstance.post(`/api/v1/webhook-event/${post_data?.businessid}/`,post_data);
    showsuccess("Webhook Notification push succesfully");
    return data;


  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});



const webhookeventSlice = createSlice({
  name: "webhookevents",
  initialState: {
    webhookevents: null,
    selectedtransaction:[],
    loading: false,
    sending: false,
    statistic:null,
  },

  reducers: {

    updateSelectedTransaction: (state,action) => {
      state.selectedtransaction = action.payload;
    },
  
  },

  extraReducers: {
    [fetchwebhookevent.fulfilled]: (state, action) => {
      state.loading = false;
      state.webhookevents = action.payload;
      
    },
    [fetchwebhookevent.pending]: (state) => {
      state.loading = true;
    },
    [fetchwebhookevent.rejected]: (state) => {
      state.loading = false;
    },

    [geteventStatistic.fulfilled]: (state, action) => {
      state.loading = false;
      state.statistic = action.payload;
      
    },
    [geteventStatistic.pending]: (state) => {
      state.loading = true;
    },
    [geteventStatistic.rejected]: (state) => {
      state.loading = false;
    },

    

    [sendWebhook.fulfilled]: (state, action) => {
      state.sending = false;
      state.webhookevents = action.payload;
      state.selectedtransaction=[];

    },
    [sendWebhook.pending]: (state) => {
      state.sending = true;
    },
    [sendWebhook.rejected]: (state) => {
      state.sending = false;
    },
  },
});

export const webhookeventState = (state) => state.webhookevents;
export const { updateSelectedTransaction } = webhookeventSlice.actions;
export default webhookeventSlice.reducer;
