import React from "react";
import { Link } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAuthContext } from "../../../context/authentication/authContext";
import showerror from "../../../components/ErrorToast";

const RegisterFormOne = () => {
  const { formOne, setFormTwo, setFormOne } = useAuthContext();
  const [checked, setChecked] = React.useState(false);
  const formFields = formOne.fields;
  const validationSchema = Yup.object({
    Fname: Yup.string().required("First name is required."),
    Lname: Yup.string().required("Last name is required."),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email address is required"),
    phonenumber: Yup.number()
      .typeError("Invalid phone number")
      .required("Phone number is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Your password should contain uppercase, special character & number"
      ),
    confirmpassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Password does not match"
    ),
  });
  const [showPass, setShowPass] = React.useState(false);
  const [showConfirmPass, setShowConfirmPass] = React.useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
     
      Fname: formFields.Fname,
      Lname: formFields.Lname,
      email: formFields.email,
      phonenumber: formFields.phonenumber,
    },
  });

  
  const onSubmit = (data) => {
    if (!checked) {
      showerror("Kindly accept Payvessel's terms of use.");
      return;
    }
    setFormOne({
      active: false,
      fields: {

        Fname: data.Fname,
        Lname: data.Lname,
        email: data.email,
        phonenumber: data.phonenumber,
        password: data.password,
      },
    });
    setFormTwo((prev) => ({
      ...prev,
      active: true,
    }));
  };

  return (
    <div className="form-container">
      <img
        src="/images/logo-white.png"
        alt=""
        className="form-container-logo"
      />
      <section className="form-card account">
        <form className="create-act" onSubmit={handleSubmit(onSubmit)}>
          <h5>Create Your Payvessel Account</h5>
          <p>Let's onboard you to Greatness</p>

         
          <div className="act-form">
            <input
              placeholder="First Name"
              type="text"
              className={
                errors.Fname ? "form-input form-invalid-input" : "form-input"
              }
              {...register("Fname")}
            />
            <ErrorMessage
              errors={errors}
              name="Fname"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>
          <div className="act-form">
            <input
              placeholder="Last Name"
              type="text"
              className={
                errors.Lname ? "form-input form-invalid-input" : "form-input"
              }
              {...register("Lname")}
            />
            <ErrorMessage
              errors={errors}
              name="Lname"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>
          <div className="act-form">
            <input
              placeholder="E-mail Address"
              type="email"
              className={
                errors.email ? "form-input form-invalid-input" : "form-input"
              }
              {...register("email")}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>
          <div className="act-form ">
            <div className="form-input-flex ">
              <div className="phoneinput__container">
                <div className="country-code">+234</div>
                <input
                  placeholder="Phone Number"
                  type="number"
                  className={
                    errors.phonenumber
                      ? "form-input phoneinput__container-phone form-invalid-input"
                      : "form-input phoneinput__container-phone"
                  }
                  {...register("phonenumber")}
                  min="0"
                />
              </div>
              <ErrorMessage
                errors={errors}
                name="phonenumber"
                render={({ message }) => (
                  <span className="form-invalid-feedback"> {message}</span>
                )}
              />
            </div>
          </div>
          <div className="act-form phoneinput__container">
            <div className="innerPasswordInputcontainer">
              {!showPass ? (
                <AiOutlineEyeInvisible
                  size={22}
                  onClick={() => setShowPass(!showPass)}
                  className={`password__eye ${
                    errors.password && "password__eye-error"
                  }`}
                />
              ) : (
                <AiOutlineEye
                  size={22}
                  onClick={() => setShowPass(!showPass)}
                  className={`password__eye ${
                    errors.password && "password__eye-error"
                  }`}
                />
              )}
              <input
                placeholder="Password"
                type={showPass ? "text" : "password"}
                className={
                  errors.password
                    ? "form-input form-invalid-input"
                    : "form-input"
                }
                {...register("password")}
              />
            </div>
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>
          <div className="act-form phoneinput__container">
            <div className="innerPasswordInputcontainer">
              {!showConfirmPass ? (
                <AiOutlineEyeInvisible
                  size={22}
                  onClick={() => setShowConfirmPass(!showConfirmPass)}
                  className={`password__eye ${
                    errors.confirmpassword && "password__eye-error"
                  }`}
                />
              ) : (
                <AiOutlineEye
                  size={22}
                  onClick={() => setShowConfirmPass(!showConfirmPass)}
                  className={`password__eye ${
                    errors.confirmpassword && "password__eye-error"
                  }`}
                />
              )}
              <input
                placeholder="Confirm Password"
                type={showConfirmPass ? "text" : "password"}
                className={
                  errors.confirmpassword
                    ? "form-input form-invalid-input"
                    : "form-input"
                }
                {...register("confirmpassword")}
              />
            </div>
            <ErrorMessage
              errors={errors}
              name="confirmpassword"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>
          <div className="term-condition-group">
            <div className="checkboxGroup">
              <input
                type="checkbox"
                defaultChecked={checked}
                onChange={() => setChecked(!checked)}
              />
              <p className="term-condition">
                By clicking on the checkbox, you agree to Payvessel's
              </p>
            </div>
            <a className="prm-color" href="https://www.payvessel.com/terms"> Terms of acceptable use.</a>
          </div>
          <button
            type="submit"
            // disabled={!isDirty || !isValid}
            className="btn btn-primary formsubmit__btn"
          >
            Get Started
          </button>
          <p>
            Already have an account?{" "}
            <span className="prm-color">
              <Link to="/">Sign In</Link>
            </span>
          </p>
        </form>
      </section>
      <p
        style={{
          marginTop: "52px",
          color: "white",
        }}
      >
        © payvessel {new Date().getFullYear()}
      </p>
    </div>
  );
};

export default RegisterFormOne;
