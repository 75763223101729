import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserState } from "../redux/authentication/getUserSlice";
import { logoutAction } from "../redux/authentication/userLoginSlice";

export const NavBar = ({ title, icon }) => {
  const { activeBusiness }  = useSelector(getUserState)

  const openNav = () => {
    let mainNav = document.querySelector(".sidebar");
    mainNav.classList.toggle("sidebar-toggle-active");
  };
  const userNav = () => {
    let userNav = document.querySelector(".user-nav");
    userNav.classList.toggle("toggle-filter");
  };




  return (
    <div className="feature-head">
      <div>
        <span className="flex-icon">
          <span id="get-menu" onClick={openNav}>
            <i className="fa fa-bars" />
          </span>
          <img src={`../images/${icon}.png`} className="nav-icon" alt="" />{" "}
          <h3>{title}</h3>
        </span>
      </div>
      <div className="img-bell-name">
        <i className="fa fa-bell" />
        {/* <img src="../images/macy.png" alt="" /> */}

        <img src={activeBusiness?.business_logo ? activeBusiness?.business_logo :
            "../images/empty-logo.png"} className="empty-logo" alt=""  style={{height:"40px",width:"40px"}}/>

        <div className="position-dropdown">
          <p>
          <i className="fas fa-angle-down" onClick={userNav} />
          </p>
          <nav>
            <ul className="user-nav">

<li>
                <Link to="/settings">
                  <i className="fa fa-gear" /> Settings
                </Link>
              </li>
              <li>
                <Link to="#" onClick={logoutAction}>
                  <i className="fa-solid fa-arrow-right-from-bracket" />
                  Log out
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
