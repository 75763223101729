import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import showsuccess from "../../components/SuccessToast";
import axiosInstance from "../../utils/useAxios";



export const resetPasswordOtp = createAsyncThunk("resetPassword/password_reset/", async (post_data,thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(`/api/v1/password-reset/?user_agent=${post_data.user_agent}&service=${post_data.service}&email=${post_data.email}`);
      showsuccess(`${data.message}`);
      return data;
    } catch (error) {
      let errorMsg = "";
  
      if (error.response && error.response.data.error) {
        errorMsg = error.response.data.error[0];
      } else if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.response && error.response.data.detail) {
        errorMsg = error.response.data.detail;
      } else {
        errorMsg = error.message;
      }
      showerror(`Oops 😠,  ${errorMsg}`);
      return thunkAPI.rejectWithValue(errorMsg);
    }
  });



export const resetPasswordsubmit = createAsyncThunk("resetPassword/submit", async (post_data,thunkAPI) => {
  try {
    const { data } = await axiosInstance.post(`/api/v1/password-reset/`, post_data);
    showsuccess(`${data.message}`);
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});
  

const passwordResetSlice = createSlice({
  name: "passordreset",
  initialState: {
    email:"",
    otp:"",
    step: 1,
    newpassword1:"",
    newpassword2:"",
    success:false
  },
  reducers: {
    changeStep: (state,action) => {
      state.step = action.payload;
    },

    resetState: (state,action) => {
      state.email="";
      state.otp="";
      state.step= 1;
      state.newpassword1="";
      state.newpassword2="";
      state.success=false
    },

    updateEmail: (state,action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
  extraReducers: {
    [resetPasswordOtp.fulfilled]: (state, action) => {
      state.loading = false;
      state.step = 2;
      
    },
    [resetPasswordOtp.pending]: (state) => {
      state.loading = true;
    },
    [resetPasswordOtp.rejected]: (state) => {
      state.loading = false;
    },

    [resetPasswordsubmit.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true
      
    },
    [resetPasswordsubmit.pending]: (state) => {
      state.loading = true;
      
    },
    [resetPasswordsubmit.rejected]: (state) => {
      state.loading = false;
    },
  },
});


export const passwordResetState = (state) => state.passordreset;
export const { changeStep,updateEmail,resetState } = passwordResetSlice.actions;
export default passwordResetSlice.reducer;
