import React from "react";
import { createContext } from "react";
import { useContext } from "react";

const authContext = createContext({
  formOne: {
    active: true,
    fields: {
      Fname: "",
      Lname: "",
      email: "",
      phonenumber: "",
      password: "",
    },
  },
  formTwo: {
    active: false,
    account_type: "",
  },
  setFormOne: () => {},
  setFormTwo: () => {},
  formThree: {
    active: false,
    fields: {
      legal_business_name: "",
      business_industry: "",
      state: "",
      lga: "",
      street_address: "",
    },
  },
  setFormThree: () => {},
});

const AuthContextProvider = ({ children }) => {
  // INITIAL FORM
  const [formOne, setFormOne] = React.useState({
    active: true,
    fields: {
      username: "",
      Fname: "",
      Lname: "",
      email: "",
      phonenumber: "",
      password: "",
    },
  });
  // ACCOUNT TYPE
  const [formTwo, setFormTwo] = React.useState({
    active: false,
    account_type: "",
  });

  // ABOUT YOURSELF
  const [formThree, setFormThree] = React.useState({
    active: false,
    fields: {
      legal_business_name: "",
      business_description: "",
      business_industry: "",
      state: "",
      lga: "",
      street_address: "",
    },
  });
  return (
    <authContext.Provider
      value={{
        formOne,
        setFormOne,
        formTwo,
        setFormTwo,
        formThree,
        setFormThree,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export const useAuthContext = () => useContext(authContext);

export default AuthContextProvider;
