import React from "react";
import { useAuthContext } from "../../context/authentication/authContext";
import RegisterFormOne from "./components/RegisterFormOne";
import RegisterFormTwo from "./components/RegisterFormTwo";
import RegisterFormThree from "./components/RegisterFormThree";

export const RegisterScreen = () => {
  const { formOne, formTwo, formThree } = useAuthContext();

  if (formOne.active) return <RegisterFormOne />;
  if (formTwo.active) return <RegisterFormTwo />;
  if (formThree.active) return <RegisterFormThree />;
};
