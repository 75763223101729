import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Base } from '../../components/Base'
import showerror from '../../components/ErrorToast';
import { LoadingBox } from '../../components/LoadingBox';
import { getUserState } from '../../redux/authentication/getUserSlice';
import { getBusiness, getBusinessState } from '../../redux/dashboard/getBusinessSlice';
import { getOTP, getOtpState } from '../../redux/otp/otpSlice';
import { BankAccountState, fetchPayoutAccount } from '../../redux/settings/bankSlice';
import { updateAmount, withdraw, withdrawState } from '../../redux/withdraw/withdrawSlice';
import { formatMoney } from '../../utils/FormatMoney';
import { SuccessModal } from './components/SuccessModal';



export const WithdrawScreen = () => {

    const {bankName,accountNumber, accountName,bankCode} = useSelector(BankAccountState);
    
    const { loading,amount,success,otp} = useSelector(withdrawState);
    const { loading:getOtpLoading} = useSelector(getOtpState);
  
    const dispatch = useDispatch();
    const { activeBusiness }  = useSelector(getUserState)
    const { businessdata }  = useSelector(getBusinessState)
    const navigate = useNavigate();
  
    useEffect(() => {
      if(activeBusiness?.businessid){
      dispatch(fetchPayoutAccount(activeBusiness?.businessid));
      dispatch(getBusiness(activeBusiness.businessid));
      
    }
    }, [activeBusiness,dispatch, ]);

    useEffect(() => {
      if(!activeBusiness?.verify){
        Swal.fire({
          text: "You can not perform this action ,please verify your business first",
          icon: 'error',
        })
        navigate("/dashboard");
        
      }
    }, [activeBusiness, navigate])
    


    const submitHandler = (e) => {
      e.preventDefault()
      if (!accountNumber) {
        showerror("account number is required")
        return
      }
      else if (!accountName) {
        showerror("account name is required")
        return
      }
  
      else if (!bankCode) {
        showerror("bank code is required")
        return
      }
  
      else if (!otp) {
        showerror("OTP code is required")
        return
      }
  
      else if (!bankName) {
        showerror("bank name is required")
        return
      }
      else if (!amount) {
        showerror("bank name is required")
        return
      }

      else if ( amount < 500) {
        showerror("Minimum payout is N500")
        return
      }

      else if ( Number(amount) > businessdata?.wallet_balance) {
        showerror("insufficient balance")
        return
      }
      else {
        const user_agent = navigator.userAgent;
        const  service =  "Withdraw Request";
        const data = {accountNumber,accountName,amount,bankCode,bankName,otp,user_agent,service }
        const payloaddata = { businessID: activeBusiness?.businessid,data}
        dispatch(withdraw(payloaddata))}
    }

    const getOtphandler = ()=>{
      const payloaddata = {
          user_agent:navigator.userAgent,
          service: "Withdraw Request"
      }
        dispatch(getOTP(payloaddata))
  
  }


    const handlechange = (e) => {
        const { name, value } = e.target;
        dispatch(updateAmount({ name, value }));
    
      }


  return (
<Base title={""} icon={"dashboard_"}>

  <>
 

   <div className="card profile">
    {loading &&  <LoadingBox/> }
      <div className="setting-profile">
        <h2>Withdraw</h2>

    
    <form  onSubmit={submitHandler}>
              
           
    <div className="edit-profile">
                  <label htmlFor="acct-number">Bank Name</label>
                  <input type="text" name="bankName" value={bankName} disabled />
                </div>
                <div className="edit-profile">
                  <label htmlFor="acct-number">Account Number</label>
                  <input type="number" name="accountNumber" maxlength="10" value={accountNumber} disabled  />
                </div>
             
             <div className="edit-profile">
                  <label htmlFor="acct-name">Account Name</label>
                  <input type="text" name="accountName" value={accountName} disabled/>
                </div>

                <div className="edit-profile">
                  <div className='label-flex'>
                  <label htmlFor="acct-name">Amount </label>
                  <span>₦{formatMoney(businessdata?.wallet_balance)}</span>
                  </div>
                  <input type="number" name="amount" value={amount}  onChange={handlechange} required/>
                </div>

                {/* onClick={getOtphandler} */}

           <div className="edit-profile">
             <label htmlFor="acct-name">OTP </label>

            <div class="div-otp-flex">
            <input type="text"  name = "otp" value={otp} onChange={handlechange} />
            <button type="button" className="btn btn-secondary"  
            disabled={getOtpLoading ? true : false} onClick={getOtphandler}
            >
              {getOtpLoading ? (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              ) : (
                "Get OTP"
              )}

            </button>

            </div> </div>

                <div className="">
                  <button type="submit" disabled ={amount && otp?.length === 6 ? false : true} className="btn btn-full  btn-secondary">Withdraw</button>
                </div>
              </form>
            </div>
            </div>

            {success && <SuccessModal  />}

  
   </>
   
</Base>
)
}
