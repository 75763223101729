import { getComplianceState, resetComplianceState, uploadData, uploadDocument } from "../../../redux/kyc/getcomplianceSlice";
import { getUserState } from "../../../redux/authentication/getUserSlice";
import { hideBVNModal } from "../../../redux/kyc/generalSlice";
import showerror from "../../../components/ErrorToast";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineClose } from "react-icons/md";
import { useState, useEffect } from "react";
import { BackDrop } from "../../../components/BackDrop";

export const BVNModal = () => {
  const [inputValue, setInputValue] = useState({ bvn: "", dob: "" });
  const [isDisabled, setIsDisabled] = useState(true);
  const { activeBusiness } = useSelector(getUserState);
  const { uploadloading, success } = useSelector(getComplianceState);
  const dispatch = useDispatch();
  const { bvn, dob } = inputValue;

  const onInputBVNChange = (evt) => {
    setInputValue((prev) => ({ bvn: evt.target.value, dob: prev.dob }));
  };

  const onInputDOBChange = (evt) => {
    console.log(evt.target.value);
    setInputValue((prev) => ({ bvn: prev.bvn, dob: evt.target.value }));
  };

  const handlesubmit = () => {
    if (!bvn || bvn === "") {
      showerror("BVN is required");
      return;
    } else if (bvn.length !== 11) {
      showerror("Invalid BVN! Must be 11 digits");
      return;
    } else if (!dob || dob === "") {
      showerror("DOB is required");
      return;
    } else {
      let data = {
        bvn,
        dob,
        action: "BVN_VERIFICATION",
      };
      let payloaddata = {
        businessID: activeBusiness?.businessid,
        data,
      };

      dispatch(uploadData(payloaddata));
    }
  };

  useEffect(() => {
    if (success === true) {
      dispatch(resetComplianceState())
      dispatch(hideBVNModal());
    }
    setIsDisabled(bvn === "" || dob === "");
  }, [bvn, dob, success, dispatch, inputValue]);

  return (
    <BackDrop>
      <div className="modal">
        <div className="header">
          <div className="close-icon" onClick={() => dispatch(hideBVNModal())}>
            <MdOutlineClose size={20} color="#fff" />
          </div>
        </div>

        <div className="edit-profile">
                  <label htmlFor="acct-name">Bank Verification Number (BVN)</label>
                  <input  id="bvn"
            value={bvn}
            className="form-input"
            type="number"
            placeholder="Enter BVN (Compulsory)"
            onChange={onInputBVNChange}/>
                </div>

                <div className="edit-profile">
                  <label htmlFor="acct-name">Date of Birth</label>
                  <input  id="dob"
            value={dob}
            className="form-input"
            type="date"
            placeholder="DOB"
            onChange={onInputDOBChange}/>
                </div>


          <div className="btn-bvn-submit">
            {uploadloading ? (
              <button className="btn btn-prm" disabled={true}>
                {" "}
                <i
                  className="fa fa-spinner fa-spin"
                  aria-hidden="true"
                ></i>{" "}
                Please wait ...
              </button>
            ) : (
              <button
                className="btn btn-prm"
                disabled={isDisabled}
                onClick={() => handlesubmit()}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      
    </BackDrop>
  );
};
