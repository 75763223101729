
import { toast } from 'react-toastify';


const showsuccess = (msg) => {

    toast.success(`  ${msg}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        limit: '5'
    });
}

export default showsuccess;