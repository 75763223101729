import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const querycustomers = createAsyncThunk("querycustomers/querycustomers", async (payloaddata,thunkAPI) => {

  try {
    var url = `/api/v1/customers/${payloaddata?.businessid}/?query=${payloaddata?.query}`
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});


const querycustomersSlice = createSlice({
  name: "querycustomers",
  initialState: {
    querycustomerslist: null,
    query:"",
    queryloading: false,
  },

  reducers: {
    resetQuery: (state) => {
      state.querycustomerslist = null;
      state.query= "";
      state.queryloading = false;
    },
    setQuery: (state,action) => {
      state.query= action.payload;
     
    },
  },

  extraReducers: {
    [querycustomers.fulfilled]: (state, action) => {
      state.queryloading = false;
      state.querycustomerslist = action.payload;
      
    },
    [querycustomers.pending]: (state) => {
      state.queryloading = true;
    },
    [querycustomers.rejected]: (state) => {
      state.queryloading = false;
    },
  },
});

export const querycustomersState = (state) => state.querycustomers;
export default querycustomersSlice.reducer;
export const { resetQuery,setQuery } = querycustomersSlice.actions;


