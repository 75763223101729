import { getComplianceState, resetComplianceState, uploadDocument } from "../../../redux/kyc/getcomplianceSlice";
import { getUserState } from "../../../redux/authentication/getUserSlice";
import { hideVideoModal } from "../../../redux/kyc/generalSlice";
import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import showerror from "../../../components/ErrorToast";
import { useSpeechSynthesis } from "react-speech-kit";
import { MdOutlineClose } from "react-icons/md";
import { BackDrop } from "../../../components/BackDrop";
import { randomWords } from "./data";

export const VideoModal = () => {
  const { uploadloading, success } = useSelector(getComplianceState);
  const [display, setDisplay] = useState({ v1: "none", v2: "none" });
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [showOtherBtn, setShowOtherBtn] = useState(false);
  const video = document.getElementById("recorded-video");
  const [videoDuration, setVideoDuration] = useState(0);
  const [securityWord, setSecurityWord] = useState("");
  const { activeBusiness } = useSelector(getUserState);
  const [recording, setRecording] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [delayTime, setDelayTime] = useState(0);
  const { cancel, speak } = useSpeechSynthesis();
  const mediaStreamRef = useRef(null);
  const dispatch = useDispatch();
  const { v1, v2 } = display;
  const videoRef = useRef();

  const lauchCamera = () => {
    // Get access to the user's camera
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        let video = videoRef.current;
        mediaStreamRef.current = stream;
        if (video) {
          video.srcObject = stream;
          video.volume = 0;
          // video.type = "video/mp4"
          video.play();
        }
      })
      .then(() => {
        startRecording(mediaStreamRef.current);
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });
  };

  useEffect(() => {
    const randomIdx = Math.floor(Math.random() * randomWords.length);
    const randWord = randomWords[randomIdx];
    setSecurityWord(randWord);
  }, []);

  useEffect(() => {
    if (securityWord ) {
      speak({
        text: `Please record a 5 seconds video where you say ${securityWord} to complete your K.Y.C process.`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityWord]);

  useEffect(() => {
    let intervalId;
    if (delayTime > 0) {
      intervalId = setTimeout(() => {
        setDelayTime((prevDelayTime) => prevDelayTime - 1);
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [delayTime]);

  useEffect(() => {
    let intervalId;
    if (recording) {
      intervalId = setInterval(() => {
        setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [recording]);

  const startRecording = (stream) => {
    setDelayTime(5);
    setDisplay((prev) => ({ v1: prev.v1, v2: "flex" }));

    setTimeout(() => {
      try {
        var option = { mimeType: "video/webm" };

        if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
        
          option = {mimeType: 'video/webm; codecs=vp9'};
        } else  if (MediaRecorder.isTypeSupported('video/webm')) {
          
          option = {mimeType: 'video/webm'};
        } else if (MediaRecorder.isTypeSupported('video/mp4')) {
          option = {mimeType: 'video/mp4', videoBitsPerSecond : 100000};
        } else {
            console.error("no suitable mimetype found for this device");
        }
        const recorder = new MediaRecorder(stream, option);
        const chunks = [];

        recorder.ondataavailable = (event) => {
          chunks.push(event.data);
        };

        recorder.onstop = () => {
          const videoBlob = new Blob(chunks, { type: "video/mp4" });

          if (video) {
            video.src = URL.createObjectURL(videoBlob);
            setDisplay((prev) => ({ v1: "flex", v2: prev.v2 }));
          }

          setRecording(false);
          setShowOtherBtn(true);
          setElapsedTime(0);
          closeCamera();
        };

        setMediaRecorder(recorder);
        setRecording(true);
        recorder.start();
      } catch (err) {
        console.log(err);
        showerror(err);
      }
    }, 5000);
  };

  useEffect(() => {
    if (elapsedTime >= 5 && recording) {
      mediaRecorder.stop();
    }
  }, [elapsedTime, mediaRecorder, recording]);

  const closeCamera = useCallback(() => {
    const mediaStream = mediaStreamRef.current;
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      mediaStreamRef.current = null;
    }
  }, []);

  useEffect(() => {
    return () => {
      closeCamera();
    };
  }, [closeCamera]);

  const stopRecording = useCallback(() => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
    }
  }, [mediaRecorder]);

  const closeModal = useCallback(() => {
    cancel();
    closeCamera();
    stopRecording();
    dispatch(hideVideoModal());
  }, [cancel, dispatch, closeCamera, stopRecording]);

  useEffect(() => {
    if (success === true) {
      dispatch(resetComplianceState())
      closeModal();
    }
  }, [closeModal, dispatch, success]);

  useEffect(() => {
    if (video) {
      const handleMetadataLoaded = () => {
        if (video.duration !== Infinity) {
          setVideoDuration(video.duration);
        }
      };

      const handleDurationChange = () => {
        if (video.duration !== Infinity) {
          setVideoDuration(video.duration);
        }
      };

      video.addEventListener("loadedmetadata", handleMetadataLoaded);
      video.addEventListener("durationchange", handleDurationChange);

      return () => {
        video.removeEventListener("loadedmetadata", handleMetadataLoaded);
        video.removeEventListener("durationchange", handleDurationChange);
      };
    }
  }, [video]);

  const onClickRetakeBtn = () => {
    lauchCamera();
    setShowOtherBtn(false);
    setDisplay({ v1: "none", v2: "flex" });
  };

   const disableOtherBtn = Math.round(videoDuration) > 5 || videoDuration === 0;
//  const  disableOtherBtn = false;
  // alert(videoDuration)

  const uploadVideo = () => {

    if (Math.round(videoDuration) > 5 || videoDuration === 0) {
      showerror("Video length can't be more than 5s or 0s");
      return;
    }

    if (video) {
      const videoUrl = video.src;

      fetch(videoUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const videoFile = new File([blob], `${securityWord}.mp4`);

          let formData = new FormData();
          formData.append("video_file", videoFile);
          formData.append("security_word", securityWord);
          formData.append("action", "SHORT_VIDEO");

          let payloaddata = {
            businessID: activeBusiness?.businessid,
            formData,
          };

          dispatch(uploadDocument(payloaddata));
        })
        .catch((error) => {
          showerror(error);
        });
    }
  };

  return (
    <BackDrop>
      <div className="modal">
        <div className="header">
          <div className="close-icon" onClick={closeModal}>
            <MdOutlineClose size={20} color="#fff" />
          </div>
        </div>
        <div className="body-video">
          <p >Record A Liveliness Video</p>
          <div className="video-icon">
            <video
              id="recorded-video"
              controls
              autoPlay
              playsInline={true}
              style={{ display: `${v1}` }}
            />

            {v1 === "none" && (
              <video
                className="live-display"
                autoPlay={true}
                playsInline={true}
                muted={true}
                ref={videoRef}
                style={{ borderRadius: "10px", display: `${v2}` }}
              />
            )}

            {v1 === "none" && v2 === "none" && (
              <svg
                height={100}
                width={150}
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="video"
                className="svg-inline--fa fa-video fa-7x "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"
                ></path>
              </svg>
            )}

            {delayTime > 0 && (
              <p className="delayTime">
                {" "}
                Please Wait... Recording Starts In 0.0{delayTime}{" "}
              </p>
            )}

            {recording && (
              <p className="activeRecording">
                Recording Started 0.0{elapsedTime}
              </p>
            )}
          </div>
          <div className="instruct">
            <h5>
              Please record a 5 seconds video where you say{" "}
              <strong className="word">{securityWord}</strong> to complete your
              KYC process.
            </h5>
          </div>

          {v1 === "none" && v2 === "none" && (
            <button className="btn btn-record-video" onClick={lauchCamera}>
              Record Video
            </button>
          )}

          {showOtherBtn && (
            <div className="btn-retake-wrapper">
              <button
                className="btn btn-prm"
                onClick={onClickRetakeBtn}
                disabled={uploadloading || disableOtherBtn ? true : false}
              >
                Retake Video
              </button>
              {uploadloading ? (
                <button className="btn btn-prm" disabled={true}>
                  {" "}
                  <i
                    className="fa fa-spinner fa-spin"
                    aria-hidden="true"
                  ></i>{" "}
                  Please wait ...
                </button>
              ) : (
                <button
                  className="btn btn-prm"
                  // disabled={disableOtherBtn}
                  onClick={uploadVideo}
                >
                  Upload
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </BackDrop>
  );
};
