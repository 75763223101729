import { configureStore } from "@reduxjs/toolkit";
import getUserSlice from "../authentication/getUserSlice";
import generalSlice from "../kyc/generalSlice";
import userLoginSlice from "../authentication/userLoginSlice";
import getBusinessSlice from "../dashboard/getBusinessSlice";
import userRegisterSlice from "../authentication/userRegisterSlice";
import getTransactionsSlice from "../transactions/getTransactionsSlice";
import getTransactionsChartSlice from "../transactions/getTransactionsChartSlice";
import getcomplianceSlice from "../kyc/getcomplianceSlice";
import apikeysSlice from "../settings/apikeysSlice";
import webhookeventSlice from "../webhookEvents/webhookeventSlice";
import virtualAccountSlice from "../virtualAccount/virtualAccountSlice";
import customersSlice from "../customers/customersSlice";
import webhookquerySlice from "../webhookEvents/webhookquerySlice";
import querycustomersSlice from "../customers/querycustomersSlice";
import queryvirtualAccountSlice from "../virtualAccount/queryvirtualAccountSlice";
import querytransactionSlice from "../transactions/querytransactionSlice";
import filtertransactionSlice from "../transactions/filtertransactionSlice";
import newBusinessSlice from "../business/newBusinessSlice";
import updateBusinessLogoSlice from "../business/updateBusinessLogoSlice";
import BankSlice from "../settings/bankSlice";
import otpSlice from "../otp/otpSlice";
import webhookSlice from "../settings/webhookSlice";
import withdrawSlice from "../withdraw/withdrawSlice";
import getVirtAccTransactionsSlice from "../transactions/getVirtAccTransactionsSlice";
import queryVirtAccTransactionSlice from "../transactions/queryVirtAccTransactionSlice";
import filterVirtAccTransactionSlice from "../transactions/filterVirtAccTransactionSlice";
import passwordResetSlice from "../authentication/passwordResetSlice";
import webhookfilterSlice from "../webhookEvents/webhookfilterSlice";

const store = configureStore({
  reducer: {
    userRegister: userRegisterSlice,
    userlogin: userLoginSlice,
    user: getUserSlice,
    business: getBusinessSlice,
    compliance: getcomplianceSlice,
    transactions: getTransactionsSlice,
    querytransactions:querytransactionSlice,
    filtertransactions:filtertransactionSlice,
    transactions_chart: getTransactionsChartSlice,
    virtualacctransactions:getVirtAccTransactionsSlice,
    queryvirtacctransactions:queryVirtAccTransactionSlice,
    filtervirtacctransactions:filterVirtAccTransactionSlice,
    general: generalSlice,
    apikeys:apikeysSlice,
    webhookevents:webhookeventSlice,
    customers:customersSlice,
    virtualaccount:virtualAccountSlice,
    webhookquerys:webhookquerySlice,
    webhookFilters:webhookfilterSlice,
    querycustomers:querycustomersSlice,
    querycvirtualaccount:queryvirtualAccountSlice,
    newbussiness:newBusinessSlice,
    updatebussiness:updateBusinessLogoSlice,
    bank_account:BankSlice,
    otp:otpSlice,
    webhook:webhookSlice,
    withdraw:withdrawSlice,
    passordreset:passwordResetSlice
    

  },
});

export default store;
