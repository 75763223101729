import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/styles.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import store from "./redux/app/configureStore";
import AuthContextProvider from "./context/authentication/authContext";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AuthContextProvider>
      <React.StrictMode>
        <BrowserRouter>
          <App />
          {/* <TawkMessengerReact
                propertyId="64e2400d94cf5d49dc6b7984"
                widgetId="1h89skdnn"/> */}
          <ToastContainer />
        </BrowserRouter>
      </React.StrictMode>
    </AuthContextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
