import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const getTransactions = createAsyncThunk("transactions/gettransactions", async (payloaddata,thunkAPI) => {
  try {

    var url = `/api/v1/transactions/${payloaddata?.businessid}/`
    if (payloaddata?.page){
        url = `/api/v1/transactions/${payloaddata?.businessid}/?page=${payloaddata?.page}`
    }
    const { data } = await axiosInstance.get(url);
    
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});

const getTransactionsSlice = createSlice({
  name: "transactions",
  initialState: {
    transactions: [],
    transactions_loading: false,
    
  },
 
  extraReducers: {
    [getTransactions.fulfilled]: (state, action) => {
      state.transactions_loading = false;
      state.transactions = action.payload;
      
    },
    [getTransactions.pending]: (state) => {
      state.transactions_loading = true;
    },
    [getTransactions.rejected]: (state) => {
      state.transactions_loading = false;
    },
  },
});

export const getTransactionsState = (state) => state.transactions;
export default getTransactionsSlice.reducer;
