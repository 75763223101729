import React from "react";
import { accountTypeOptions } from "../../../data/accountTypes";
import { useAuthContext } from "../../../context/authentication/authContext";
import showerror from "../../../components/ErrorToast";

const RegisterFormTwo = () => {
  const { formTwo, setFormTwo, setFormOne, setFormThree } = useAuthContext();
  const [account_type, setAccount_type] = React.useState(
    formTwo.account_type === "" ? "" : formTwo.account_type
  );

  const selectAccountType = (e) => {
    setAccount_type(e.target.value);
  };

  const goBack = () => {
    setFormOne((prev) => ({
      ...prev,
      active: true,
    }));
    setFormTwo((prev) => ({
      ...prev,
      active: false,
    }));
  };

  const nextForm = () => {
    if (account_type === "") {
      showerror("Kindly select an account type");
      return;
    }
    setFormTwo({
      active: false,
      account_type,
    });

    setFormThree((prev) => ({
      ...prev,
      active: true,
    }));
  };
  return (
    <div className="form-container">
      <img
        src="/images/logo-white.png"
        alt=""
        className="form-container-logo"
      />
      <section className="form-card account">
        <div className="create-act">
          <h4 className="formtwo__title">CHOOSE ACCOUNT TYPE</h4>

          {accountTypeOptions.map((accountType, index) => (
            <div key={index} className="accounttype">
              <div
                className={`accounttype__container ${
                  account_type === accountType.option && "container__border"
                } `}
              >
                <div className="accounttype__heading">
                  <input
                    type="radio"
                    name="accountType"
                    value={accountType.option}
                    onChange={selectAccountType}
                    checked={account_type === accountType.option}
                  />
                  <label htmlFor="">{accountType.name}</label>
                </div>
                <div
                  className={`accounttype__content  ${
                    account_type === accountType.option && "open__container"
                  } `}
                >
                  <p className="accounttype__content-title">What you’ll get:</p>
                  {accountType.offers.map((offer, index) => (
                    <div key={index} className="accounttype__description">
                      <div className="accounttype__offers">
                        <img
                          src="/images/check-green.svg"
                          alt="check-green"
                          className="accounttype__offers-img"
                        />
                        <p className="accounttype__offers-title">
                          {offer.title}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          <div className="button__group">
            <button
              type="submit"
              onClick={goBack}
              className="btn btn-secondary formsubmit__btn"
            >
              Back
            </button>
            <button
              type="submit"
              onClick={nextForm}
              className="btn btn-primary formsubmit__btn"
            >
              Continue
            </button>
          </div>
        </div>
      </section>
      <p
        style={{
          marginTop: "52px",
          color: "white",
        }}
      >
        © payvessel {new Date().getFullYear()}
      </p>
    </div>
  );
};

export default RegisterFormTwo;
