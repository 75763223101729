import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Base } from '../../components/Base'
import { LoadingBox } from '../../components/LoadingBox';
import { getUserState } from '../../redux/authentication/getUserSlice';
import { querycvirtualaccount, querycvirtualaccountState, resetQuery, setQuery } from '../../redux/virtualAccount/queryvirtualAccountSlice';
import { fetchvirtualaccount, virtualaccountState } from '../../redux/virtualAccount/virtualAccountSlice';
import { Pagination } from './components/Pagination';
import { VirtualaccountTable } from './components/VirtualaccountTable';

export const VirtualAccountScreen = () => {

    const dispatch = useDispatch();
    const { activeBusiness }  = useSelector(getUserState)
    const { virtualaccount,loading }  = useSelector(virtualaccountState)
    const { querycvirtualaccountlist,queryloading,query }  = useSelector(querycvirtualaccountState)
    
   
    useEffect(() => {
  
      dispatch(fetchvirtualaccount({"businessid":activeBusiness?.businessid,"page":null}))
      
    }, [activeBusiness,dispatch]);


    const goto =(page)=>{
      dispatch(fetchvirtualaccount({"businessid":activeBusiness?.businessid,"page":page}))
  }

  const search = (event)=>{
    const query = event.target.value
    dispatch(setQuery(query)) 
    
    if (query?.length > 0){
      dispatch(querycvirtualaccount({"businessid":activeBusiness?.businessid,"query":query}))
    }
    else{
      dispatch(resetQuery()) 
    }
  }
  return (
    <Base title={"Virtual Account"} icon={"dashboard_"}>

    <>
    <div>
         <div className="transaction-table">
             
           <div className="card">
           {loading && <LoadingBox/>}
           {queryloading && <LoadingBox/>}


           <form action>
              <div className="p-relative">
            
              <input type="text" value={query} onChange={search} className="transac-search" placeholder="Search" />
              {query?.length > 0 &&  <span className="form-input-append">
                <i className= "fa fa-times" onClick={()=>dispatch(resetQuery()) }></i>
              </span>}
             
              </div>
            </form>


            
             {query?.length > 0 ? querycvirtualaccountlist?.results?.length > 0 ?
                <VirtualaccountTable  virtualaccount={querycvirtualaccountlist} />: <div class="emtyTransactions">

                <img src={`../images/empty.png`} className="empty" alt="empty " />

                <p>Your query did not match any records.</p>

                </div>:
                <VirtualaccountTable  virtualaccount={virtualaccount} />
               
              }
           </div>
         </div>
        
        
         {query?.length > 0 ? querycvirtualaccountlist?.results?.length > 0 ?
              <Pagination  virtualaccount={querycvirtualaccountlist}  goto={goto}/>:<></>:
              virtualaccount?.results?.length > 0 &&  <Pagination  virtualaccount={virtualaccount}  goto={goto}/>
               
              }

       </div>
    </>
    
 </Base>
  )
}
