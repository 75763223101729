import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { login } from "../../redux/authentication/userLoginSlice";
// import { Turnstile } from '@marsidev/react-turnstile'


export const LoginScreen = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { userInfo, loading } = useSelector((state) => state.userlogin);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (data) => {
    dispatch(login({...data,userAgent:navigator.userAgent}));
  };

  useEffect(() => {
    if (userInfo?.access) {
      navigate("/dashboard");
      
    }
  }, [navigate, userInfo]);

  return (
    <div className="form-container">
      <img
        src="/images/logo-white.png"
        alt=""
        className="form-container-logo"
      />

      <section className="form-card account">
        <form
          onSubmit={handleSubmit(submitHandler)}
          className="create-act"
          noValidate
        >
          <h5>Login to Your Account</h5>
          <div className="act-form">
            <label className="form-input-label ">Email Address</label>
            <input
              type="email"
              className={
                errors.email ? "form-input form-invalid-input" : "form-input"
              }
              {...register("email", { required: "Email is required" })}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>
          <div className="act-form">
            <label className="form-input-label ">Password</label>
            <div className="p-relative">
              <input
                type={passwordShown ? "text" : "password"}
                className={
                  errors.password
                    ? "form-input form-invalid-input"
                    : "form-input"
                }
                {...register("password", { required: "Password is required." })}
              />
              <span
                className="form-input-append"
                onClick={() => setPasswordShown(!passwordShown)}
              >
                <i
                  className={passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}
                ></i>
              </span>
            </div>
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <span className="form-invalid-feedback"> {message}</span>
              )}
            />
          </div>

          
          <button
            type="submit"
            className="btn btn-primary"
            disabled={loading ? true : false}
          >
            {loading ? (
              <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
            ) : (
              "Login"
            )}
          </button>

          <p style={{ marginBottom: "30px" }} >
            <span className=""> <Link className="dark-color" to="/forgot-password"> Forgot Password?</Link></span>
          </p>
          <p>
            New to Payvessel ?{" "}
            <span className="prm-color">
              <Link to="/register">Create an Account</Link>
            </span>
          </p>
        </form>
      </section>
      <p
        style={{
          marginTop: "auto",
          color: "white",
        }}
      >
        © payvessel {new Date().getFullYear()}
      </p>
    </div>
  );
};
