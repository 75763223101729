import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { LoadingBox } from '../../../components/LoadingBox';
import { getUserState } from '../../../redux/authentication/getUserSlice';
import { getWebhook, updateWebhook, updateWebhookurl, WebhookState } from '../../../redux/settings/webhookSlice';

export const Webhook = () => {

  const { loading,webhook_url }  = useSelector(WebhookState)
  const { activeBusiness, }  = useSelector(getUserState)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWebhook(activeBusiness?.businessid))

  }, [activeBusiness, dispatch])
  

  const handlechange = (e) => {
    const { name, value } = e.target;
    dispatch(updateWebhookurl({ name, value }));

  }


const handleSubmit = (e) =>{
  e.preventDefault()
 const  payload = {
  businessID:activeBusiness?.businessid,
  data :{webhook_url}
  }
  dispatch(updateWebhook(payload))

}

  return (
    <div className="card profile">
      {loading && <LoadingBox/>}


    <div className="setting-profile">
      <h2>Webhook</h2>
      
      <form onSubmit={handleSubmit}>

      <div className="edit-profile">
            <label htmlFor="firstname">Webhook Url </label>
            <input type="url" name="webhook_url" value={webhook_url ? webhook_url : activeBusiness.websiteurl}  onChange={handlechange} required/>
          </div>

         
          <button type="submit"  className="btn btn-full  btn-secondary">Save</button>
      

          
      </form>

    </div>
  </div>
  )
}
