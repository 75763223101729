import { useSelector } from "react-redux";
import { getUserState } from "../../../redux/authentication/getUserSlice";


export const Profile = ({onNext}) => {

  const { activeBusiness }  = useSelector(getUserState)
  return (
    <div className="profile">
    
      <div className="title">
        <p>Business Owner</p>
        <h4>{activeBusiness?.owner}</h4>
      </div>
      <div className="title">
        <p>Legal Business Name</p>
        <h4>{activeBusiness?.name}</h4>
      </div>
      <div className="title">
        <p>Business Industry</p>
        <h4>{activeBusiness?.industry}</h4>
      </div>

      <div className="title">
        <p>Business Description</p>
        <h4>{activeBusiness?.description}</h4>
      </div>
      <div className="nextBtn">
        <button onClick={onNext}>Next</button>
      </div>
    </div>
  );
};
