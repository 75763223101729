import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const registerUser = createAsyncThunk(
  "userRegister/register",
  async (authdata, thunkAPI) => {
    try {
      const { data } = await axiosInstance.post(
        `/api/v1/registration/`,
        authdata
      );
      return data;
    } catch (error) {

      console.log(error)
      let errorMsg = "";

      if (error.response && error.response.data.error) {
        errorMsg = error.response.data.error[0];
      } else if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.response && error.response.data.detail) {
        errorMsg = error.response.data.detail;
      } else {
        errorMsg = error.message;
      }
      showerror(`Oops 😠,  ${errorMsg}`);
      return thunkAPI.rejectWithValue(errorMsg);
    }
  }
);

const userRegisterSlice = createSlice({
  name: "userRegister",
  initialState: {
    loading: false,
    message: "",
    error: null,
    status: "idle",
  },
  reducers: {
    resetState: (state) => {
      state.error = null;
      state.status = "idle";
    },
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.status = "loading";
    },
    [registerUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      state.status = "success";
    },
    [registerUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.status = "failed";
    },
  },
});

export const getRegisterState = (state) => state.userRegister;
export const { resetState } = userRegisterSlice.actions;
export default userRegisterSlice.reducer;
