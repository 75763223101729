import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const getTransactionsChart = createAsyncThunk("transactionschart/gettransactionschart", async (businessID,thunkAPI) => {
  try {
     
    const { data } = await axiosInstance.get(`/api/v1/transactions_chart/${businessID}/`);
    
    return data;
  } catch (error) {
    let errorMsg = "";

    if (error.response && error.response.data.error) {
      errorMsg = error.response.data.error[0];
    } else if (error.response && error.response.data.message) {
      errorMsg = error.response.data.message;
    } else if (error.response && error.response.data.detail) {
      errorMsg = error.response.data.detail;
    } else {
      errorMsg = error.message;
    }
    showerror(`Oops 😠,  ${errorMsg}`);
    return thunkAPI.rejectWithValue(errorMsg);
  }
});



const getTransactionsChartSlice = createSlice({
  name: "transactionschart",
  initialState: {
    transactions_chart: [],
    transactions_chart_loading: false, 
  },
  extraReducers: {
    [getTransactionsChart.fulfilled]: (state, action) => {
      state.transactions_chart_loading = false;
      state.transactions_chart = action.payload;
      
    },
    [getTransactionsChart.pending]: (state) => {
      state.transactions_chart_loading = true;
    },
    [getTransactionsChart.rejected]: (state) => {
      state.transactions_chart_loading = false;
    },
  },
});


export const getTransactionsChartState = (state) => state.transactions_chart;
export default getTransactionsChartSlice.reducer;
