import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import showerror from "../../components/ErrorToast";
import axiosInstance from "../../utils/useAxios";

export const filterVirtAccTransaction = createAsyncThunk(
  "filtervirtacctransactions/filter",
  async (payloaddata, thunkAPI) => {
    try {
      var url = `/api/v1/virtual-account/${payloaddata?.businessid}/${payloaddata?.accountNum}/?start_date=${payloaddata?.start_date}&end_date=${payloaddata?.end_date}`;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      let errorMsg = "";

      if (error.response && error.response.data.error) {
        errorMsg = error.response.data.error[0];
      } else if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.response && error.response.data.detail) {
        errorMsg = error.response.data.detail;
      } else {
        errorMsg = error.message;
      }
      showerror(`Oops 😠,  ${errorMsg}`);
      return thunkAPI.rejectWithValue(errorMsg);
    }
  }
);

const filterVirtAccTransactionSlice = createSlice({
  name: "filtervirtacctransactions",
  initialState: {
    vfiltertransactionlist: null,
    vfilterloading: false,
  },

  reducers: {
    resetFilter: (state) => {
      state.vfiltertransactionlist = null;
      state.vfilterloading = false;
    },
    setFilter: (state, action) => {
      state.vfilter = action.payload;
    },
  },

  extraReducers: {
    [filterVirtAccTransaction.fulfilled]: (state, action) => {
      state.vfilterloading = false;
      state.vfiltertransactionlist = action.payload;
    },
    [filterVirtAccTransaction.pending]: (state) => {
      state.vfilterloading = true;
    },
    [filterVirtAccTransaction.rejected]: (state) => {
      state.vfilterloading = false;
    },
  },
});

export const filterVirtAccTransactionState = (state) => state.filtervirtacctransactions;
export default filterVirtAccTransactionSlice.reducer;
export const { resetFilter, setFilter } = filterVirtAccTransactionSlice.actions;
