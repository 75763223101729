import React from 'react'

export const EmptyComponent = ({image,text}) => {
  return (
   <div class="emtyTransactions">

    <img src={image} className="empty" alt="empty " />

    <p>{text}</p>
    </div>
    
  )
}
